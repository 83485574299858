import React from "react";
import styled from "styled-components";
import { ReactComponent as Frame290570 } from "../../../assets/images/close-btn.svg";
import CheckIcon from "./../../../assets/images/check-cir.svg";
/* #region CSS */
const StyledPage = styled.div`
    &.frame-29643,
    &.frame-29643 * {
        box-sizing: border-box;
    }
    &.frame-29643 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        position: relative;
        padding: 8px;
    }
    .group-3412 {
        width: 50px;
        height: 50px;
        position: static;
    }
    .ellipse-80 {
        background: var(--wrap-wrap-green, rgba(105, 194, 63, 0.1));
        border-radius: 50%;
        width: 50px;
        height: 50px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .frame-313411 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-end;
        justify-content: flex-start;
        align-self: stretch;
        position: relative;
    }
    .frame-313402 {
        display: flex;
        flex-direction: row;
        gap: 32px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        position: relative;
    }
    .frame-313406 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-3134022 {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        position: relative;
    }
    .frame-313401 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        position: relative;
    }
    .title-here {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 22px;
        line-height: 24px;
        font-weight: 600;
        position: relative;
        align-self: stretch;
    }
    .frame-313418 {
        padding: 0px 24px 0px 24px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        position: relative;
    }
    .text-label {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        flex: 1;
    }
`;
export default function SuccessSugestBrand({ setShowSuggestPopup }) {
    return (
        <StyledPage className="frame-29643">
            <div className="group-3412">
                <div className="ellipse-80">
                    <img className="check" src={CheckIcon} alt="" />
                </div>
                <Frame290570
                    style={{ position: "absolute", right: "0px" }}
                    className="frame-29057 cursor-pointer"
                    onClick={() => setShowSuggestPopup(false)}
                />
            </div>
            <div className="frame-313411">
                <div className="frame-313402">
                    <div className="frame-313406">
                        <div className="frame-3134022">
                            <div className="frame-313401">
                                <div className="title-here">Thank you for your suggestion!</div>
                                <div className="frame-313418">
                                    <div className="text-label">
                                        We appreciate your input! Your suggestion has been received, and we&#039;re working hard to bring
                                        the brands you love to BOtag. Stay tuned for updates!
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledPage>
    );
}

import React from "react";
import styled from "styled-components";
import ArrowRight from "./../../../assets/images/chevron-right0.svg";
import LayoutContext from "../../../contexts/LayoutContext";
/* #region CSS */

const StyledPage = styled.div`
    &.settings,
    &.settings * {
        box-sizing: border-box;
    }
    &.settings {
        background: var(--surface-surface-secondary, #121212);
        border-width: 0px 0px 0px 1px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
        width: 100%;
    }
    .panel-headers {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29055 {
        background: #212121;
        border-radius: 500px;
        padding: 4px 14px 4px 14px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .my-orders {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--heading-3-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--heading-3-font-size, 16px);
        line-height: var(--heading-3-line-height, 24px);
        font-weight: var(--heading-3-font-weight, 600);
        position: relative;
    }
    .frame-313365 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        width: 159px;
        position: relative;
    }
    .btn-xs-secondary {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: 30px;
        position: relative;
        cursor: pointer;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-small-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-small-font-size, 12px);
        line-height: var(--button-text-small-line-height, 16px);
        font-weight: var(--button-text-small-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .separator {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: auto;
        position: relative;
        overflow: visible;
    }
    .location-indicator {
        border-radius: 5px;
        border-style: solid;
        border-color: #212121;
        border-width: 1px;
        padding: 10px 16px 10px 16px;
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 100%;
        height: 48px;
        position: relative;
        cursor: pointer;
    }
    .location-indicator:hover {
        background-color: rgb(33, 33, 33) !important;
    }
    .frame-29614 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-313472 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .tordenskiolds-gate-2-0160-oslo {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        position: relative;
        width: 240px;
    }
    .chevron-right {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        position: relative;
        overflow: visible;
    }
    .your-locations-coming-soon {
        text-align: left;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        position: relative;
        width: 270.62px;
    }
    .your-locations-coming-soon-span {
        color: var(--text-text-primary, #ffffff);
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
    }
    .your-locations-coming-soon-span2 {
        color: var(--text-text-brand, #f7665f);
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
    }
    .chevron-right2 {
        opacity: 0.2;
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        position: relative;
        overflow: visible;
    }
    .saved-payment-methods-coming-soon {
        text-align: left;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        position: relative;
        width: 270.62px;
    }
    .saved-payment-methods-coming-soon-span {
        color: var(--text-text-primary, #ffffff);
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
    }
    .saved-payment-methods-coming-soon-span2 {
        color: var(--text-text-brand, #f7665f);
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
    }
    .chevron-right3 {
        opacity: 0.2;
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        position: relative;
        overflow: visible;
    }
    .tordenskiolds-gate-2-0160-oslo2 {
        text-align: left;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        position: relative;
        width: 240px;
    }
    .tordenskiolds-gate-2-0160-oslo-2-span {
        color: var(--text-text-primary, #ffffff);
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
    }
    .tordenskiolds-gate-2-0160-oslo-2-span2 {
        color: var(--text-text-brand, #f7665f);
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
    }
    .chevron-right4 {
        opacity: 0.2;
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        position: relative;
        overflow: visible;
    }
    .seperator {
        display: flex;
        flex-direction: row;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        border-bottom: 1px solid #212121;
    }
`;
/* #endregion CSS */

export default function SettingPannel() {
    const { setShowRightSettingsPanel, setShowRightCheckoutPanel, setShowYourAccountPannel } = React.useContext(LayoutContext);
    /* #region Methods */
    const handleCloseSetting = () => {
        setShowRightSettingsPanel(false);
        setShowRightCheckoutPanel(false);
    };
    const handleYourAccountFunction = () => {
        setShowYourAccountPannel(true);
        setShowRightSettingsPanel(false);
    };
    /* #endregion Methods */
    return (
        <StyledPage className="settings">
            <div className="panel-headers">
                <div className="frame-29055">
                    <div className="my-orders">Settings</div>
                </div>
                <div className="frame-313365">
                    <div className="btn-xs-secondary" onClick={handleCloseSetting}>
                        <div className="button">Close</div>
                    </div>
                </div>
            </div>
            <div className="seperator"></div>
            <div className="location-indicator" onClick={handleYourAccountFunction}>
                <div className="frame-29614">
                    <div className="frame-313472">
                        <div className="tordenskiolds-gate-2-0160-oslo">Your account</div>
                    </div>
                    <img className="chevron-right" src={ArrowRight} alt="" />
                </div>
            </div>
            <div className="location-indicator">
                <div className="frame-29614">
                    <div className="frame-313472">
                        <div className="your-locations-coming-soon">
                            <span>
                                <span className="your-locations-coming-soon-span">Your locations</span>
                                <span className="your-locations-coming-soon-span2"> (Coming soon)</span>
                            </span>
                        </div>
                    </div>
                    <img className="chevron-right2" src={ArrowRight} alt="" />
                </div>
            </div>
            <div className="location-indicator">
                <div className="frame-29614">
                    <div className="frame-313472">
                        <div className="saved-payment-methods-coming-soon">
                            <span>
                                <span className="saved-payment-methods-coming-soon-span">Saved payment methods</span>
                                <span className="saved-payment-methods-coming-soon-span2"> (Coming soon)</span>
                            </span>
                        </div>
                    </div>
                    <img className="chevron-right3" src={ArrowRight} alt="" />
                </div>
            </div>
            <div className="location-indicator">
                <div className="frame-29614">
                    <div className="frame-313472">
                        <div className="tordenskiolds-gate-2-0160-oslo2">
                            <span>
                                <span className="tordenskiolds-gate-2-0160-oslo-2-span">BOtag preferences</span>
                                <span className="tordenskiolds-gate-2-0160-oslo-2-span2"> (Coming soon)</span>
                            </span>
                        </div>
                    </div>
                    <img className="chevron-right4" src={ArrowRight} alt="" />
                </div>
            </div>
        </StyledPage>
    );
}

import React from "react";
import StockExceedInfo from "../../../../assets/images/stockexceedinfo.svg";

export default function BOTagBodyDeliveryUnavailable({ pickupDistance, setStoreTooAway }) {
    return (
        <div
            style={{
                margin: "0px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                gap: "20px"
            }}>
            <div style={{ display: "flex", flexDirection: "column", marginTop: "18px", textAlign: "center", gap: "19px", width: "180px" }}>
                <div className="stockexceedIcon">
                    <img src={StockExceedInfo} alt="" />
                </div>
                <div className="checkText" style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ fontWeight: "600", lineHeight: "16px", fontSize: "12px" }}>
                        Fastest option is pick up, but it’s more than {pickupDistance} km away.
                    </span>
                </div>
            </div>
            <div className="frame-3135012" onClick={() => setStoreTooAway(false)}>
                <div className="frame-313503 full-width-btn">
                    <div className="btn-fw-botag" style={{ background: "#212121" }}>
                        <div className="frame-313497">
                            <div className="frame-313349">
                                <div className="button">Show anyway</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React, { useContext, useState } from "react";
import { ReactComponent as CoinsStacked030 } from "../../../../assets/images/cheapest.svg";
import { ReactComponent as ActiveCheapest } from "../../../../assets/images/active-cheapest.svg";
import { ReactComponent as InactiveFastest } from "../../../../assets/images/inactive-fast.svg";
import { ReactComponent as LocationOption } from "../../../../assets/images/location-option.svg";
import settings030 from "../../../../assets/images/settings-030.svg";
import { ReactComponent as ZapFast0 } from "../../../../assets/images/zap-fast0.svg";
import rectangle2530 from "../../../../assets/images/rectangle-2530.svg";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import styled from "styled-components";
import ShareLocationPopup from "../../../common/dialogs/ShareLocationPopup";
import { UserContext } from "../../../../contexts/UserContext";
import { fetchAddressFromCoordinates } from "../../../../utils/LocationLib";
import { saveCoordinates, saveLocation } from "../../../../utils/helperFunctions";

const StyledPage = styled.div`
    &.frame-29287 {
        flex-shrink: 0;
        width: 26px;
        height: 122px;
        position: absolute;
        right: -26px;
        top: calc(50% - 58px);
    }
    .rectangle-253 {
        border-radius: 0px;
        width: 26px;
        height: 122px;
        position: absolute;
        left: 0px;
        top: 0px;
        overflow: visible;
    }
    .frame-3425 {
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-items: center;
        justify-content: flex-start;
        position: absolute;
        left: 0px;
        top: 16px;
    }
    .zap-fast {
        padding: 2px 1px 2px 1px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        height: auto;
        position: relative;
        overflow: visible;
    }
    .coins-stacked-03 {
        padding: 3px 2px 3px 2px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        height: auto;
        position: relative;
        overflow: visible;
    }
    .settings-03 {
        padding: 3px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        height: auto;
        position: relative;
        overflow: visible;
        cursor: pointer;
    }

    .deliveryPreference {
        cursor: pointer;
        color: #ffffff !important;
    }

    .deliveryPreference.active {
        color: #0060cc !important;
    }
`;

export default function BOTagSideOptions({ deliveryPreference, setDeliveryPreference }) {
    /* #region VARIABLES */
    const [showLocationPopup, setShowLocationPopup] = useState(false);
    const { userGPSCoordinates, setUserGPSCoordinates, setUserAddress } = useContext(UserContext);
    /* #endregion */

    /* #region METHODS */
    const onLocationChanged = location => {
        const { latitude, longitude } = location;

        setUserGPSCoordinates({ latitude, longitude });
        saveCoordinates({ latitude, longitude });

        fetchAddressFromCoordinates(userGPSCoordinates.latitude, userGPSCoordinates.longitude).then(newUserAddress => {
            console.log("User address fetched", newUserAddress);

            setUserAddress(newUserAddress);
            saveLocation(newUserAddress);
        });
    };
    /* #endregion */

    return (
        <StyledPage className="frame-29287">
            <ShareLocationPopup visible={showLocationPopup} setVisible={setShowLocationPopup} onLocationSelected={onLocationChanged} />

            <img className="rectangle-253" src={rectangle2530} alt="options" />
            <div className="frame-3425">
                {deliveryPreference === "fastest" ? (
                    <ZapFast0
                        className={"deliveryPreference zap-fast active"}
                        onClick={() => setDeliveryPreference("fastest")}
                        alt="Fastest"
                    />
                ) : (
                    <InactiveFastest
                        className={"deliveryPreference zap-fast"}
                        onClick={() => setDeliveryPreference("fastest")}
                        alt="Fastest"
                    />
                )}
                {deliveryPreference === "cheapest" ? (
                    <ActiveCheapest
                        className={"deliveryPreference coins-stacked-03"}
                        onClick={() => setDeliveryPreference("cheapest")}
                        alt="Cheapest"
                    />
                ) : (
                    <CoinsStacked030
                        className={"deliveryPreference coins-stacked-03"}
                        onClick={() => setDeliveryPreference("cheapest")}
                        alt="Cheapest"
                    />
                )}

                {/* <img className="settings-03" src={settings030} alt="Location" onClick={() => setShowLocationPopup(true)} /> */}
                <LocationOption className="settings-03" onClick={() => setShowLocationPopup(true)} />
            </div>
        </StyledPage>
    );
}

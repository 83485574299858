import styled from "styled-components";
import Courier from "../../assets/images/courier.png";
import Shipping from "../../assets/images/shipping.png";
import Vector from "../../assets/images/Vector.png";
import Postnord from "../../assets/images/postnordIcon.png";
import Dots from "../../assets/images/threeDots.png";
import { Tooltip } from "antd";
import Store from "../../assets/images/order-store.svg";
import moment from "moment";
import React, { useState } from "react";
import LayoutContext from "../../contexts/LayoutContext";
import PopOver from "../common/dialogs/PopOver";
import SinglePopOver from "../common/dialogs/SinglePopOver";
import { getLatLngFromAddress, getDistanceBetweenCoordinates } from "../../utils/LocationLib";
import * as Formatter from "../../utils/Formatter";
/* #region STYLED CSS */
const OrderItemCardStyles = styled.div`
    .frame-29717 {
        display: flex;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .separator2 {
        width: 100%;
        height: 0.5px;
        //border: 1px solid rgba(166, 166, 166, 0.1);
        border: 0.5px solid #373737;
        //background-color: rgb(56, 56, 56);
    }
    .brand-product-type4 {
        white-space: nowrap;
    }

    .frame-313454 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .group-3495 {
        width: 20px;
    }
    .group-3496 {
        width: 14px;
    }
    .frame-313452 {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 6px;
    }
    .frame-313359 {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .frame-313451 {
        width: 100%;
    }
    .frame-2222 {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .trackBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #212121;
        height: 30px;
        padding: 0px 6px;
        border-radius: 5px;
        color: #8c8c8c;
    }
    .closed {
        color: rgb(255, 0, 0);
        font-size: 10px;
        font-weight: 400;
    }
`;
/* #endregion */

const OrderItemCard = ({ data }) => {
    /* #region VARS */
    //Contexts
    const { setShowProductGalleryLightbox } = React.useContext(LayoutContext);
    //States
    const [showPopOver, setShowPopOver] = useState(false);
    const isStoreOpen = () => {
        const isWeekday = new Date().getDay() >= 1 && new Date().getDay() <= 5;
        const isSaturday = new Date().getDay() === 6;

        const openHours = isWeekday
            ? data?.storeInfo?.openHours?.weekdays
            : isSaturday
            ? data?.storeInfo?.openHours?.saturdays
            : data?.storeInfo?.openHours?.sundays;
        if (!openHours || !openHours?.hourStart || !openHours?.hourEnd) return false;
        const currentTime = moment().utcOffset("+0100").format("HH:mm");

        const hourStart = openHours?.hourStart; // "09:00"
        const hourEnd = openHours?.hourEnd; // "18:00"

        return currentTime >= hourStart && currentTime <= hourEnd;
    };
    const disableCondition = data?.status === "New" || data?.status === "Handling";
    /* #endregion */

    /* #region METHODS */
    const onImageGalleryShow = images => {
        const slides = images?.map(image => ({ src: image?.URL }));

        if (images && images.length > 0) {
            setShowProductGalleryLightbox({
                isOpen: true,
                slides: slides,
                showProductDetails: false
            });
        }
    };

    /* #endregion */

    return (
        <OrderItemCardStyles style={{ width: "100%" }}>
            <div className="orders-card-item">
                <div className="info-card-status">
                    <AllUiStatusComponent data={data} />
                </div>
                <div className="frame-313449">
                    <div className="frame-313447">
                        <div className="frame-29715">
                            <img
                                className="luan-j-shirt-cosmic-4-3 cursor-pointer"
                                src={data?.imageURL}
                                onClick={() => onImageGalleryShow(data?.allGTINimageURLs)}
                                alt=""
                            />
                            <div className="frame-29710">
                                <div className="brand-product-type">
                                    {data?.brandName} / {data?.productType}
                                </div>
                                <div className="product-name" style={{ width: "100%" }}>
                                    {data?.productName}
                                </div>
                                <div className="brand-product-type2">
                                    {data?.color} - {data?.size} - {data?.quantity}
                                </div>
                            </div>
                            <div className="frame-29538">
                                <div
                                    style={{
                                        fontWeight: 700,
                                        marginTop: "-4px",
                                        cursor: "pointer",
                                        opacity: disableCondition ? "0.3" : "1",
                                        pointerEvents: disableCondition && "none",
                                        height: "20px"
                                    }}>
                                    <img
                                        src={Dots}
                                        alt=""
                                        width="24px"
                                        onClick={() => {
                                            setShowPopOver(!showPopOver);
                                        }}
                                    />
                                </div>
                                <div style={{ position: "absolute", zIndex: "10", top: "17px" }}>
                                    {showPopOver && (
                                        <PopOverSelectorFunc data={data} setShowPopOver={setShowPopOver} showPopOver={showPopOver} />
                                    )}
                                </div>

                                <div className="brand-product-type3">
                                    {data?.isDiscount === true ? (
                                        <span>
                                            <span className="brand-product-type-3-span">
                                                <span style={{ textDecoration: data?.itemPrice && "line-through" }}>
                                                    {data?.originalItemPrice?.toLocaleString()?.replaceAll(",", ".")}
                                                </span>{" "}
                                                <span style={{ color: "#FF0000", display: !data?.itemPrice && "none" }}>
                                                    {data?.itemPrice && data?.itemPrice?.toLocaleString()?.replaceAll(",", ".")}
                                                </span>{" "}
                                            </span>
                                            <span className="brand-product-type-3-span2"></span>
                                            <span className="brand-product-type-3-span3" style={{ color: data?.itemPrice && "#FF0000" }}>
                                                NOK
                                            </span>
                                        </span>
                                    ) : (
                                        <>
                                            <span className="brand-product-type-3-span">
                                                <span>{data?.originalItemPrice?.toLocaleString()?.replaceAll(",", ".")}</span>{" "}
                                            </span>
                                            <span className="brand-product-type-3-span2"></span>
                                            <span className="brand-product-type-3-span3"> NOK</span>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="frame-313454">
                        {data?.deliveryOption === "Pickup" && <PickUpOrder data={data} />}
                        {data?.deliveryOption === "Courier" && <CourierOrder data={data} />}
                        {data?.deliveryOption === "Shipping" && <ShippingOrder data={data} />}
                        <div className="frame-313448">
                            <div className="frame-313443">
                                <div className="frame-3134432">
                                    <div className="brand-product-type4">
                                        {data?.deliveryOption === "Pickup" ? "TO STORE" : "FROM STORE"}
                                    </div>
                                    <div className="separator2" src="separator1.svg"></div>
                                </div>
                            </div>
                            <div className="frame-29714">
                                <div className="frame-29721">
                                    <div className="frame-29719">
                                        <div className="frame-297172">
                                            <div className="frame-29716">
                                                <img className="group-3626" src={Store} alt="" />
                                                <div className="frame-313440">
                                                    <div className="frame-313441">
                                                        <div className="product-name3">{data?.storeName}</div>
                                                        <div className={`${isStoreOpen() ? "open" : "closed"}`}>
                                                            {isStoreOpen() ? "Open" : "Closed"}
                                                        </div>
                                                    </div>
                                                    <div className="product-name4">{data?.storeAddress}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </OrderItemCardStyles>
    );
};
export default OrderItemCard;

/* #region SMALL COMPONENTS */
const PickUpOrder = ({ data }) => {
    const [distanceFromStore, setDistanceFromStore] = useState("");
    getLatLngFromAddress(data?.storeAddress).then(latLng => {
        if (latLng) {
            const userLatLng = { latitude: data?.userLocation?.latitude, longitude: data?.userLocation?.longitude };
            const storeLatLng = { latitude: latLng?.lat, longitude: latLng?.lng };
            const distance = getDistanceBetweenCoordinates(storeLatLng, userLatLng);
            const distanceToStore = Formatter.formatDistance(distance, "km", "--");
            setDistanceFromStore(distanceToStore);
        } else {
            console.log("No results found");
        }
    });
    return (
        <div className="frame-313453">
            <div className="frame-313443">
                <div className="frame-3134432">
                    <div className="brand-product-type4">PICK UP</div>
                    <div className="separator2" src="separator1.svg"></div>
                </div>
            </div>
            <div className="frame-313444">
                <div className="frame-3627">
                    <img className="group-3496" src={Vector} alt="" />
                </div>
                <div className="frame-313452">
                    <div className="frame-313451">
                        <div className="frame-29717">
                            <div className="frame-313359">
                                <div style={{ display: "flex", gap: "4px", alignItems: "center", fontSize: "12px" }}>
                                    <span className="product-name2">
                                        Distance to store: <span style={{ color: " #8c8c8c" }}>{distanceFromStore}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="frame-313451">
                        <div className="frame-29717">
                            <div className="frame-313359">
                                <div style={{ display: "flex", gap: "4px", alignItems: "center", fontSize: "12px" }}>
                                    <span className="product-name2">
                                        From:{" "}
                                        <span
                                            style={{
                                                color: " #8c8c8c",
                                                position: "absolute",
                                                width: "216px",
                                                left: "37px",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                            }}>
                                            {data?.userLocation?.address}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const CourierOrder = ({ data }) => {
    return (
        <div className="frame-313453">
            <div className="frame-313443">
                <div className="frame-3134432">
                    <div className="brand-product-type4">COURIER</div>
                    <img className="separator2" src="separator1.svg" alt="" />
                </div>
            </div>
            <div className="frame-313444">
                <div className="frame-3627">
                    <img className="group-3495" src={Courier} alt="" style={{ position: "relative", top: "1px" }} />
                </div>
                <div className="frame-313452">
                    <div className="frame-313451">
                        <div className="frame-29717">
                            <div className="frame-313359">
                                <div style={{ display: "flex", gap: "4px", alignItems: "center", fontSize: "12px" }}>
                                    <div className="product-name2" style={{ gap: "3px" }}>
                                        By:
                                        <img src={data?.logoURL} alt="" width="27px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="frame-313456">
                            <div className="frame-313450">
                                <div style={{ fontWeight: "600" }}>
                                    {data?.deliveryCost === 0 || data?.deliveryCost === "" ? null : data?.deliveryCost}{" "}
                                    <span style={{ fontWeight: 400, fontSize: "12px" }}>
                                        {data?.deliveryCost === 0 || data?.deliveryCost === "" ? "FREE" : "NOK"}
                                    </span>
                                </div>
                            </div>
                            <div className="frame-3134523"></div>
                        </div>
                    </div>
                    <div className="frame-313451">
                        <div className="frame-29717">
                            <div className="frame-313359">
                                <div style={{ display: "flex", gap: "4px", alignItems: "center", fontSize: "12px" }}>
                                    <span className="product-name2" style={{ display: "unset" }}>
                                        To: <span style={{ color: " #8c8c8c", fontSize: "12px" }}>{data?.userAddress}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="frame-313451">
                        <div className="frame-29717">
                            <div className="frame-313359">
                                <div style={{ display: "flex", gap: "4px", alignItems: "center", fontSize: "12px" }}>
                                    <span className="product-name2" style={{ display: "unset" }}>
                                        Instructions:{" "}
                                        <span style={{ color: " #8c8c8c", fontSize: "12px" }}>
                                            {data?.userLocation?.courierInstructions
                                                ? data?.userLocation?.courierInstructions
                                                : "No instructions added"}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const ShippingOrder = ({ data }) => {
    return (
        <div className="frame-313453">
            <div className="frame-313443">
                <div className="frame-3134432">
                    <div className="brand-product-type4">SHIPPING</div>
                    <img className="separator2" src="separator1.svg" alt="" />
                </div>
            </div>
            <div className="frame-313444">
                <div className="frame-3627">
                    <img className="group-3495" src={Shipping} alt="" />
                </div>
                <div className="frame-313452">
                    <div className="frame-313451">
                        <div className="frame-29717">
                            <div className="frame-313359">
                                <div className="product-name2">
                                    By:
                                    <img src={Postnord} alt="" height="10px" />
                                </div>
                                <div style={{ fontWeight: "600" }}>
                                    {data?.deliveryCost === 0 || data?.deliveryCost === "" ? null : data?.deliveryCost}{" "}
                                    <span style={{ fontWeight: 400, fontSize: "12px" }}>
                                        {data?.deliveryCost === 0 || data?.deliveryCost === "" ? "FREE" : "NOK"}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="frame-313451">
                        <div className="frame-29717">
                            <div className="frame-313359">
                                <div style={{ display: "flex", gap: "4px", alignItems: "center", fontSize: "12px" }}>
                                    <span className="product-name2" style={{ display: "unset" }}>
                                        To:{" "}
                                        <span style={{ color: " #8c8c8c", fontSize: "12px" }}>
                                            {data?.userLocation?.address} {data?.userLocation?.doorNumber}, {data?.userLocation?.postCode},
                                            {data?.userLocation?.city}, {data?.userLocation?.country}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="frame-313451">
                        <div className="frame-29717">
                            <div className="frame-313359">
                                <div className="frame-2222">
                                    <div style={{ display: "flex", gap: "4px", alignItems: "center", fontSize: "12px" }}>
                                        <div className="product-name2">Recipient:</div>
                                        <div style={{ color: " #8c8c8c", fontSize: "12px" }}>
                                            {data?.recipientFirstName} {data?.recipientLastName}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const AllUiStatusComponent = ({ data }) => {
    let statusText = "";
    let icon = "";
    let styles = {};
    const originalDate = moment(data?.statusDate);
    const convertedDate = originalDate.format("DD.MM.YY, HH:mm");

    if (data?.status === "New") {
        statusText = "Please wait for store confirmation";
        styles = {
            color: "rgba(255, 153, 0, 1)",
            backgroundColor: "rgba(255, 153, 0, 0.1)",
            margin: "0px",
            width: "100%"
        };
    } else if (data?.status === "Handling") {
        statusText = "Please wait for store confirmation";
        styles = {
            color: "rgba(255, 153, 0, 1)",
            backgroundColor: "rgba(255, 153, 0, 0.1)",
            margin: "0px",
            width: "100%"
        };
    } else if (data?.status === "Handled" && data?.deliveryOption === "Pickup") {
        statusText = "Ready for pick up!";
        styles = {
            color: "rgba(105, 194, 63, 1)",
            backgroundColor: "rgba(105, 194, 63, 0.1)",
            margin: "0px",
            width: "100%"
        };
    } else if (data?.status === "Handled" && data?.deliveryOption === "Courier") {
        statusText = "Store accepted! Waiting for courier";
        styles = {
            color: "rgba(105, 194, 63, 1)",
            backgroundColor: "rgba(105, 194, 63, 0.1)",
            margin: "0px",
            width: "100%"
        };
    } else if (data?.status === "Handled" && data?.deliveryOption === "Shipping") {
        statusText = "Store accepted! Ready to ship";
        styles = {
            color: "rgba(105, 194, 63, 1)",
            backgroundColor: "rgba(105, 194, 63, 0.1)",
            margin: "0px",
            width: "100%"
        };
    } else if (data?.status === "CourierConfirmed") {
        statusText = "Courier accepted!";
        styles = {
            color: "rgba(105, 194, 63, 1)",
            backgroundColor: "rgba(105, 194, 63, 0.1)",
            margin: "0px",
            width: "100%"
        };
    } else if (data?.status === "CourierPickup" || data?.status === "CourierPickedUp") {
        statusText = "Courier is on the way to you!";
        styles = {
            color: "rgba(105, 194, 63, 1)",
            backgroundColor: "rgba(105, 194, 63, 0.1)",
            margin: "0px",
            width: "100%"
        };
    } else if (data?.status === "CourierIsNear") {
        statusText = "Courier is right around the corner!";
        styles = {
            color: "rgba(105, 194, 63, 1)",
            backgroundColor: "rgba(105, 194, 63, 0.1)",
            margin: "0px",
            width: "100%"
        };
    } else if (data?.status === "ShippingConfirmed") {
        statusText = "On its way!";
        styles = {
            color: "rgba(105, 194, 63, 1)",
            backgroundColor: "rgba(105, 194, 63, 0.1)",
            margin: "0px",
            width: "100%"
        };
    } else if (data?.status === "ShippingPickedUp") {
        statusText = "On its way!";
        styles = {
            color: "rgba(105, 194, 63, 1)",
            backgroundColor: "rgba(105, 194, 63, 0.1)",
            margin: "0px",
            width: "100%"
        };
    } else if (data?.status === "Delivered") {
        statusText = `Delivered ${convertedDate}`;
        styles = {
            color: "rgba(105, 194, 63, 1)",
            backgroundColor: "rgba(105, 194, 63, 0.1)",
            margin: "0px",
            width: "100%"
        };
    } else if (data?.status === "ToReturn") {
        statusText = "Refund requested";
        icon = <RefundedIcon data={data} />;
        styles = {
            color: "rgba(255, 153, 0, 1)",
            backgroundColor: "rgba(255, 153, 0, 0.1)",
            margin: "0px",
            width: "100%"
        };
    } else if (data?.status === "Canceled/Refunded") {
        statusText = "Cancelled / Refunded";
        styles = {
            color: "rgba(255, 0, 0, 1)",
            backgroundColor: "rgba(255, 0, 0, 0.1)",
            margin: "0px",
            width: "100%"
        };
    } else if (data?.status === "ToRefund") {
        statusText = "Refund requested";
        icon = <RefundedIcon data={data} />;
        styles = {
            color: "rgba(255, 153, 0, 1)",
            backgroundColor: "rgba(255, 153, 0, 0.1)",
            margin: "0px",
            width: "100%"
        };
    } else if (data?.status === "Refunded") {
        statusText = "Refunded";
        icon = <RefundedIcon data={data} />;
        styles = {
            color: "rgba(105, 194, 63, 1)",
            backgroundColor: "rgba(105, 194, 63, 0.1)",
            margin: "0px",
            width: "100%"
        };
    } else if (data?.status === "RefundDeclined") {
        statusText = "Refund declined";
        icon = <RefundedIcon data={data} />;
        styles = {
            color: "rgba(255, 0, 0, 1)",
            backgroundColor: "rgba(255, 0, 0, 0.1)",
            margin: "0px",
            width: "100%"
        };
    }
    return (
        <p className="info-card-status" style={styles}>
            {statusText}&nbsp;&nbsp;{icon}
        </p>
    );
};

const RefundedIcon = ({ data }) => {
    const convertDateFunc = date => {
        if (date !== "--.--.--, --:--") {
            const originalDate = moment(date);
            const convertedDate = originalDate.format("DD.MM.YY, HH:mm");
            return convertedDate;
        } else {
            return date;
        }
    };
    return (
        <Tooltip
            style={{
                backgroundColor: "rgba(33, 33, 33, 1)",
                color: "rgba(255, 255, 255, 1)"
            }}
            placement="bottom"
            title={
                <ul
                    style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "rgba(255, 255, 255, 1)",
                        lineHeight: "21px",
                        marginBottom: "0px",
                        paddingLeft: "16px"
                    }}>
                    {data?.status === "ToReturn" && (
                        <>
                            <li>Delivered {convertDateFunc(data?.deliveredDate?.date)}</li>
                            <li>Refund requested {convertDateFunc(data?.toRefundDate?.date)} </li>
                            <li>Info sent to: {data?.email}</li>
                        </>
                    )}
                    {data?.status === "ToRefund" && (
                        <>
                            <li>Delivered {convertDateFunc(data?.deliveredDate?.date)}</li>
                            <li>Refund requested {convertDateFunc(data?.toRefundDate?.date)}</li>
                            <li>Info sent to: {data?.email}</li>
                        </>
                    )}
                    {data?.status === "RefundDeclined" && (
                        <>
                            <li>Delivered {convertDateFunc(data?.deliveredDate?.date)}</li>
                            <li>Refund requested {convertDateFunc(data?.toRefundDate?.date)}</li>
                            <li>Refunded declined {convertDateFunc(data?.refundDeclinedDate?.date)}</li>
                            <li>Info sent to: {data?.email}</li>
                        </>
                    )}
                    {data?.status === "Refunded" && (
                        <>
                            <li>Delivered {convertDateFunc(data?.deliveredDate?.date)}</li>
                            <li>Refund requested {convertDateFunc(data?.toRefundDate?.date)}</li>
                            <li>Refunded {convertDateFunc(data?.refundedDate?.date)}</li>
                            <li>Info sent to: {data?.email}</li>
                        </>
                    )}
                </ul>
            }
            trigger={["click"]}
            defaultOpen={false}
            overlayClassName="custom-tooltip">
            <span>
                <i className="fa-light fa-circle-info"></i>
            </span>
        </Tooltip>
    );
};
const PopOverSelectorFunc = ({ data, setShowPopOver, showPopOver }) => {
    if (data?.status === "Delivered" || data?.status === "New") {
        return (
            <span>
                <PopOver data={data} setShowPopOver={setShowPopOver} showPopOver={showPopOver} />
            </span>
        );
    }
    if (data?.status === "ToRefund" || data?.status === "Refunded" || data?.status === "RefundDeclined" || data?.status === "ToReturn") {
        return <SinglePopOver data={data} setShowPopOver={setShowPopOver} showPopOver={showPopOver} />;
    }
};

/* #endregion */

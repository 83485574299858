import React, { useContext, useRef, useState, useEffect } from "react";
import styled from "styled-components";
import * as Formatter from "../../utils/Formatter";
import * as Library from "../../utils/Library";
import OrderPickup from "../../assets/images/order-pickup.svg";
import OrderMinus from "../../assets/images/order-minus.svg";
import OrderPlus from "../../assets/images/order-plus.svg";
import OrderStore from "../../assets/images/order-store.svg";
import OrderFastestIcon from "../../assets/images/order-fastest-icon.svg";
import OrderRemove from "../../assets/images/order-rmv.svg";
import horizontalDots from "../../assets/images/horizontal-dots.svg";
import OrderCourier from "../../assets/images/order-courier.svg";
import QuickBuy from "../../assets/images/quick-buy.svg";
import OrderEdit from "../../assets/images/order-edit.svg";
import OrderShipping from "../../assets/images/order-shipping.svg";
import UserContext from "../../contexts/UserContext";
import LayoutContext from "../../contexts/LayoutContext";
import CourierInstructionsPopup from "../common/dialogs/CourierInstructionPopup";
import logGA4Event from "../../utils/firebase/analytics";
import CartRemovePopup from "../common/dialogs/CartRemovePopup";
import moment from "moment";

/* #region SCOPED CSS */
const CheckoutItemsStyles = styled.div`
    &.checkout-cards {
        border-radius: 5px;
        border-style: solid;
        border-color: rgba(166, 166, 166, 0.2);
        border-width: 1px;
        padding: 0px 0px 16px 0px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 100%;
        position: relative;
    }
    .info-card-status {
        background: var(--wrap-wrap-blue, rgba(0, 96, 204, 0.1));
        border-radius: 5px 5px 0px 0px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 32px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
    }
    .frame-29741 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-29781 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .solid {
        flex-shrink: 0;
        width: 11.01px;
        height: 10px;
        position: relative;
        overflow: visible;
    }
    .fastest-courier-approx-45-min {
        color: var(--text-text-info, #0060cc);
        text-align: center;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-313449 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313447 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .dropdown-mini {
        background: #212121;
        border-radius: 4px;
        padding: 8px 4px;
        width: 136px;
        position: absolute;
        right: 0px;
        z-index: 9;
    }
    .frame-75 {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-84 {
        border-radius: 4px;
        padding: 8px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313639 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .trash-03 {
        flex-shrink: 0;
        width: 15px;
        height: 15px;
        position: relative;
        overflow: visible;
    }
    .delete {
        color: var(--text-text-error, #ff0000);
        text-align: left;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        flex: 1;
    }
    .frame-29715 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .luan-j-shirt-cosmic-4-3 {
        border-radius: 5px;
        flex-shrink: 0;
        width: 44.83px;
        height: 60px;
        position: relative;
        object-fit: cover;
    }
    .frame-29710 {
        display: flex;
        flex-direction: column;
        gap: 6px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .brand-product-type {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .product-name {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .brand-product-type2 {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
    }
    .frame-29538 {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313446 {
        cursor: pointer;
        position: relative;
    }
    .brand-product-type3 {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 1px;
    }
    .brand-product-type-3-span {
        color: var(--text-text-primary, #ffffff);
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
    }
    .brand-product-type-3-span2 {
        color: var(--text-text-primary, #ffffff);
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
    }
    .brand-product-type-3-span3 {
        color: var(--text-text-primary, #ffffff);
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 10px;
        line-height: 20px;
        font-weight: 400;
    }
    .frame-313265 {
        display: flex;
        flex-direction: row;
        gap: 9px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .group-3487 {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        position: relative;
        overflow: visible;
        cursor: pointer;
    }
    ._1 {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-size: 15px;
        font-weight: 600;
        position: relative;
    }
    .group-3488 {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        position: relative;
        overflow: visible;
        cursor: pointer;
    }
    .frame-313454 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313448 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313445 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .line-26 {
        margin-top: -0.5px;
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0.001deg) scale(1, 1);
    }
    .frame-3134462 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29721 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: flex-end;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29719 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-297172 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29716 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        position: relative;
    }
    .group-3626 {
        flex-shrink: 0;
        width: 45px;
        height: 45px;
        position: relative;
        overflow: visible;
    }
    .frame-313440 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-end;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-313441 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-end;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .product-name3 {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .open {
        color: #69c23f;
    }
    .closed {
        color: #ff0000;
    }
    .product-name4 {
        color: #8c8c8c;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        position: relative;
        align-self: stretch;
    }
    .frame-313443 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .brand-product-type4 {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        position: relative;
    }
    .open-hours {
        color: #f7665f !important;
        text-decoration: underline;
        cursor: pointer;
    }
`;

const PickupSectionStyles = styled.div`
    &.frame-313453 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313444 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313443 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .brand-product-type4 {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        position: relative;
    }
    .separator {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: auto;
        position: relative;
        overflow: visible;
    }
    .frame-29723 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 45px;
        position: relative;
    }
    .frame-313488 {
        border-radius: 63px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 45px;
        height: 45px;
        position: relative;
        overflow: visible;
    }
    .frame-313452 {
        display: flex;
        flex-direction: column;
        gap: 6px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-3134522 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29717 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-313359 {
        display: flex;
        flex-direction: row;
        gap: 3px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .product-name2 {
        text-align: left;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 275px;
    }
    .product-name-2-span {
        color: var(--text-text-primary, #ffffff);
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
    }
    .product-name-2-span2 {
        color: var(--text-text-secondary, #8c8c8c);
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
    }
    .frame-313451 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .product-name-2-span3 {
        color: var(--text-text-primary, #ffffff);
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
    }
    .product-name-2-span4 {
        color: var(--text-text-secondary, #8c8c8c);
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
    }
`;

const CourierSectionStyles = styled.div`
    &.frame-313453,
    &.frame-313453 * {
        box-sizing: border-box;
    }
    &.frame-313453 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313443 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3134432 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .brand-product-type {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        position: relative;
    }
    .separator {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: auto;
        position: relative;
        overflow: visible;
    }
    .frame-29723 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3627 {
        background: var(--buttons-button-tertiary, #232832);
        border-radius: 57px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 45px;
        height: 45px;
        position: relative;
    }
    .group-3495 {
        flex-shrink: 0;
        width: 19.93px;
        height: 15.97px;
        position: relative;
        overflow: visible;
    }
    .frame-313452 {
        display: flex;
        flex-direction: column;
        gap: 6px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-313451 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29717 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-313359 {
        display: flex;
        flex-direction: row;
        gap: 3px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .product-name {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .vectorCourier {
        flex-shrink: 0;
        width: 27px;
        height: 15px;
        overflow: visible;
        object-fit: cover;
    }
    .frame-3134522 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3133592 {
        display: flex;
        flex-direction: row;
        gap: 3px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .to-span {
        color: var(--Text-text-primary, #fff);
        font-family: "Open Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 116.667% */
    }
    .to-span2 {
        color: var(--Text-text-secondary, #8c8c8c);

        /* Caption-Semibold */
        font-family: "Open Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
    }
    .instructions-span {
        color: var(--Text-text-primary, #fff);
        font-family: "Open Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 116.667% */
    }
    .instructions-span2 {
        color: #8c8c8c;

        /* Caption-Semibold */
        font-family: "Open Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
    }

    .frame-3134532 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313455 {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313450 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .brand-product-type2 {
        color: var(--text-text-primary, #ffffff);
        text-align: right;
        position: relative;
    }
    .brand-product-type-2-span {
        color: var(--text-text-primary, #ffffff);
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
    }
    .brand-product-type-2-span2 {
        color: var(--text-text-primary, #ffffff);
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }
    .brand-product-type-2-span3 {
        color: var(--text-text-primary, #ffffff);
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 10px;
        line-height: 20px;
        font-weight: 400;
    }
    .frame-3134523 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3134512 {
        background: var(--buttons-button-tertiary, #232832);
        border-radius: 51px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        position: relative;
        cursor: pointer;
    }
    .edit-02 {
        flex-shrink: 0;
        width: 20px;
        height: 10px;
        position: relative;
        overflow: visible;
    }
`;

const ShippingSectionStyles = styled.div`
    &.frame-313453,
    &.frame-313453 * {
        box-sizing: border-box;
    }
    &.frame-313453 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313443 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3134432 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .brand-product-type {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        position: relative;
    }
    .separator {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: auto;
        position: relative;
        overflow: visible;
    }
    .frame-29723 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313487 {
        border-radius: 60px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 45px;
        height: 45px;
        position: relative;
        overflow: visible;
    }
    .frame-313452 {
        display: flex;
        flex-direction: column;
        gap: 6px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-313451 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29717 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-313359 {
        display: flex;
        flex-direction: row;
        gap: 3px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .product-name {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-29607 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        height: 11px;
    }
    .image-153 {
        flex-shrink: 0;
        height: 11px;
        position: relative;
        object-fit: cover;
    }
    .frame-313454 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    span.to-span {
        color: var(--Text-text-primary, #fff);
        font-family: "Open Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
    }
    .frame-3133592 {
        display: flex;
        flex-direction: row;
        gap: 3px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-3134522 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313455 {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313450 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .brand-product-type2 {
        color: var(--text-text-primary, #ffffff);
        text-align: right;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
    }
    .frame-3134512 {
        background: var(--buttons-button-tertiary, #232832);
        border-radius: 51px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        position: relative;
    }
    .edit-02 {
        flex-shrink: 0;
        width: 20px;
        height: 10px;
        position: relative;
        overflow: visible;
    }
    .recipient-span {
        color: var(--Text-text-primary, #fff);
        font-family: "Open Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 116.667% */
    }
    .recipient-span2 {
        color: var(--Text-text-warning, #f90);

        /* Caption-Semibold */
        font-family: "Open Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
    }
    .to-span2 {
        color: var(--Text-text-secondary, #8c8c8c);

        /* Caption-Semibold */
        font-family: "Open Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
    }
    .brand-product-type-2-span {
        color: var(--text-text-primary, #ffffff);
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
    }
    .brand-product-type-2-span2 {
        color: var(--text-text-primary, #ffffff);
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }
    .brand-product-type-2-span3 {
        color: var(--text-text-primary, #ffffff);
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 10px;
        line-height: 20px;
        font-weight: 400;
    }
`;
/* #endregion */

export default function ItemCheckoutCard({ item, onEditClick, singleItemId }) {
    /* #region VARS */
    const { checkoutCartData, setCheckoutCartData, setQuickBuyData } = useContext(UserContext);
    const {
        setShowShippingAddressPopup,
        setShippingAddressPopupData,
        setShowCourierInstructionPopup,
        showCourierInstructionPopup,
        courierInstructionsData,
        setShowStoreInformation,
        setStoreInformation,
        setShowProductGalleryLightbox,
        setShowRightCheckoutPanel,
        setQuickBuyPopup
    } = useContext(LayoutContext);
    const BOTagData = item?.BOTagData;
    const BOTagAlgData = item?.BOTagAlgorithmData;
    const FormattedData = item?.FormattedData;
    const { city, country, postCode, street } = item?.UserAddress;
    const UserAddress = `${street || ""} ${postCode || ""} ${city || ""}, ${country || ""}`;
    const storeAddress = `${BOTagData?.storeDetails?.address || ""}, ${BOTagData?.storeDetails?.postalCode || ""} ${
        BOTagData?.storeDetails?.city || ""
    }, ${BOTagData?.storeDetails?.country || ""}`;
    //const UserData = item?.UserData;
    //const UserGPSCoordinates = item?.UserGPSCoordinates;
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [removeItem, setRemoveItem] = useState("");
    const [deletedID, setDeletedID] = useState("");
    const deleteRef = useRef();
    const dropdownMiniRef = useRef();
    const deliveryMethod = BOTagData?.deliveryMethod;
    /* #endregion */

    /* #region METHODS */
    const onChangeQuantity = (item, isIncrement = true) => {
        try {
            const itemID = item?.itemID;

            const itemIndex = checkoutCartData?.items?.findIndex(item => item?.itemID === itemID);
            if (itemIndex === -1) throw new Error("Item not found in cart");

            const itemData = checkoutCartData?.items[itemIndex];
            let newQuantity = isIncrement ? itemData?.BOTagData?.selectedQuantity + 1 : itemData?.BOTagData?.selectedQuantity - 1;
            if (newQuantity < 1) newQuantity = 1;
            if (newQuantity > 10) newQuantity = 10;

            const priceDifference = itemData.FormattedData.price;
            const oldTotalPrice = checkoutCartData.total;

            itemData.BOTagData.selectedQuantity = newQuantity;
            itemData.FormattedData.totalPrice = itemData.FormattedData.price * newQuantity + (itemData.FormattedData.deliveryCharges || 0);
            itemData.FormattedData.totalPriceStr = Formatter.formatPrice(itemData.FormattedData.totalPrice, "");
            const allItems = [...checkoutCartData?.items];
            allItems[itemIndex] = itemData;

            const newTotalPrice = isIncrement ? oldTotalPrice + priceDifference : oldTotalPrice - priceDifference;

            setCheckoutCartData({ ...checkoutCartData, items: allItems, total: newTotalPrice });
        } catch (error) {
            Library.showConfirmationMessage("Error: " + error.message);
        }
    };

    const onItemRemovePopup = item => {
        /*setShowDeletePopup(true);
        setRemoveItem(item);*/
        onRemoveFromCart(item);
    };

    const onRemoveFromCart = removeItem => {
        try {
            const itemID = removeItem?.itemID;
            const itemIndex = checkoutCartData?.items?.findIndex(item => item?.itemID === itemID);
            if (itemIndex === -1) throw new Error("Item not found in cart");
            const allItems = [...checkoutCartData?.items];
            const deletedTotalPrice = allItems[itemIndex]?.FormattedData?.totalPrice;
            allItems.splice(itemIndex, 1);
            if (allItems.length === 0) {
                setShowRightCheckoutPanel(false);
            }
            setCheckoutCartData({ ...checkoutCartData, items: allItems, total: checkoutCartData?.total - deletedTotalPrice });
            setShowDeletePopup(false);
        } catch (error) {
            Library.showConfirmationMessage("Error: " + error.message);
        }
    };

    const onEditRecipientClick = item => {
        setShippingAddressPopupData(item.itemID);
        setShowShippingAddressPopup(true);
    };
    const handleEditClick = id => {
        setShowCourierInstructionPopup(true);
        onEditClick();
    };
    const showStoreinfoFunc = storeDetails => {
        setShowStoreInformation(true);
        setStoreInformation(storeDetails);
    };
    const isStoreOpen = () => {
        const isWeekday = new Date().getDay() >= 1 && new Date().getDay() <= 5;
        const isSaturday = new Date().getDay() === 6;

        const openHours = isWeekday
            ? BOTagData?.storeDetails?.openHours?.weekdays
            : isSaturday
            ? BOTagData?.storeDetails?.openHours?.saturdays
            : BOTagData?.storeDetails?.openHours?.sundays;

        if (!openHours || !openHours?.hourStart || !openHours?.hourEnd) return false;

        //get current time in Norway Oslo with moment
        const currentTime = moment().utcOffset("+0100").format("HH:mm");

        const hourStart = openHours?.hourStart; // "09:00"
        const hourEnd = openHours?.hourEnd; // "18:00"

        //check if current time in Oslo is between open hours
        return currentTime >= hourStart && currentTime <= hourEnd;
    };

    const showDeleteBoxFun = item => {
        if (deletedID) {
            setDeletedID("");
        } else {
            setDeletedID(item?.itemID);
        }
    };

    const showImageGallery = () => {
        const images = BOTagData?.images;
        if (images && images.length > 0) {
            setShowProductGalleryLightbox({
                isOpen: true,
                slides: images?.map(image => ({ src: image.URL })),
                showProductDetails: false
            });
        }
    };
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        const handleClickOutside = event => {
            if (
                deleteRef.current &&
                !deleteRef.current.contains(event.target) &&
                dropdownMiniRef.current &&
                !dropdownMiniRef.current.contains(event.target)
            ) {
                setDeletedID("");
            }
        };

        document.addEventListener("click", handleClickOutside, true);

        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, [deleteRef, dropdownMiniRef]);
    /* #endregion */

    return (
        <CheckoutItemsStyles className="checkout-cards">
            {showDeletePopup && (
                <CartRemovePopup
                    length={checkoutCartData?.items.length}
                    setShowDeletePopup={setShowDeletePopup}
                    onRemoveFromCart={onRemoveFromCart}
                />
            )}
            {showCourierInstructionPopup && <CourierInstructionsPopup item={item} itemID={singleItemId} />}
            <div className="info-card-status">
                <div className="frame-29741">
                    <div className="frame-29781">
                        <img className="solid" src={OrderFastestIcon} alt="" />
                        <div className="fastest-courier-approx-45-min">
                            {Formatter.capitalizeFirstLetter(BOTagData?.deliveryPreference)} option
                        </div>
                    </div>
                </div>
            </div>
            <div className="frame-313449">
                <div className="frame-313447">
                    <div className="frame-29715">
                        <img className="luan-j-shirt-cosmic-4-3 cursor-pointer" src={BOTagData?.image} alt="" onClick={showImageGallery} />
                        <div className="frame-29710" style={{ width: "100%", overflow: "hidden" }}>
                            <div className="brand-product-type text-one-line d-block w-100">{`${BOTagData?.labelBrandName} / ${BOTagData?.productType}`}</div>
                            <div className="product-name text-one-line d-block w-100">{BOTagData?.productName}</div>
                            <div className="brand-product-type2 text-one-line d-block w-100">{`${BOTagData?.selectedColorName} - ${BOTagData?.selectedSize} - ${BOTagData?.selectedQuantity}`}</div>
                        </div>
                        <div className="frame-29538">
                            <div className="frame-313446">
                                <img
                                    src={horizontalDots}
                                    alt=""
                                    onClick={() => {
                                        showDeleteBoxFun(item);
                                    }}
                                    style={{ cursor: "pointer" }}
                                    ref={deleteRef}
                                />
                                <div
                                    className="dropdown-mini"
                                    ref={dropdownMiniRef}
                                    style={{
                                        display: item.itemID === deletedID ? "" : "none"
                                    }}>
                                    <div
                                        className="frame-75"
                                        onClick={() => {
                                            setQuickBuyData(prevState => {
                                                return {
                                                    ...prevState,
                                                    items: [item],
                                                    total: item?.FormattedData.totalPrice
                                                };
                                            });
                                            setQuickBuyPopup(true);
                                            logGA4Event("QuickBuyItem_PopUp");
                                        }}>
                                        <div className="frame-84">
                                            <div className="frame-313639">
                                                <img className="trash-03" src={QuickBuy} alt="QuickBuy" />
                                                <div className="quickbuy">Quick buy</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="frame-75"
                                        onClick={() => {
                                            onItemRemovePopup(item);
                                            logGA4Event("DeletingItem_PopUp");
                                        }}>
                                        <div className="frame-84">
                                            <div className="frame-313639">
                                                <img className="trash-03" src={OrderRemove} alt="OrderRemove" />
                                                <div className="delete">Delete</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="brand-product-type3">
                                <span>
                                    <span className="brand-product-type-3-span">{FormattedData?.priceStr}</span>
                                    <span className="brand-product-type-3-span2">&nbsp;</span>
                                    <span className="brand-product-type-3-span3">NOK</span>
                                </span>
                            </div>
                            <div className="frame-313265">
                                <img
                                    className="group-3487"
                                    src={OrderMinus}
                                    alt=""
                                    /* onClick={e => (BOTagData?.selectedQuantity > 1 ? onChangeQuantity(item, false) : e.preventDefault())}
                                    style={{ cursor: "pointer", opacity: BOTagData?.selectedQuantity === 1 ? 0.2 : 1 }}*/
                                    style={{ cursor: "no-drop", opacity: 0.2 }}
                                />
                                <div className="_1">{BOTagData?.selectedQuantity}</div>
                                <img
                                    className="group-3488"
                                    src={OrderPlus}
                                    alt=""
                                    /*onClick={e => (BOTagData?.selectedQuantity < 10 ? onChangeQuantity(item, true) : e.preventDefault())}
                                    style={{ cursor: "pointer", opacity: BOTagData?.selectedQuantity === 10 ? 0.2 : 1 }}*/
                                    style={{ cursor: "no-drop", opacity: 0.2 }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="frame-313454">
                    {deliveryMethod === "pickup" ? (
                        <PickupSection distanceToStore={FormattedData?.distanceToStore} storeAddress={storeAddress} />
                    ) : deliveryMethod === "courier" ? (
                        <CourierSection
                            courierImage={BOTagAlgData?.cf?.logoURL}
                            toAddress={UserAddress}
                            instructions={BOTagData?.instructions}
                            priceStr={FormattedData?.deliveryChargesStr}
                            currency="NOK"
                            courierInstructionsData={
                                typeof courierInstructionsData !== "undefined" && courierInstructionsData?.[item?.itemID]
                            }
                            handleEditClick={handleEditClick}
                            item={item}
                        />
                    ) : deliveryMethod === "shipping" ? (
                        <ShippingSection
                            shippingImage={BOTagAlgData?.cf?.logoURL}
                            toAddress={UserAddress}
                            recipient={BOTagData?.recipient}
                            priceStr={FormattedData?.deliveryChargesStr}
                            currency="NOK"
                            onEditRecipientClick={() => onEditRecipientClick(item)}
                        />
                    ) : null}

                    <div className="frame-313448">
                        <div className="frame-313445">
                            <div className="frame-313443">
                                <div className="brand-product-type4">{deliveryMethod === "pickup" ? "AT STORE" : "FROM STORE"}</div>
                                <div className="line-26"></div>
                            </div>
                        </div>
                        <div className="frame-3134462">
                            <div className="frame-29721">
                                <div className="frame-29719">
                                    <div className="frame-297172">
                                        <div className="frame-29716">
                                            <img className="group-3626" src={OrderStore} alt="" />
                                            <div className="frame-313440">
                                                <div className="frame-313441">
                                                    <div className="product-name3">{BOTagData?.storeName}</div>
                                                </div>
                                                <div className="product-name4">
                                                    {isStoreOpen() ? (
                                                        <>
                                                            <span className="open">Open</span> -{" "}
                                                            {deliveryMethod === "pickup"
                                                                ? "Pick up today or any other day during store opening hours. "
                                                                : deliveryMethod === "courier"
                                                                ? "Wolt will deliver to you after the store handles your order. "
                                                                : "You will receive a tracking link when the order is shipped. "}
                                                            {deliveryMethod === "pickup" ? (
                                                                <span
                                                                    className="open-hours"
                                                                    onClick={() => showStoreinfoFunc(BOTagData?.storeDetails)}>
                                                                    See opening hours
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span className="closed">Closed</span> -{" "}
                                                            {deliveryMethod === "pickup"
                                                                ? "Order now and pick up when store opens again. "
                                                                : deliveryMethod === "courier"
                                                                ? "Order now and Wolt will deliver to you when store opens again. "
                                                                : " You can still order now. You will receive a tracking link when the order is shipped. "}
                                                            {deliveryMethod !== "shipping" ? (
                                                                <span
                                                                    className="open-hours"
                                                                    onClick={() => showStoreinfoFunc(BOTagData?.storeDetails)}>
                                                                    See opening hours
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CheckoutItemsStyles>
    );
}

/* #region SMALL COMPONENTS */
const PickupSection = ({ distanceToStore, storeAddress }) => {
    return (
        <PickupSectionStyles className="frame-313453">
            <div className="frame-313444">
                <div className="frame-313443">
                    <div className="brand-product-type4">PICK UP</div>
                    <div className="line-26"></div>
                </div>
            </div>
            <div className="frame-29723">
                <img className="frame-313488" src={OrderPickup} alt="" />
                <div className="frame-313452">
                    <div className="frame-3134522">
                        <div className="frame-29717">
                            <div className="frame-313359">
                                <div className="product-name2">
                                    <span>
                                        <span className="product-name-2-span">Distance to store:&nbsp;</span>
                                        <span className="product-name-2-span2">{distanceToStore}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="frame-313451">
                        <div className="frame-29717">
                            <div className="frame-313359">
                                <div className="product-name2">
                                    <span>
                                        <span className="product-name-2-span3">Store address:&nbsp;</span>
                                        <span className="product-name-2-span4">{storeAddress}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PickupSectionStyles>
    );
};

const CourierSection = ({
    courierImage,
    toAddress,
    instructions = "",
    priceStr,
    currency = "NOK",
    courierInstructionsData,
    handleEditClick,
    item
}) => {
    return (
        <CourierSectionStyles className="frame-313453">
            <div className="frame-313443">
                <div className="frame-3134432">
                    <div className="brand-product-type">COURIER</div>
                    <div className="line-26"></div>
                </div>
            </div>
            <div className="frame-29723">
                <div className="frame-3627">
                    <img className="group-3495" src={OrderCourier} alt="" />
                </div>
                <div className="frame-313452">
                    <div className="frame-313451">
                        <div className="frame-29717">
                            <div className="frame-313359">
                                <div className="product-name">By:</div>
                                <img className="vectorCourier" src={courierImage} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="frame-3134522">
                        <div className="frame-29717">
                            <div className="frame-3133592">
                                <span className="to-span">
                                    To: <span className="to-span2">{toAddress}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="frame-3134532">
                        <div className="frame-29717">
                            <div className="frame-3133592">
                                <span className="instructions-span">
                                    Instructions:{" "}
                                    <span className="instructions-span2">
                                        {!courierInstructionsData ? "No instructions added" : courierInstructionsData}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="frame-313455">
                    <div className="frame-313450">
                        <div className="brand-product-type2">
                            {priceStr ? (
                                <span>
                                    <span className="brand-product-type-2-span">{priceStr}</span>
                                    <span className="brand-product-type-2-span2">&nbsp;</span>
                                    <span className="brand-product-type-2-span3">{currency}</span>
                                </span>
                            ) : (
                                <div className="brand-product-type2">FREE</div>
                            )}
                        </div>
                    </div>
                    <div className="frame-3134523"></div>

                    <div
                        className="frame-3134512"
                        onClick={() => {
                            handleEditClick(item?.itemID);
                        }}>
                        <img className="edit-02" src={OrderEdit} alt="" />
                    </div>
                </div>
            </div>
        </CourierSectionStyles>
    );
};

const ShippingSection = ({ shippingImage, toAddress, recipient = "", priceStr, currency = "NOK", onEditRecipientClick }) => {
    return (
        <ShippingSectionStyles className="frame-313453">
            <div className="frame-313443">
                <div className="frame-3134432">
                    <div className="brand-product-type">SHIPPING</div>
                    <div className="line-26"></div>
                </div>
            </div>
            <div className="frame-29723">
                <img className="frame-313487" src={OrderShipping} alt="" />
                <div className="frame-313452">
                    <div className="frame-313451">
                        <div className="frame-29717">
                            <div className="frame-313359">
                                <div className="product-name">By:</div>
                                <div className="frame-29607">
                                    <img className="image-153" src={shippingImage} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="frame-313454">
                        <div className="frame-29717">
                            <div className="frame-3133592">
                                <span className="to-span">
                                    To: <span className="to-span2">{toAddress}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="frame-3134522">
                        <div className="frame-29717">
                            <div className="frame-3133592">
                                <span className="recipient-span">Recipient: </span>
                                {!recipient ? (
                                    <span className="to-span2">No recipient added</span>
                                ) : (
                                    <span className="to-span2">{recipient}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="frame-313455">
                    <div className="frame-313450">
                        {priceStr ? (
                            <span>
                                <span className="brand-product-type-2-span">{priceStr}</span>
                                <span className="brand-product-type-2-span2">&nbsp;</span>
                                <span className="brand-product-type-2-span3">{currency}</span>
                            </span>
                        ) : (
                            <div className="brand-product-type2">FREE</div>
                        )}
                    </div>
                    <div className="frame-3134512" onClick={onEditRecipientClick}>
                        <img className="edit-02" src={OrderEdit} alt="" style={{ cursor: "pointer" }} />
                    </div>
                </div>
            </div>
        </ShippingSectionStyles>
    );
};

/* #endregion */

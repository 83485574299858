import React, { useEffect, useState, useRef } from "react";
import { GoogleMap, Marker, useJsApiLoader, MarkerClusterer, InfoWindow } from "@react-google-maps/api";
import mapIcon from "../../../assets/images/currentLocationn.png";
import storeIcon from "../../../assets/images/newMapIcon.png";
import clusterImg from "../../../assets/images/m5.png";
import styled from "styled-components";
import { ReactComponent as ChevronRight0 } from "../../../assets/images/map-pen.svg";
const StyledPage = styled.div`
    &.googleMapsContainer {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        position: relative;
    }
    .mapCanvas {
        -webkit-overflow: hidden;
        -ms-overflow: hidden;
        overflow: hidden;
        position: relative;
    }

    .mapCanvas > div > div {
        background-color: #17263c !important;
    }
    .mapCanvas .gm-style {
        border-radius: 10px;
    }
    .mapCanvas .gmnoprint .gm-style-mtc {
        display: none;
    }
    .mapCanvas .gm-svpc {
        display: none;
    }
    .mapCanvas .gmnoprint button.gm-control-active {
        background: #212121 !important;
        border-radius: 10px !important;
        margin: 0px 0 4px !important;
    }
    .mapCanvas .gmnoprint button.gm-control-active:hover {
        background: #272727 !important;
    }
    .mapCanvas .gmnoprint button.gm-control-active img {
        display: none;
    }

    .mapCanvas .gmnoprint button.gm-control-active:first-child::after {
        content: "\\2b";
        font-family: "Font Awesome 6 Pro";
        color: #fff;
        font-size: 18px;
        font-weight: 100;
    }
    .mapCanvas .gmnoprint button.gm-control-active:last-child::after {
        content: "\\f068";
        font-family: "Font Awesome 6 Pro";
        color: #fff;
        font-size: 18px;
        font-weight: 100;
    }
    .mapCanvas .gm-control-active.gm-fullscreen-control {
        background-color: #212121 !important;
        border-radius: 10px !important;
    }
    .mapCanvas .gm-control-active.gm-fullscreen-control:hover {
        background: #272727 !important;
    }
    .mapCanvas .gm-control-active.gm-fullscreen-control::after {
        content: "\\f31d";
        font-family: "Font Awesome 6 Pro";
        color: #fff;
        font-size: 18px;
        font-weight: 100;
    }
    .mapCanvas .gm-control-active.gm-fullscreen-control img {
        display: none;
    }
    .mapCanvas .gm-style-cc {
        display: none !important;
    }
    .mapCanvas .gmnoprint > div > div {
        background: transparent !important;
        box-shadow: none !important;
    }
    .mapCanvas .gmnoprint .gm-style-cc {
        display: none;
    }
    .mapCanvas .gmnoprint.gm-style-cc {
        display: none;
    }

    .mapCanvas > div {
        -ms-overflow: hidden;
        overflow: hidden;
        -webkit-overflow: hidden;
    }
    &.googleMapsContainer > div {
        -ms-overflow: hidden;
        overflow: hidden;
        -webkit-overflow: hidden;
        border-radius: 10px;
    }
    &.googleMapsContainer > div > div {
        background-color: #17263c !important;
        border-radius: 10px;
        -webkit-border-radius: 10px;
    }
    .gm-style {
        border-radius: 10px;
    }
    .gmnoprint .gm-style-mtc {
        display: none;
    }
    .gm-svpc {
        display: none;
    }
    .gmnoprint button.gm-control-active {
        background: #212121 !important;
        border-radius: 10px !important;
        margin: 0px 0 4px !important;
    }
    .gmnoprint button.gm-control-active:hover {
        background: #272727 !important;
    }
    .gmnoprint button.gm-control-active img {
        display: none;
    }
    .gmnoprint button.gm-control-active:first-child::after {
        content: "\\2b";
        font-family: "Font Awesome 6 Pro";
        color: #fff;
        font-size: 18px;
        font-weight: 100;
    }
    .gmnoprint button.gm-control-active:last-child::after {
        content: "\\f068";
        font-family: "Font Awesome 6 Pro";
        color: #fff;
        font-size: 18px;
        font-weight: 100;
    }
    .gm-control-active.gm-fullscreen-control {
        background-color: #212121 !important;
        border-radius: 10px !important;
    }
    .gm-control-active.gm-fullscreen-control:hover {
        background: #272727 !important;
    }
    .gm-control-active.gm-fullscreen-control::after {
        content: "\\f31d";
        font-family: "Font Awesome 6 Pro";
        color: #fff;
        font-size: 18px;
        font-weight: 100;
    }
    .gm-control-active.gm-fullscreen-control img {
        display: none;
    }
    .gm-style-cc {
        display: none !important;
    }
    .gmnoprint > div > div {
        background: transparent !important;
        box-shadow: none !important;
    }
    .gmnoprint .gm-style-cc {
        display: none;
    }
    .gmnoprint.gm-style-cc {
        display: none;
    }
    .share-my-location-main {
        position: relative;
        z-index: 9;
    }
    .gm-style-iw-chr {
        display: none;
    }
    .gm-style-iw-tc {
        display: none !important;
    }
    .gm-style-iw-t {
        bottom: 12px !important;
    }
    .gm-style-iw-d {
        height: 100%;
        background-color: #212121;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 16px 16px 16px 24px;
        overflow: hidden !important;
        cursor: pointer;
    }
    .gm-style-iw-c {
        border-radius: 16px;
        width: 300px !important;
        height: 66px !important;
        padding: 0px !important;
        margin-top: -60px !important;
        background-color: #212121 !important;
    }
    .store-name {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 16px;
    }
    .main-parent {
        display: flex;
        flex-direction: row;
        -webkit-box-align: center;
        align-items: center;
        gap: 15px;
        justify-content: space-between;
    }
    .group-1 {
        display: flex;
        flex-direction: column;
        gap: 5px;
        text-align: left;
        font-family: OpenSans-Regular, sans-serif;
    }
    .share-location {
        color: #8c8c8c;
        font-size: 12px;
    }
    .address-text {
        font-size: 12px;
    }
    .address-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 173px;
    }
    /* .group-2 {
        position: absolute;
        right: 20px;
    } */
`;

/**
 * Renders a Google Maps component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.coords - The coordinates object.
 * @param {number} props.coords.latitude - The latitude coordinate.
 * @param {number} props.coords.longitude - The longitude coordinate.
 * @param {Object} props.storeCoords - The store coordinates object.
 * @param {number} props.storeCoords.latitude - The store latitude coordinate.
 * @param {number} props.storeCoords.longitude - The store longitude coordinate.
 * @param {boolean} [props.highlightStore=false] - The flag to highlight the store.
 * @param {boolean} [props.farawayZoom=false] - The flag to use a faraway zoom.
 * @param {Array} props.allStoresCoords - The array of all store coordinates.
 * @returns {JSX.Element} The Google Maps component.
 */
export default function GoogleMaps({
    coords,
    storeCoords,
    highlightStore = false,
    farawayZoom = false,
    allStoresCoords = [],
    showStoreinfoFunc,
    userAddress,
    isMobile,
    changeLocationClicked
}) {
    /* #region VARS */
    const { isLoaded } = useJsApiLoader({ id: "google-map-script", googleMapsApiKey: process.env.REACT_APP_MAP_KEY });

    const mapRef = useRef();
    const [mapObject, setMapObject] = useState("");
    const [coordIPLocation, setCoordIPLocation] = useState({ lat: 61.1542163, lng: 9.0619226 });
    const [centerAtMyCoords, setCenterAtMyCoords] = useState({ lat: 0, lng: 0 });
    const [centerAtStoreCoords, setCenterAtStoreCoords] = useState({ lat: 0, lng: 0 });
    const [showInfoBox, setShowInfoBox] = useState(false);
    /* #endregion */

    /* #region METHODS */
    const onLoad = map => {
        if (!farawayZoom) map.setZoom(16);
        else map.setZoom(10);
        setMapObject(map);
    };

    const refreshFitBoundaries = map => {
        try {
            const markerArray = [];
            markerArray.push({ lat: coords?.latitude, lng: coords?.longitude });

            if (!allStoresCoords.length && storeCoords?.latitude && storeCoords?.longitude) {
                markerArray.push({
                    lat: storeCoords?.latitude,
                    lng: storeCoords?.longitude
                });
            } else if (allStoresCoords.length) {
                allStoresCoords.length &&
                    allStoresCoords.forEach(storePos => {
                        markerArray.push(storePos);
                    });
            }

            const coordinates = { lat: [], lng: [] };
            markerArray.forEach(l => {
                coordinates.lat.push(Number(l.lat));
                coordinates.lng.push(Number(l.lng));
            });

            const bounds = new window.google.maps.LatLngBounds({
                lat: coords?.latitude,
                lng: coords?.longitude
            });

            markerArray.forEach(location => {
                bounds.extend({ lat: Number(location.lat), lng: Number(location.lng) });
            });
            map.fitBounds(bounds);
            if (farawayZoom) {
                setTimeout(() => {
                    map.setZoom(map.getZoom() - 5);
                }, 100);
            }
        } catch (error) {
            console.error("Error refreshing boundaries:", coords?.latitude, coords?.longitude, error);
        }
    };

    const shouldCenterAtStore = () => {
        return (
            highlightStore &&
            storeCoords?.longitude &&
            storeCoords?.latitude &&
            !allStoresCoords.length &&
            typeof storeCoords !== "undefined" &&
            centerAtStoreCoords?.lat &&
            centerAtStoreCoords?.lng
        );
    };

    const handleShowInfoBoxFun = () => {
        setShowInfoBox(!showInfoBox);
    };
    /* #endregion */

    /* #region EFFECTS */
    /*useEffect(() => {
        getLocationByIP().then(res => {
            if (res?.latitude && res?.longitude) setCoordIPLocation({ lat: res.latitude, lng: res.longitude });
        });
    }, []);*/

    useEffect(() => {
        if (!mapObject) return;
        if (farawayZoom === false && coordIPLocation?.lat && coordIPLocation?.lng) mapObject.setZoom(6);
        else if (farawayZoom === false) mapObject.setZoom(16);
        else if (farawayZoom === true) mapObject.setZoom(10);

        if (coords?.latitude && coords?.longitude && storeCoords?.latitude && storeCoords?.longitude && allStoresCoords) {
            refreshFitBoundaries(mapObject);
        }
    }, [storeCoords, allStoresCoords, farawayZoom, mapObject, coordIPLocation]);

    useEffect(() => {
        setCenterAtMyCoords({
            lat: coords?.latitude ? coords?.latitude : coordIPLocation?.lat,
            lng: coords?.longitude ? coords?.longitude : coordIPLocation?.lng
        });
    }, [coords, coordIPLocation]);

    useEffect(() => {
        if ((!highlightStore && mapObject) || typeof storeCoords === "undefined") {
            setCenterAtMyCoords({ lat: coords?.latitude ? coords?.latitude : 0, lng: coords?.longitude ? coords?.longitude : 0 });
            if (mapObject && !farawayZoom) {
                mapObject?.setZoom(16);
            }
        }
    }, [highlightStore, storeCoords]);

    useEffect(() => {
        if (storeCoords?.latitude && storeCoords?.longitude) {
            setCenterAtStoreCoords({ lat: storeCoords?.latitude, lng: storeCoords?.longitude });
        } else {
            setCenterAtStoreCoords({ lat: 0, lng: 0 });
        }
    }, [storeCoords]);
    /* #endregion */
    return (
        <StyledPage className="googleMapsContainer" style={{ height: "100%" }}>
            {isLoaded && (
                <>
                    <GoogleMapWrapper
                        mapRef={mapRef}
                        onLoad={onLoad}
                        center={shouldCenterAtStore() ? centerAtStoreCoords : centerAtMyCoords}
                        userAddress={userAddress}
                        isMobile={isMobile}>
                        {centerAtMyCoords && userAddress && (
                            <>
                                <Marker
                                    position={centerAtMyCoords}
                                    draggable={false}
                                    visible={true}
                                    zIndex={9999}
                                    icon={mapIcon}
                                    onClick={handleShowInfoBoxFun}
                                />
                                {showInfoBox && (
                                    <InfoWindow position={centerAtMyCoords}>
                                        <div className="main-parent" onClick={changeLocationClicked}>
                                            <div className="group-1">
                                                <div className="share-location">Your shared location</div>
                                                <div
                                                    className="address-text"
                                                    style={
                                                        isMobile ? { width: "auto" } : {}
                                                    }>{`${userAddress?.street} ${userAddress?.streetNumber}, ${userAddress?.postCode} ${userAddress?.city}`}</div>
                                            </div>
                                            <div className="group-2">
                                                <ChevronRight0 className="chevron-right" src="chevron-right0.svg" />
                                            </div>
                                        </div>
                                    </InfoWindow>
                                )}
                            </>
                        )}

                        {shouldCenterAtStore && centerAtStoreCoords.lat && (
                            <Marker
                                position={centerAtStoreCoords}
                                draggable={false}
                                visible={true}
                                zIndex={999}
                                icon={storeIcon}
                                onClick={showStoreinfoFunc}
                            />
                        )}

                        <MarkerClustererWrapper allStoreData={allStoresCoords} />
                    </GoogleMapWrapper>
                </>
            )}
        </StyledPage>
    );
}

/* #region SMALL COMPONENTS */

const GoogleMapWrapper = ({ children, center, mapRef, onLoad, userAddress, isMobile }) => {
    return (
        <GoogleMap
            ref={mapRef}
            onLoad={onLoad}
            center={center}
            mapContainerStyle={{ width: "100%", height: "100%" }}
            clickableIcons={false}
            options={{
                styles: [
                    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
                    {
                        elementType: "labels.text.stroke",
                        stylers: [{ color: "#242f3e" }]
                    },
                    {
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#909eb2" }]
                    },
                    {
                        featureType: "administrative",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#d3d9e1" }]
                    },
                    {
                        featureType: "landscape",
                        elementType: "labels",
                        stylers: [{ visibility: "off" }]
                    },
                    {
                        featureType: "administrative.locality",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#ffffff" }]
                    },
                    {
                        featureType: "poi",
                        stylers: [{ visibility: "off" }]
                    },
                    {
                        featureType: "poi.business",
                        stylers: [{ visibility: "off" }]
                    },
                    {
                        featureType: "poi.park",
                        elementType: "geometry",
                        stylers: [{ color: "#154043" }]
                    },
                    {
                        featureType: "poi.park",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#6b9a76" }]
                    },
                    {
                        featureType: "road",
                        elementType: "geometry",
                        stylers: [{ color: "#42566d" }]
                    },
                    {
                        featureType: "road",
                        elementType: "geometry.stroke",
                        stylers: [{ color: "#212a37" }]
                    },
                    {
                        featureType: "road",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#9ca5b3" }]
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry",
                        stylers: [{ color: "#42566d" }]
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry.stroke",
                        stylers: [{ color: "#000000" }]
                    },
                    {
                        featureType: "road.highway",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#ffffff" }]
                    },
                    {
                        featureType: "transit",
                        elementType: "geometry",
                        stylers: [{ color: "#2f3948" }, { visibility: "off" }]
                    },
                    {
                        featureType: "transit.station",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#d59563" }]
                    },
                    {
                        featureType: "water",
                        elementType: "geometry",
                        stylers: [{ color: "#000000" }]
                    },
                    {
                        featureType: "water",
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#8ab4f8"
                            }
                        ]
                    },
                    {
                        featureType: "water",
                        elementType: "labels.text.stroke",
                        stylers: [{ color: "#8ab4f8" }]
                    },
                    {
                        selector: ":focus",
                        stylers: [{ outline: "none" }]
                    },
                    {
                        selector: "*:focus",
                        stylers: [{ outline: "none" }]
                    }
                ],
                draggable: userAddress ? true : false,
                gestureHandling: isMobile ? "cooperative" : ""
            }}>
            {children}
        </GoogleMap>
    );
};

const MarkerClustererWrapper = ({ allStoreData }) => {
    return (
        <MarkerClusterer
            styles={[
                { url: clusterImg, height: 53, width: 53 },
                { url: clusterImg, height: 53, width: 53 },
                { url: clusterImg, height: 53, width: 53 },
                { url: "/static/images/cluster/m4.png", height: 53, width: 53 },
                { url: clusterImg, height: 53, width: 53 }
            ]}>
            {clusterer => allStoreData.map((_d, i) => <Marker key={i} position={_d} clusterer={clusterer} icon={storeIcon} />)}
        </MarkerClusterer>
    );
};

/* #endregion */

import React from "react";
import styled from "styled-components";
import LayoutContext from "../../contexts/LayoutContext";

const StyledPage = styled.div`
    &.message-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        font-size: 2rem;
        color: #000000;
        flex-direction: column;
        width: 100%;
    }

    .go-back-btn {
        display: flex;
        width: 404px;
        height: 48px;
        justify-content: center;
        align-items: center;

        border-radius: 5px;
        background: var(--Buttons-button-primary, #f7665f);

        /* bt-drop-shadow */
        box-shadow: 0px 0px 4px 0px rgba(30, 38, 49, 0.35);

        color: var(--Text-text-primary, #fff);
        text-align: center;

        /* Button Text - Medium - Regular */
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
    }
`;

const PageNotFound = () => {
    /* #region VARS */
    const {
        setShowHeader,
        setShowFooter,
        setShowPolicyPopup,
        setShowShippingAddressPopup,
        setShowBOImagePageID,
        setShowRightSidebar,
        setShowRightUserLocationPanel
    } = React.useContext(LayoutContext);
    /* #endregion */

    /* #region EFFECTS */
    React.useEffect(() => {
        setShowHeader(false);
        setShowFooter(false);
        setShowRightSidebar(false);
        setShowPolicyPopup(false);
        setShowShippingAddressPopup(false);
        setShowBOImagePageID(false);
        setShowRightUserLocationPanel(false);
    }, []);
    /* #endregion */

    return (
        <StyledPage className="message-container">
            <div style={{ textAlign: "center" }}>
                Oops!
                <br />
                <br />
                This is a broken URL...
            </div>
            <br />
            <div className="go-back-btn cursor-pointer" style={{}} onClick={() => (window.location.href = "/feed")}>
                Go to BOtag feed
            </div>
        </StyledPage>
    );
};

export default PageNotFound;

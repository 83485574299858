import React from "react";
import styled from "styled-components";
import ShareLocLogo from "./../../../assets/images/share-loc-logo.svg";
import ShareLocArrow from "./../../../assets/images/arraow-location.svg";
import CloseBtn from "./../../../assets/images/close-btn.svg";
import Spinner from "../../../assets/images/spinner.svg";
import LayoutContext from "../../../contexts/LayoutContext";
const StyledPage = styled.div`
    &.share-location-overlay,
    &.share-location-overlay * {
        box-sizing: border-box;
    }
    &.share-location-overlay {
        border-radius: 10px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        -webkit-box-pack: start;
        justify-content: flex-start;
        flex-shrink: 0;
        box-shadow: rgb(0, 0, 0) 4px 4px 87.4px 0px;
        background: #212121;
        position: absolute;
        z-index: 9;
        /* width: 100%; */
        margin: 16px;
    }
    .frame-3590 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-295922361 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .group-3414 {
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        position: relative;
        overflow: visible;
    }
    .group-3413 {
        margin: 0 0 0 -12px;
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        position: static;
    }
    .group-3664 {
        width: 50px;
        height: 50px;
        position: static;
    }
    .ellipse-80 {
        background: var(--buttons-button-tertiary, #232832);
        border-radius: 50%;
        width: 50px;
        height: 50px;
        position: absolute;
        left: 38px;
        top: 0px;
    }
    .navigation-pointer-01 {
        position: absolute;
        right: 5px;
        top: 5px;
    }
    .frame-295922360 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-295922362 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-295922363 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .b-otag-wants-to-use-your-location {
        text-align: center;
        font-size: var(--heading-3-font-size, 16px);
        line-height: var(--heading-3-line-height, 24px);
        font-weight: var(--heading-3-font-weight, 600);
        position: relative;
        align-self: stretch;
    }
    .b-otag-wants-to-use-your-location-span {
        color: var(--text-text-brand, #f7665f);
        font-size: var(--heading-3-font-size, 16px);
        line-height: var(--heading-3-line-height, 24px);
        font-weight: var(--heading-3-font-weight, 600);
    }
    .b-otag-wants-to-use-your-location-span2 {
        color: var(--text-text-primary, #ffffff);
        font-size: var(--heading-3-font-size, 16px);
        line-height: var(--heading-3-line-height, 24px);
        font-weight: var(--heading-3-font-weight, 600);
    }
    .we-will-use-your-location-to-find-the-best-stores-and-prices-for-you {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        height: 48px;
    }
    .btn-xs-primary {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 5px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 100%;
        height: 30px;
        position: relative;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-small-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-small-font-size, 12px);
        line-height: var(--button-text-small-line-height, 16px);
        font-weight: var(--button-text-small-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .x-close {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: absolute;
        left: 308px;
        top: 16px;
        transform: translate(0px, 0px);
        overflow: visible;
    }

    .compound-class {
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .close-btn-location {
        position: absolute;
        right: 10px;
        top: 5px;
        cursor: pointer;
    }
    .spinner-text {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
    }
    .spinner-img {
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
    .close-btn-location.disable {
        opacity: 0.2;
    }
`;
export default function ShareMyLocation({ locationBtnClick, setShowMyLocation }) {
    const { locationLoader } = React.useContext(LayoutContext);
    return (
        <StyledPage className="share-location-overlay">
            <div className="compound-class">
                <div className="frame-3590">
                    <div className="frame-295922361">
                        <img className="group-3414" src={ShareLocLogo} alt="ShareLocLogo" />
                        <div className="group-3413">
                            <div className="group-3664">
                                <div className="ellipse-80"></div>
                                <img className="navigation-pointer-01" src={ShareLocArrow} alt="ShareLocArrow" />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={`close-btn-location ${locationLoader ? "disable" : ""}`}
                    onClick={e => (locationLoader ? e.preventDefault : setShowMyLocation(false))}>
                    <img className="close-btn" src={CloseBtn} alt="ShareLocArrow" />
                </div>
            </div>
            <div className="frame-295922360">
                <div className="frame-295922362">
                    <div className="frame-295922363">
                        <div className="b-otag-wants-to-use-your-location">
                            <span>
                                <span className="b-otag-wants-to-use-your-location-span">BOtag</span>{" "}
                                <span className="b-otag-wants-to-use-your-location-span2">wants to use your location</span>
                            </span>
                        </div>
                        <div className="we-will-use-your-location-to-find-the-best-stores-and-prices-for-you">
                            We will use your location to find the best stores and prices for you.
                        </div>
                    </div>
                    <div
                        className="btn-xs-primary cursor-pointer"
                        onClick={e => (locationLoader ? e.preventDefault() : locationBtnClick())}>
                        <div className="button">
                            {locationLoader ? (
                                <span className="spinner-text">
                                    <img src={Spinner} alt="" className="spinner-img" />
                                    &nbsp;
                                    {"  "}Detecting Location
                                </span>
                            ) : (
                                "Share my location"
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </StyledPage>
    );
}

import styled from "styled-components";
import Loader from "../../../assets/images/loader.svg";
import React from "react";
import LayoutContext from "../../../contexts/LayoutContext";
/* #region SCOPED CSS */
const StyledPage = styled.div`
    &.defaultPopupOverlay {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        top: 0;
        background-color: rgba(24, 24, 24, 0.5);
        z-index: 99999999;
        max-width: 100%;
        backdrop-filter: blur(6px);
    }
    .loader-bg {
        position: absolute;
        right: 140px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 16px;
    }
    .loader-bg-mobile {
        position: absolute;
        z-index: 999;
        height: 100%;
        width: 100%;
        backdrop-filter: blur(7px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 16px;
        background-color: rgba(0, 0, 0, 0.4);
    }
`;
/* #endregion SCOPED CSS */
export default function OrderSubmitLoader({ visible }) {
    const { isMobile } = React.useContext(LayoutContext);
    return (
        <StyledPage className="defaultPopupOverlay">
            <div>{""}</div>
            {localStorage.getItem("isLoggedIn") === "true" && (
                <div className={isMobile ? "loader-bg-mobile" : "loader-bg"}>
                    <img src={Loader} alt="" style={{ width: "70px" }} />
                    <div style={{ fontSize: "16px", fontWeight: 600, color: "rgba(255, 255, 255, 1)" }}>Placing order...</div>
                </div>
            )}
        </StyledPage>
    );
}

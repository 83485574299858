// import RefundedIcon from "./RefundedIcon";
//extract phoneNumber without country code
export const extractPhoneNumber = (countryCode, phoneNumber) => {
    if (phoneNumber?.startsWith(countryCode)) {
        return phoneNumber?.slice(countryCode.length);
    }
    return phoneNumber;
};

//function to format phone number with pattern mainly used for saving formatted numbers in onboarding, accountPanel and paymentPopup

export const phoneNumberPattern = (number, pattern) => {
    number = number?.replace(/\D/g, "");

    let formattedNumber = [];
    let numberIndex = 0;

    for (let i = 0; i < pattern?.length; i++) {
        if (pattern?.[i] === ".") {
            if (numberIndex < number?.length) {
                formattedNumber.push(number?.[numberIndex]);
                numberIndex++;
            }
        } else {
            formattedNumber?.push(pattern?.[i]);
        }
    }

    return formattedNumber?.join("");
};
// Function to save the location object to localStorage
export const saveLocation = location => {
    const locationObject = { location };
    localStorage.setItem("userLocation", JSON.stringify(locationObject));
};

// Function to retrieve the location object from localStorage
export const getLocation = () => {
    const locationString = localStorage.getItem("userLocation");
    if (locationString) {
        return JSON.parse(locationString).location;
    }
    return null;
};
// Function to save the location object to localStorage
export const saveCoordinates = coords => {
    const coordsObject = { coords };
    localStorage.setItem("userCoords", JSON.stringify(coordsObject));
};

// Function to retrieve the location object from localStorage
export const getCoordinates = () => {
    const coordsString = localStorage.getItem("userCoords");
    if (coordsString) {
        return JSON.parse(coordsString).coords;
    }
    return null;
};

const setCookie = (name, value, hours) => {
    const date = new Date();
    date.setTime(date.getTime() + hours * 60 * 60 * 1000); // changed to hours
    // date.setTime(date.getTime() + hours * 1000); // Changed to 10 seconds
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
};
export const getCookie = name => {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
};

const imageExists = (array, ourID) => {
    return array.some(item => item.ourID === ourID);
};

export const storeImageDetails = (ourID, timestamp) => {
    const storedData = JSON.parse(getCookie("recentlyViewedImages")) || [];

    if (!imageExists(storedData, ourID)) {
        const newImageDetails = { ourID, timestamp };

        storedData.push(newImageDetails);

        setCookie("recentlyViewedImages", JSON.stringify(storedData), 48); // Set cookie for 48 hours
    }
};

import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Vector30 from "../../../assets/images/vector-30.svg";
import Vector40 from "../../../assets/images/vector-40.svg";
import Explore from "../../../assets/images/explore-welcome.svg";
import logGA4Event from "../../../utils/firebase/analytics";
import LayoutContext from "../../../contexts/LayoutContext";
import UserContext from "../../../contexts/UserContext";
import AppContext from "../../../contexts/AppContext";
/* #region CSS */
const StyledPage = styled.div`
    &.frame-313709,
    &.frame-313709 * {
        box-sizing: border-box;
    }
    &.frame-313709 {
        background: #121212;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 400px;
    }
    .vector-3,
    .vector-4 {
        position: absolute;
        width: 100%;
        height: 100%; /* or set a specific height */
        background-size: 100% 100%; /* add this line */
        background-position: center;
    }
    .frame-295922529 {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
        /* top: -20px; */
    }
    .frame-313714 {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        justify-content: center;

        position: relative;
    }
    .welcome-to-b-otag {
        color: #ffffff;
        text-align: center;
        font-family: "ItcAvantGardeGothicProDemi-Regular", sans-serif;
        font-size: 64px;
        font-weight: 600;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .shop-your-favorite-fashion-brands-from-over-50-physical-stores {
        color: #ffffff;
        text-align: center;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 24px;
        line-height: 24px;
        font-weight: 600;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-295922536 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .btn-lg-solid {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 30px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 268.8px;
        height: 57.6px;
        position: relative;
        box-shadow: 4.8px 4.8px 104.88px 0px rgba(0, 0, 0, 1);
        cursor: pointer;
    }
    .frame-29590 {
        display: flex;
        flex-direction: row;
        gap: 9.6px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .compass-03 {
        flex-shrink: 0;
        width: 30px;
        height: 30px;
        position: relative;
        overflow: visible;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 16.80000114440918px;
        line-height: 28.8px;
        font-weight: 600;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btn-lg-solid2 {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 30px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 268.8px;
        height: 57.6px;
        position: relative;
        box-shadow: 4.8px 4.8px 104.88px 0px rgba(0, 0, 0, 1);
        cursor: pointer;
    }
    .create-your-b-oaccount {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 16.80000114440918px;
        line-height: 28.8px;
        font-weight: 600;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    @media only screen and (max-width: 992px) {
        &.frame-313709 {
            height: 300px;
        }
        .frame-295922529 {
            width: 100%;
        }
        .frame-313714 {
            width: 302px;
        }
        .welcome-to-b-otag {
            font-size: 32px;
            line-height: unset;
        }
        .shop-your-favorite-fashion-brands-from-over-50-physical-stores {
            font-size: 16px;
        }
        .btn-lg-solid2 {
            display: none;
        }
    }
`;
/* #endregion CSS */
export default function Welcome() {
    /* #region VARS */
    const navigate = useNavigate();
    const { setShowLoginForm, setShowRegistration, setShowUserLoginRegisterPopup } = React.useContext(LayoutContext);
    const { isUserLoggedIn } = React.useContext(UserContext);
    const { setSelectedBrandID } = React.useContext(AppContext);
    /* #endregion VARS */
    /* #region Methods */
    const navigateToFeedFun = () => {
        navigate("/feed");
        setSelectedBrandID("");
    };
    const createAccountFun = () => {
        logGA4Event("CreateYourBOaccount");
        setShowUserLoginRegisterPopup(true);
        setShowLoginForm(false);
        setShowRegistration(true);
    };
    /* #endregion Methods */
    return (
        <StyledPage className="frame-313709">
            <div className="vector-3" style={{ backgroundImage: `url(${Vector30})` }} />
            <div className="vector-4" style={{ backgroundImage: `url(${Vector40})` }} />
            <div className="frame-295922529">
                <div className="frame-313714">
                    <div className="welcome-to-b-otag">Welcome to BOtag!</div>
                    <div className="shop-your-favorite-fashion-brands-from-over-50-physical-stores">
                        Shop your favorite fashion brands, from over 50+ physical stores.
                    </div>
                </div>
                <div className="frame-295922536">
                    <div className="btn-lg-solid" onClick={navigateToFeedFun}>
                        <div className="frame-29590">
                            <img className="compass-03" src={Explore} alt="" />
                            <div className="button">Explore BOimages</div>
                        </div>
                    </div>
                    {!isUserLoggedIn && (
                        <div className="btn-lg-solid2" onClick={createAccountFun}>
                            <div className="frame-29590">
                                <div className="create-your-b-oaccount">Create your BOaccount</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </StyledPage>
    );
}

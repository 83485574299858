import React from "react";
import LightBoxWrapper from "../../../common/dialogs/LightBoxWrapper";
import solid0 from "../../../../assets/images/solid0.svg";
import vector0 from "../../../../assets/images/woltvector.png";
import loader from "../../../../assets/images/loader.svg";
import ActiveCheapest from "../../../../assets/images/active-cheapest.svg";
import logGA4Event from "../../../../utils/firebase/analytics";
import LayoutContext from "../../../../contexts/LayoutContext";

//NOTE: ALL STYLED CSS CLASSES ARE INSIDE BOTAG.JSX INSTEAD OF HERE

export default function BOTagHeader({
    isLoadingPage,
    deliveryMethod,
    botagData,
    selectedQuantity,
    selectedSize,
    selectedColor,
    onDeliveryMethodChanged,
    deliveryPreference,
    showSizeSelection,
    showQuantitySelection,
    showColorSelection,
    setShowSizeSelection,
    setShowQuantitySelection,
    setShowColorSelection,
    showDeliveryMethodSelection,
    setShowDeliveryMethodSelection,
    //showLightBoxImages,
    //setShowLightBoxImages,
    setShowRightSidebar,
    selectedNewSize,
    selectedNewColor,
    selectedNewQuantity,
    selectedImage,
    productImageLoader,
    fastestOption,
    botagAlgorithmnIsLoading,
    animate
}) {
    /* #region VARS */
    const [image, setImage] = React.useState(botagData?.image || "");
    const [images, setImages] = React.useState(botagData?.images || []);
    const brandName = botagData?.labelBrandName || "--";
    const productType = botagData?.productType || "--";
    const productName = botagData?.productName || "--";

    const { setShowProductGalleryLightbox } = React.useContext(LayoutContext);
    /* #endregion */

    /* #region METHODS */
    const fetchImages = () => {
        return images.map(image => ({ src: image.URL }));
    };

    const onShowLightBoxImagesClicked = () => {
        setShowRightSidebar(true);
        if (!!image)
            setShowProductGalleryLightbox({
                isOpen: true,
                slides: fetchImages()
            });
    };
    /* #endregion */

    /* #region EFFECTS */
    React.useEffect(() => {
        if (selectedImage.length) {
            setImage(selectedImage[0]?.URL);
            setImages(selectedImage);
        }
    }, [selectedImage]);
    /* #endregion */

    return (
        <>
            {/* <LightBoxWrapper open={showLightBoxImages} close={() => setShowLightBoxImages(false)} slides={fetchImages()} /> */}

            <div className="frame-313504">
                <div className="frame-313320">
                    <div className="frame-313322">
                        {productImageLoader ? (
                            <div className="product-image-loader cursor-pointer">
                                <img src={loader} alt="loader" style={{ width: "20px" }} />
                            </div>
                        ) : (
                            <img
                                className={`product-image cursor-pointer item ${animate ? "animate" : ""}`}
                                src={!image ? loader : image}
                                alt="Product"
                                onClick={onShowLightBoxImagesClicked}
                                style={!image ? { padding: "15px", objectFit: "contain" } : {}}
                                id="item"
                            />
                        )}
                        <div className="frame-313314">
                            <div className="frame-313317">
                                <div className="frame-313316">
                                    <div className="sams-e-sams-e-shirt">{`${brandName} / ${productType}`}</div>
                                    <div className="luan-j-shirt-14826">{productName}</div>
                                </div>
                                <div className="frame-313313">
                                    <div
                                        className="group-sizes cursor-pointer"
                                        onClick={() => {
                                            setShowSizeSelection(true);
                                            setShowDeliveryMethodSelection(false);
                                        }}>
                                        <div className="ellipse-12"></div>
                                        <div className="m">{selectedNewSize}</div>
                                    </div>
                                    <div
                                        className="group-colors cursor-pointer"
                                        onClick={() => {
                                            setShowColorSelection(true);
                                            setShowDeliveryMethodSelection(false);
                                        }}>
                                        <div className="color-circle">
                                            <div className="ellipse-122"></div>
                                        </div>
                                        <div
                                            className="image-44"
                                            style={{ backgroundColor: "#" + selectedNewColor.replace("#", "") }}></div>
                                    </div>
                                    <div
                                        className="group-quantity cursor-pointer"
                                        onClick={() => {
                                            setShowQuantitySelection(true);
                                            setShowDeliveryMethodSelection(false);
                                        }}>
                                        <div className="ellipse-123"></div>
                                        <div className="_1">{selectedNewQuantity}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="frame-313318">
                    <div className="line-26"></div>
                    {showSizeSelection ? (
                        <div className="frame-313319">
                            <div className="title-313319">Select size</div>
                        </div>
                    ) : showColorSelection ? (
                        <div className="frame-313319">
                            <div className="title-313319">Select color</div>
                        </div>
                    ) : showQuantitySelection ? (
                        <div className="frame-313319">
                            <div className="title-313319">Select quantity</div>
                        </div>
                    ) : (
                        <div className="frame-313319">
                            {deliveryMethod === fastestOption || botagAlgorithmnIsLoading ? (
                                deliveryPreference === "fastest" ? (
                                    <img className="solid" src={solid0} alt="vector" />
                                ) : (
                                    <img className="solid" src={ActiveCheapest} alt="vector" />
                                )
                            ) : (
                                ""
                            )}

                            <div
                                className={`fastest-option ${deliveryMethod !== fastestOption && !botagAlgorithmnIsLoading ? "text" : ""}`}>
                                {deliveryPreference === "fastest" ? "Fastest" : "Cheapest"}{" "}
                                {deliveryMethod === fastestOption || botagAlgorithmnIsLoading ? "option" : deliveryMethod}
                            </div>
                        </div>
                    )}
                    <div className="line-25"></div>
                </div>
            </div>
            <div className="frame-313501" style={{ display: showDeliveryMethodSelection ? "block" : "none" }}>
                <div className="frame-313502">
                    <div className="frame-313500">
                        <div className="frame-313499">
                            <div className="frame-313323">
                                <div className="frame-313321">
                                    <div className="delivery-slider-b-otag">
                                        <div className="rectangle-158"></div>
                                        <div
                                            className={`pick-up2 ${deliveryMethod === "pickup" && !isLoadingPage && "delivery-enabled"}`}
                                            onClick={() => onDeliveryMethodChanged("pickup")}>
                                            Pick up
                                        </div>
                                        <div
                                            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                            className={`courier ${deliveryMethod === "courier" && !isLoadingPage && "delivery-enabled"}`}
                                            onClick={() => {
                                                onDeliveryMethodChanged("courier");
                                                logGA4Event("Shipping_PopUp");
                                            }}>
                                            <img className="vector" src={vector0} alt="Wolt" />
                                        </div>
                                        <div
                                            className={`shipping ${deliveryMethod === "shipping" && !isLoadingPage && "delivery-enabled"}`}
                                            onClick={() => {
                                                onDeliveryMethodChanged("shipping");
                                                logGA4Event("Shipping_PopUp");
                                            }}>
                                            Shipping
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

import React, { useState } from "react";
import * as Library from "../../../utils/Library";
import * as Formatter from "../../../utils/Formatter";
import styled from "styled-components";
import { UserContext } from "../../../contexts/UserContext";
import loader from "../../../assets/images/loader.svg";
import BOTagBodySelectSize from "./BOTagComponents/BOTagBodySelectSize";
import BOTagBodySelectColor from "./BOTagComponents/BOTagBodySelectColor";
import BOTagBodySelectQuantity from "./BOTagComponents/BOTagBodySelectQuantity";
import LayoutContext from "../../../contexts/LayoutContext";
import BOTagSideOptions from "./BOTagComponents/BOTagSideOptions";
import BOTagBodyDeliveryAvailable from "./BOTagComponents/BOTagBodyDeliveryAvailable";
import BOTagBodyDeliveryUnavailable from "./BOTagComponents/BOTagBodyDeliveryUnavailable";
import StoreAwayComponent from "./BOTagComponents/StoreAwayComponent";
import BOTagHeader from "./BOTagComponents/BOTagHeader";
import AppContext from "../../../contexts/AppContext";
import logGA4Event from "../../../utils/firebase/analytics";

/* #region STYLED CSS */
const StyledPage = styled.div`
    .botag-container,
    .botag-container * {
        box-sizing: border-box;
    }
    .botag-container {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 10px;
        padding: 0px 0px 15px 0px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        width: 232px;
        height: 326px;
        position: relative;
        box-shadow: var(--botag-drop-shadow-box-shadow, 0px 0px 8px 0px rgba(16, 20, 27, 0.5));
    }
    .frame-313504 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313320 {
        padding: 15px 15px 0px 15px;
        display: flex;
        flex-direction: row;
        gap: 14px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313322 {
        display: flex;
        flex-direction: row;
        gap: 14px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .product-image {
        background: var(--Surface-surface-tertiary, #212121);
        border-radius: 5px;
        flex-shrink: 0;
        width: 56px;
        height: 74px;
        position: relative;
        object-fit: cover;
    }
    .product-image-loader {
        background: var(--Surface-surface-tertiary, #212121);
        border-radius: 5px;
        flex-shrink: 0;
        width: 56px;
        height: 74px;
        position: relative;
        object-fit: cover;
        display: flex;
        justify-content: center;
    }
    .frame-313314 {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        height: 76px;
        position: relative;
    }
    .frame-313317 {
        display: flex;
        flex-direction: column;
        gap: 7px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313316 {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .sams-e-sams-e-shirt {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-size: 10px;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        height: 14px;
        width: 134px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .luan-j-shirt-14826 {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-size: 14px;
        font-weight: 600;
        position: relative;
        width: 134px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .frame-313313 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .group-sizes {
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        position: static;
    }
    .ellipse-12 {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 50%;
        width: 28.12%;
        height: 100%;
        position: absolute;
        right: 71.88%;
        left: 0%;
        bottom: 0%;
        top: 0%;
    }
    .m {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        position: absolute;
        right: 74.22%;
        left: 2.34%;
        width: 23.44%;
        bottom: 25.6%;
        top: 23.02%;
        height: 51.39%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .group-colors {
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        position: relative;
    }
    .color-circle {
        width: 28.12%;
        height: 100%;
        position: absolute;
        right: 35.94%;
        left: 35.94%;
        bottom: 0%;
        top: 0%;
    }
    .ellipse-122 {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 50%;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
    }
    .image-44 {
        background: #d8d7e2;
        border-radius: 50px;
        width: 38px;
        height: 38px;
        position: absolute;
        top: -2px;
        left: -1px;
    }
    .group-quantity {
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        position: static;
    }
    .ellipse-123 {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 50%;
        width: 28.12%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 71.88%;
        bottom: 0%;
        top: 0%;
    }
    ._1 {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        position: absolute;
        right: 2.34%;
        left: 74.22%;
        width: 23.44%;
        bottom: 25.6%;
        top: 23.02%;
        height: 51.39%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-313318 {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .line-26 {
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0.001deg) scale(1, 1);
    }
    .frame-313319 {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .title-313319 {
        color: var(--Text-text-secondary, #8c8c8c);
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .solid {
        flex-shrink: 0;
        width: 11.01px;
        height: 10px;
        position: relative;
        overflow: visible;
    }
    .fastest-option {
        color: var(--text-text-info, #0060cc);
        text-align: center;
        font-size: 10px;
        font-weight: 400;
        position: relative;
    }
    .fastest-option.text {
        color: #8c8c8c;
    }
    .line-25 {
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0.001deg) scale(1, 1);
    }
    .frame-313501 {
        display: flex;
        flex-direction: column;
        gap: 13.5px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313502 {
        display: flex;
        flex-direction: column;
        gap: 13px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313500 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313499 {
        display: flex;
        flex-direction: column;
        gap: 6px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313323 {
        display: flex;
        flex-direction: column;
        gap: 7px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313321 {
        padding: 0px 15px 0px 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .delivery-slider-b-otag {
        align-self: stretch;
        flex-shrink: 0;
        height: 38px;
        position: relative;
    }
    .rectangle-158 {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
    }

    .pick-up2 {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        position: absolute;
        left: calc(50% - 93px);
        top: calc(50% - 14px);
        width: 62px;
        height: 27px;
        align-content: center;
        cursor: pointer;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
    }
    .courier {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        position: absolute;
        left: 34.5%;
        top: calc(50% - 14px);
        width: 62px;
        height: 27px;
        align-content: center;
        cursor: pointer;
    }
    .vector {
        height: 18px;
        object-fit: contain;
        overflow: visible;
    }
    .shipping {
        color: #ffffff;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        position: absolute;
        right: 5.94%;
        left: 64.8%;
        width: 62px;
        height: 27px;
        bottom: 28.95%;
        top: calc(50% - 14px);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .pick-up2:hover,
    .courier:hover,
    .shipping:hover {
        background: #272727;
        border-radius: 5px;
    }

    .delivery-enabled {
        background: var(--buttons-button-secondary-active, #373737) !important;
        border-radius: 5px;
        width: 62px;
        height: 27px;
        box-shadow: 0px 0px 4px 0px rgba(30, 38, 49, 0.35);
    }

    .open-234-m-from-you {
        text-align: center;
        font-size: 10px;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        height: 16px;
    }
    .open-234-m-from-you-span {
        color: var(--text-text-success, #69c23f);
        font-size: 10px;
        font-weight: 400;
    }

    .open {
        color: var(--text-text-success, #69c23f);
    }

    .closed {
        color: var(--text-text-error, #f00);
    }

    .open-234-m-from-you-span2 {
        color: var(--text-text-secondary, #8c8c8c);
        font-size: 10px;
        font-weight: 400;
    }
    .frame-313324 {
        padding: 0px 15px 0px 15px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        cursor: pointer;
    }
    .sams-e-sams-e-akersgata {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        position: relative;
        width: 204px;
        height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .frame-313327 {
        display: flex;
        flex-direction: column;
        gap: 6px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313325 {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .item-1-500-nok {
        color: var(--text-text-info, #0060cc);
        text-align: center;
        position: relative;
    }
    .item-1-500-nok-span {
        color: var(--text-text-secondary, #8c8c8c);
        font-size: 10px;
        font-weight: 400;
    }
    .item-1-500-nok-span2 {
        color: var(--text-text-secondary, #8c8c8c);
        font-size: 8px;
        font-weight: 400;
    }
    ._1-500-nok {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        position: relative;
        align-self: stretch;
    }
    ._1-500-nok-span {
        color: var(--text-text-primary, #ffffff);
        font-size: 18px;
        font-weight: 700;
    }
    ._1-500-nok-span2 {
        color: var(--text-text-primary, #ffffff);
        font-size: 14px;
        font-weight: 700;
    }
    .frame-3135012 {
        padding: 0px 15px 0px 15px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313503 {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        width: 97px;
    }
    .frame-313503.full-width-btn {
        width: 100%;
    }
    .btn-fw-botag {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 33px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
        cursor: pointer;
    }
    .frame-313497 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313349 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .item {
        transition: transform 3s ease, opacity 3s ease; /* Increase duration here */
        z-index: 9;
    }
    @keyframes zoomAndFlyToCart {
        0% {
            transform: scale(2) translate(0%, 0%);
            opacity: 1;
        }
        10% {
            transform: scale(2.5) translate(10%, 10%);
        }
        50% {
            transform: scale(2.5) translate(-50%, -50%);
        }
        100% {
            transform: scale(0.5) translate(calc(100vw - 60px), calc(-50vh - 50px)); /* Zoom out and move to cart */
            opacity: 0;
        }
    }
    .animate {
        animation: zoomAndFlyToCart 3s forwards; /* Adjust duration here */
    }
`;
/* #endregion */

/**
 * BOTag Popup
 *
 * @param {object} props - The props object containing the component's properties.
 * @returns {JSX.Element} The rendered PageTemplate component.
 */
export default function BOTag(props) {
    /* #region VARS */
    //Contexts
    const { setShowRightSidebar, setFireworks, fireworks } = React.useContext(LayoutContext);
    const { userAddress, userGPSCoordinates, pickupDistance } = React.useContext(UserContext);
    const { selectedBotag, botagAlgorithmnIsLoading, setBotagAlgorithmnIsLoading, setSelectedBotagStoreDetails, setSelectedBotag } =
        React.useContext(AppContext);

    //States
    const [deliveryPreference, setDeliveryPreference] = React.useState("fastest"); //fastest, cheapest
    const [deliveryMethod, setDeliveryMethod] = React.useState(""); //pickup, courier, shipping

    //TODO: THESE SELECTED VALUES SHOULD BE SET FROM THE SELECTED BOTAG
    const [selectedQuantity, setSelectedQuantity] = React.useState(1);
    const [selectedNewQuantity, setSelectedNewQuantity] = React.useState(1);
    const [selectedColor, setSelectedColor] = React.useState(selectedBotag?.selectedColor);
    const [selectedNewColor, setSelectedNewColor] = React.useState("#" + selectedBotag?.selectedColor);
    const [selectedSize, setSelectedSize] = React.useState(selectedBotag?.selectedSize);
    const [selectedNewSize, setSelectedNewSize] = React.useState(selectedBotag?.selectedSize);
    const [selectedGTINbarcode, setSelectedGTINbarcode] = React.useState(selectedBotag?.GTINbarcode);
    const [selectedImage, setSelectedImage] = React.useState([]);
    const [pickupInfo, setPickupInfo] = React.useState({ available: false, data: undefined });
    const [courierInfo, setCourierInfo] = React.useState({ available: false, data: undefined });
    const [shippingInfo, setShippingInfo] = React.useState({ available: false, data: undefined });
    const [fastestOption, setFastestOption] = React.useState("");
    const [showLightBoxImages, setShowLightBoxImages] = React.useState(false);
    const [showDeliveryMethodSelection, setShowDeliveryMethodSelection] = React.useState(true);
    const [showSizeSelection, setShowSizeSelection] = React.useState(false);
    const [showColorSelection, setShowColorSelection] = React.useState(false);
    const [showQuantitySelection, setShowQuantitySelection] = React.useState(false);
    const [productImageLoader, setProductImageLoader] = useState(false);
    const [gtinNotFound, setGTINNotFound] = React.useState(false);
    const [storeTooAway, setStoreTooAway] = useState(false);
    const [animate, setAnimate] = useState(false);
    /* #endregion */
    /* #region EFFECTS */
    React.useEffect(() => {
        if (!selectedBotag || !deliveryPreference || !selectedGTINbarcode) return;
        getBOtagAlgorithmData();
    }, [selectedBotag, deliveryPreference, selectedGTINbarcode, selectedQuantity, userAddress]);

    React.useEffect(() => {
        const BOTagData = selectedBotag;

        const stockData = BOTagData?.stockData?.find(
            data => Formatter.formatColorHex(data.colorCode) === Formatter.formatColorHex(selectedColor) && data.size === selectedSize
        );
        const gtinBarcode = stockData?.GTINbarcode;

        if (gtinBarcode) {
            setSelectedGTINbarcode(gtinBarcode);
            setGTINNotFound(false);
        } else {
            console.log("GTIN not found");
            setGTINNotFound(true);
        }
    }, [selectedBotag, selectedColor, selectedSize]);

    React.useEffect(() => {
        if (showColorSelection) {
            setShowQuantitySelection(false);
            setShowSizeSelection(false);
            setShowLightBoxImages(false);
            setShowDeliveryMethodSelection(false);
        }
    }, [showColorSelection]);

    React.useEffect(() => {
        if (showSizeSelection) {
            setShowQuantitySelection(false);
            setShowColorSelection(false);
            setShowLightBoxImages(false);
            setShowDeliveryMethodSelection(false);
        }
    }, [showSizeSelection]);

    React.useEffect(() => {
        if (showQuantitySelection) {
            setShowSizeSelection(false);
            setShowColorSelection(false);
            setShowLightBoxImages(false);
            setShowDeliveryMethodSelection(false);
        }
    }, [showQuantitySelection]);

    React.useEffect(() => {
        if (deliveryMethod === "pickup") {
            if (!pickupInfo.available) setSelectedBotagStoreDetails(null);
            else setSelectedBotagStoreDetails(pickupInfo.data?.storeDetail);
        } else if (deliveryMethod === "courier") {
            if (!courierInfo.available) setSelectedBotagStoreDetails(null);
            else setSelectedBotagStoreDetails(courierInfo.data?.storeDetail);
        } else if (deliveryMethod === "shipping") {
            if (!shippingInfo.available) setSelectedBotagStoreDetails(null);
            else setSelectedBotagStoreDetails(shippingInfo.data?.storeDetail);
        }
    }, [pickupInfo, courierInfo, shippingInfo, deliveryMethod]);
    /* #endregion */

    /* #region METHODS */
    const getBOtagAlgorithmData = async () => {
        logGA4Event("BOtag_Loading");
        const formData = {
            GTINbarcode: selectedGTINbarcode,
            labelBrandObjID: selectedBotag?.labelBrandID,
            quantity: selectedQuantity,
            deliveryPreference: deliveryPreference,

            locationLatitudeForPickUp: userGPSCoordinates?.latitude,
            locationLongitudeForPickUp: userGPSCoordinates?.longitude,

            locationLatitudeForCourier: userGPSCoordinates?.latitude,
            locationLongitudeForCourier: userGPSCoordinates?.longitude,

            addressNameForShipping: userAddress?.street,
            addressNumberForShipping: userAddress?.streetNumber,
            postCodeForShipping: userAddress?.postCode,
            countryForShipping: userAddress?.country
            //cityForShipping: userAddress?.city
        };

        const res = await Library.makePostRequest("getBOtagAlgorithmData", formData, false, setBotagAlgorithmnIsLoading);
        try {
            if (res.data.status === 200) {
                const algorithmData = res.data.data;

                logGA4Event("BOtag_Shown");
                if (algorithmData.length) {
                    const pickupData = algorithmData?.find(data => data?.type === "pickup");
                    const courierData = algorithmData?.find(data => data?.type === "courier");
                    const shippingData = algorithmData?.find(data => data?.type === "shipping");
                    const findFastestOption = algorithmData?.find(option => option.fastestOption === true);
                    setDeliveryMethod(findFastestOption?.type);
                    setFastestOption(findFastestOption?.type);
                    setPickupInfo({ available: !!pickupData, data: pickupData });
                    setCourierInfo({ available: !!courierData, data: courierData });
                    setShippingInfo({ available: !!shippingData, data: shippingData });
                    setStoreTooAway(pickupDistance < pickupData?.distanceToStore);
                } else {
                    setPickupInfo({ vailable: false, data: undefined });
                    setCourierInfo({ vailable: false, data: undefined });
                    setShippingInfo({ vailable: false, data: undefined });
                    setFastestOption("pickup");
                    setDeliveryMethod("pickup");
                }
            } else throw new Error(res.data.message);
        } catch (error) {
            console.log("Error: ", res.data.message);
            //Library.showErrorMessage(`Error: ${res.data.message}`);
        }
    };
    /* #endregion */

    return (
        <StyledPage onClick={e => e.stopPropagation()}>
            <div className="botag-container">
                <BOTagHeader
                    isLoadingPage={botagAlgorithmnIsLoading}
                    deliveryMethod={deliveryMethod}
                    botagData={selectedBotag}
                    selectedQuantity={selectedQuantity}
                    selectedSize={selectedSize}
                    selectedColor={selectedColor}
                    onDeliveryMethodChanged={method => setDeliveryMethod(method)}
                    deliveryPreference={deliveryPreference}
                    showSizeSelection={showSizeSelection}
                    showQuantitySelection={showQuantitySelection}
                    showColorSelection={showColorSelection}
                    setShowSizeSelection={setShowSizeSelection}
                    setShowColorSelection={setShowColorSelection}
                    setShowQuantitySelection={setShowQuantitySelection}
                    showDeliveryMethodSelection={showDeliveryMethodSelection}
                    setShowDeliveryMethodSelection={setShowDeliveryMethodSelection}
                    showLightBoxImages={showLightBoxImages}
                    setShowLightBoxImages={setShowLightBoxImages}
                    setShowRightSidebar={setShowRightSidebar}
                    selectedNewSize={selectedNewSize}
                    selectedNewColor={selectedNewColor}
                    selectedNewQuantity={selectedNewQuantity}
                    selectedImage={selectedImage}
                    productImageLoader={productImageLoader}
                    fastestOption={fastestOption}
                    botagAlgorithmnIsLoading={botagAlgorithmnIsLoading}
                    animate={animate}
                />

                <BOTagSideOptions deliveryPreference={deliveryPreference} setDeliveryPreference={setDeliveryPreference} />

                {botagAlgorithmnIsLoading ? (
                    <BOTagLoading />
                ) : showSizeSelection ? (
                    <BOTagBodySelectSize
                        sizes={selectedBotag?.sizes || []}
                        selectedSize={selectedSize}
                        setSelectedSize={setSelectedSize}
                        setShowSizeSelection={setShowSizeSelection}
                        setShowDeliveryMethodSelection={setShowDeliveryMethodSelection}
                        stockData={selectedBotag?.stockData}
                        selectedNewSize={selectedNewSize}
                        setSelectedNewSize={setSelectedNewSize}
                        setSelectedColor={setSelectedColor}
                        selectedNewColor={selectedNewColor}
                        setSelectedQuantity={setSelectedQuantity}
                        selectedNewQuantity={selectedNewQuantity}
                        botagData={selectedBotag}
                    />
                ) : showColorSelection ? (
                    <BOTagBodySelectColor
                        colors={selectedBotag?.colors || []}
                        selectedColor={selectedColor}
                        setSelectedColor={setSelectedColor}
                        setShowColorSelection={setShowColorSelection}
                        setShowQuantitySelection={setShowQuantitySelection}
                        setShowDeliveryMethodSelection={setShowDeliveryMethodSelection}
                        stockData={selectedBotag?.stockData}
                        selectedNewColor={selectedNewColor}
                        setSelectedNewColor={setSelectedNewColor}
                        botagData={selectedBotag}
                        setSelectedImage={setSelectedImage}
                        setProductImageLoader={setProductImageLoader}
                        setSelectedBotag={setSelectedBotag}
                        setSelectedSize={setSelectedSize}
                        selectedNewSize={selectedNewSize}
                        setSelectedQuantity={setSelectedQuantity}
                        selectedNewQuantity={selectedNewQuantity}
                    />
                ) : showQuantitySelection ? (
                    <BOTagBodySelectQuantity
                        selectedQuantity={selectedQuantity}
                        setSelectedQuantity={setSelectedQuantity}
                        setShowQuantitySelection={setShowQuantitySelection}
                        setShowDeliveryMethodSelection={setShowDeliveryMethodSelection}
                        stockData={selectedBotag?.stockData}
                        selectedNewQuantity={selectedNewQuantity}
                        setSelectedNewQuantity={setSelectedNewQuantity}
                        setSelectedColor={setSelectedColor}
                        selectedNewColor={selectedNewColor}
                        setSelectedSize={setSelectedSize}
                        selectedNewSize={selectedNewSize}
                        botagData={selectedBotag}
                    />
                ) : (
                    <BOTagBodyAddToCheckout
                        pickupInfo={pickupInfo}
                        courierInfo={courierInfo}
                        shippingInfo={shippingInfo}
                        deliveryMethod={deliveryMethod}
                        botagData={selectedBotag}
                        GTINbarcode={selectedGTINbarcode}
                        selectedQuantity={selectedQuantity}
                        selectedColor={selectedColor}
                        selectedSize={selectedSize}
                        deliveryPreference={deliveryPreference}
                        gtinNotFound={gtinNotFound}
                        storeTooAway={storeTooAway}
                        setStoreTooAway={setStoreTooAway}
                        pickupDistance={pickupDistance}
                        animate={animate}
                        setAnimate={setAnimate}
                        fireworks={fireworks}
                        setFireworks={setFireworks}
                    />
                )}
            </div>
        </StyledPage>
    );
}

/* #region SMALL COMPONENTS */
const BOTagLoading = () => {
    return (
        <img
            className="BeaconsContainerElement loading"
            style={{
                position: "absolute",
                top: "70%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "40px",
                height: "40px"
            }}
            src={loader}
            alt="Loading"
        />
    );
};

const BOTagBodyAddToCheckout = ({
    pickupInfo,
    courierInfo,
    shippingInfo,
    deliveryMethod,
    botagData,
    GTINbarcode,
    selectedQuantity,
    selectedColor,
    selectedSize,
    deliveryPreference,
    gtinNotFound,
    storeTooAway,
    setStoreTooAway,
    pickupDistance,
    animate,
    setAnimate,
    fireworks,
    setFireworks
}) => {
    let showUnavailable = false;
    let unavailableReason = "";
    let deliveryData;

    const isPickupAvailableOnAlgorithmn = pickupInfo?.available;
    const isCourierAvailableOnAlgorithmn = courierInfo?.available;
    const isShippingAvailableOnAlgorithmn = shippingInfo?.available;

    const GTINHasStock = botagData?.stockData?.find(data => data.GTINbarcode === GTINbarcode)?.totalStock > 0;
    const allGTINSDontHaveStock = botagData?.stockData?.every(data => data.totalStock <= 0);

    if (gtinNotFound) {
        showUnavailable = true;
        unavailableReason = "Oops! This product does not exist in any store.";
    } else if (deliveryMethod === "pickup") {
        deliveryData = pickupInfo?.data;
        if (!isPickupAvailableOnAlgorithmn || !GTINHasStock || allGTINSDontHaveStock) {
            showUnavailable = true;
            unavailableReason = "Oops! No store available for pick up for the selected color and size combination.";
        }
    } else if (deliveryMethod === "courier") {
        deliveryData = courierInfo?.data;
        if (allGTINSDontHaveStock) {
            showUnavailable = true;
            unavailableReason = "Oops! This product does not exist in any store that provide courier service.";
        } else if (!GTINHasStock) {
            showUnavailable = true;
            unavailableReason = "Oops! This color and size combination does not exist in any store that provide courier service.";
        } else if (!isCourierAvailableOnAlgorithmn) {
            showUnavailable = true;
            unavailableReason = "Oops! No courier service available to your shared location.";
        }
    } else if (deliveryMethod === "shipping") {
        deliveryData = shippingInfo?.data;

        if (allGTINSDontHaveStock) {
            showUnavailable = true;
            unavailableReason = "Oops! This product does not exist in any store that provide shipping service.";
        } else if (!GTINHasStock) {
            showUnavailable = true;
            unavailableReason = "Oops! This color and size combination does not exist in any store that provide shipping service.";
        } else if (!isShippingAvailableOnAlgorithmn) {
            showUnavailable = true;
            unavailableReason = "Oops! No shipping service available to your shared location.";
        }
    }
    return (
        <div className="frame-313501">
            {showUnavailable ? (
                <BOTagBodyDeliveryUnavailable unavailableReason={unavailableReason} />
            ) : storeTooAway && deliveryMethod === "pickup" ? (
                <StoreAwayComponent pickupDistance={pickupDistance} setStoreTooAway={setStoreTooAway} />
            ) : (
                <BOTagBodyDeliveryAvailable
                    deliveryData={deliveryData}
                    deliveryMethod={deliveryMethod}
                    GTINbarcode={GTINbarcode}
                    selectedQuantity={selectedQuantity}
                    selectedColor={selectedColor}
                    selectedSize={selectedSize}
                    deliveryPreference={deliveryPreference}
                    animate={animate}
                    setAnimate={setAnimate}
                    fireworks={fireworks}
                    setFireworks={setFireworks}
                />
            )}
        </div>
    );
};
/* #endregion */

import React from "react";
import StockExceedInfo from "../../../../assets/images/stockexceedinfo.svg";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

export default function BOTagBodyDeliveryUnavailable({ unavailableReason }) {
    return (
        <div
            style={{
                // borderTop: "0.5px solid rgb(56, 56, 56)",
                margin: "0px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%"
            }}>
            <div style={{ display: "flex", flexDirection: "column", marginTop: "18px", textAlign: "center", width: "182px" }}>
                <div className="stockexceedIcon" style={{ marginBottom: "4px" }}>
                    <img src={StockExceedInfo} alt="" />
                </div>
                <div className="checkText" style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ fontWeight: "600", lineHeight: "16px", fontSize: "12px" }}>{unavailableReason}</span>
                </div>
            </div>
        </div>
    );
}

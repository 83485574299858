import React from "react";
import { DefaultPopupCard } from "./DefaultPopupCard";
import styled from "styled-components";
import LayoutContext from "../../../contexts/LayoutContext";
import closeBtn from "../../../assets/images/close-btn.svg";

const StyledPage = styled.div`
    &.policies-container {
        height: 60vh;
        padding-right: 10px;
        overflow-y: auto;
    }
    &.policies-container div span {
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        height: 90%;
        line-height: 20px;
        overflow-y: auto;
        padding-right: 10px;
    }
    &.policies-container::-webkit-scrollbar-thumb {
        background-color: rgba(33, 33, 33, 1);
        border-radius: 100px;
    }

    .headings {
        font-weight: 800;
        color: #fff;
    }
    .unOrderList {
        padding-left: 0px;
    }
    .subHeading {
        font-weight: 700;
    }
    .refreshButton {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 5px;
        height: 30px;
        padding: 0 10px;
        font-size: 12px;
        font-weight: 400;
        background-color: #212121;
        cursor: pointer;
    }
`;

export default function ShowPolicyDialog() {
    const { showPolicyPopup, setShowPolicyPopup, isMobile } = React.useContext(LayoutContext);

    const onClose = () => {
        setShowPolicyPopup(false);
    };

    return (
        <DefaultPopupCard visible={true}>
            <PolicyTitleView showPolicyPopup={showPolicyPopup} onClose={onClose} />

            <StyledPage className="policies-container" style={{ height: isMobile && "90vh" }}>
                {showPolicyPopup === "terms" && <TermsAndConditionsText />}
                {showPolicyPopup === "privacy" && <PrivacyPolicyText />}
                {showPolicyPopup === "returns" && <ReturnsText />}
            </StyledPage>
        </DefaultPopupCard>
    );
}

/* #region SMALL COMPONENTS */

const PolicyTitleView = ({ showPolicyPopup, onClose }) => {
    const PolicyTitle = showPolicyPopup => {
        switch (showPolicyPopup) {
            case "terms":
                return "Terms & Conditions";
            case "privacy":
                return "Privacy Policy";
            case "returns":
                return "Returns ";
            default:
                return `Unknown Policy Type: ${showPolicyPopup}`;
        }
    };

    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingBottom: "20px" }}>
            <div style={{ fontSize: "22px", fontWeight: "600", color: "rgb(255, 255, 255)" }}>{PolicyTitle(showPolicyPopup)}</div>
            <img src={closeBtn} width="30px" height="30px" alt="" style={{ cursor: "pointer" }} onClick={onClose} />
        </div>
    );
};

const TermsAndConditionsText = () => {
    return (
        <div>
            <span>1. Introduction</span>
            <br /> Our technology (BOtag technology), is owned by BOtag Technology BV, registered in Amsterdam, Netherlands, under the
            organization number 64500187, headquartered at Kingsfordweg 151, 1043 GR Amsterdam, Netherlands, and operated in Norway by its
            subsidiary BOtag Norge AS, registered under organization number 932017814 with our principal place of business at Tordenskiolds
            Gate 2, 0160 Oslo, Norway.
            <br />
            <br /> By using our platforms and technology, you accept these Terms and Conditions in full; accordingly.If you disagree with
            these terms or any part of these terms, you must not use our platform.
            <br />
            <br /> <span>2. User Accounts</span>
            <br /> When you create an account with BOtag, you must provide us with accurate and complete information. Your account must be
            used only for personal purposes and managed via our platform hosted on Microsoft Azure servers in Norway. You are responsible
            for safeguarding the password and also for all activities that occur under your account.
            <br />
            <br /> <span>3. Products</span>
            <br /> BOtag offers a range of products including fashion apparel, footwear, accessories, homeware, decoration, and furniture.
            These products are available for purchase under the terms specified for each item.
            <br />
            <br /> <span>4. Payments</span>
            <br />
            Payments can be made using credit cards and are handled by STRIPE. Details of the payment process and terms will be provided at
            the time of purchase.
            <br />
            <br /> <span>5. Delivery</span>
            <br /> We offer various delivery options: Pickup in store Courier delivery via WOLT Shipping via POSTNORD Details and costs of
            these delivery options will be disclosed at checkout based on your selected preferences.
            <br /> <br />
            <span> 6. Returns and Refunds</span>
            <br />
            You have 14 days from receipt of your item to initiate a return via our online return form. The product must be returned in the
            same condition it was received, with all original tags and packaging intact. You may return items to the store free of charge by
            delivering them in person. However, if shipping items to the store, the customer will be responsible for any associated shipping
            costs.
            <br />
            <br /> <span>7. Privacy and Data</span>
            <br /> Use BOtag respects your privacy. Our Privacy Policy, available on our website, outlines how we handle your personal data
            and the conditions under which it may be shared with our partners.
            <br />
            <br /> <span>8. Cookies</span>
            <br /> We use cookies to improve user experience and analyze website traffic as detailed in our Cookie Policy on the BOtag
            website.
            <br />
            <br /> <span>9. Promotions</span>
            <br /> Promotional offers may be available from time to time and are subject to terms and conditions that will be specified with
            each offer.
            <br />
            <br /> <span>10. Governing Law and Disputes</span>
            <br /> These Terms and Conditions shall be governed and construed in accordance with the laws of Norway. Any disputes relating
            to these terms and conditions shall be subject to the exclusive jurisdiction of the courts of Norway.
        </div>
    );
};

const ReturnsText = () => {
    return (
        <div>
            <span className="headings">How to Return an Item on BOtag </span>
            <br />
            <br />
            <span>
                You have 14 days from receipt of your item to initiate a return via our online return form. The product must be returned in
                the same condition it was received, with all original tags and packaging intact. <br />
            </span>
            <br />
            <ol style={{ paddingLeft: "16px" }}>
                <li>
                    <div>Open the Return Form</div>
                    <ul style={{ paddingLeft: "0px" }}>
                        <li className="headings"> With a BOaccount:</li>
                        <li className="unOrderList">Log in to your account.</li>
                        <li className="unOrderList">Click on the profile icon in the header.</li>
                        <li className="unOrderList">Select "Orders" from the menu.</li>
                        <li className="unOrderList">Find the order with the item you want to return.</li>
                        <li className="unOrderList">Click on the 3 dots next to the item and open the return form.</li>
                    </ul>
                </li>
                <br />
                <ul style={{ paddingLeft: "0px" }}>
                    <li className="headings">Without a BOaccount:</li>
                    <li className="unOrderList">Find the email you received when you placed your order.</li>
                    <li className="unOrderList">Click on the "See full order details" button at the bottom of the email.</li>
                    <li className="unOrderList">Click on the 3 dots next to the item and open the return form. </li>
                </ul>
                <br />
                <li>
                    <div className="headings"> Deliver the Item to the Store</div>
                    <ul style={{ paddingLeft: "0px" }}>
                        <li className="unOrderList">
                            <span className="subHeading">In-Person Return: </span>

                            <span style={{ fontWeight: "600", color: "rgba(209, 209, 209, 1" }}>
                                You can return the item to the store for free by delivering it in person. Show the order ID/code from your
                                BOtag order when you arrive.
                            </span>
                        </li>
                    </ul>
                    <ul style={{ paddingLeft: "0px" }}>
                        <li className="unOrderList">
                            <span className="subHeading">Shipping Return: </span>

                            <span style={{ fontWeight: "600", color: "rgba(209, 209, 209, 1" }}>
                                If you decide to ship the item, write "BOtag order #(INSERT CODE)" on the package label. Note: You are
                                responsible for any shipping costs.
                            </span>
                        </li>
                    </ul>
                </li>
                <br />
                <li className="headings">Store Assessment and Refund</li>
                <ul style={{ paddingLeft: "0px" }}>
                    <li>
                        <li className="unOrderList">
                            The store will assess the condition of the item and notify us of its eligibility for a return.
                        </li>
                        <li className="unOrderList">
                            {" "}
                            If the item qualifies, we'll start the refund process, which may take up to three business days.
                        </li>
                    </li>
                </ul>
            </ol>
            <div className="headings">
                Any questions? Reach out at <span>support@botag.no</span>
            </div>
        </div>
    );
};

const PrivacyPolicyText = () => {
    const style = {
        marginBottom: "20px"
    };
    return (
        <div>
            <div style={style}>
                Welcome to BOtag Technology BV. Your privacy and the security of your personal data are of paramount importance to us.
            </div>
            <div style={style}>
                This Privacy Policy identifies the data controller for the processing activities described below, outlines how we collect,
                use, protect, and share information about our users (“you”) and your rights regarding your personal information.
            </div>
            <div style={style}>
                This Privacy Policy governs how we manage personal information gathered when you engage in transactions with us or navigate
                our website. It outlines BOtag Technology BV’s practices for processing your personal data and furnishes you with the
                details you have the right to know under relevant data protection legislation.
            </div>
            <div style={style}>
                <div className="headings">1. Who handles your data</div>

                <div>
                    Unless specified differently, BOtag Technology BV (hereinafter referred as BOtag) acts as the data controller for the
                    processing activities outlined below.
                    <br /> BOtag Technology BV Kingsfordweg 151, 1043 GR Amsterdam
                    <br /> The Netherlands
                    <br /> VAT: NL855693009B01
                    <br /> REG: 64500187
                    <br />
                    <span>info@botagtechnology.com</span>
                </div>
            </div>
            <div style={style}>
                <div className="headings">2. When we collect your data</div>

                <div>
                    BOtag may collect your data when you visit any of the following websites owned by BOtag: botagtechnology.com and
                    bostages.com and when you make purchases through botag.no, botag plugin or botag web or mobile applications.
                    <br /> Botag may also collect your data when you communicate with us through email, sign to our newsletters or use any
                    services or featured offered by Botag.
                </div>
            </div>
            <div style={style}>
                <div className="headings">3. What data we may collect</div>

                <div>
                    Botag Technology BV may collect, process and store your personal data such us:
                    <br />
                    <ul className="unOrderList">
                        Personal Identification Information: Name, email address, phone number, address and other information you provide
                        when creating an account.
                    </ul>
                    <ul className="unOrderList">
                        Transactional Data: Details about purchases, preferences and interests (namely in products or services), and
                        interactions with our platform.
                    </ul>
                    <ul className="unOrderList">
                        {" "}
                        Technical and Usage Data: IP address, browser type, operating system, page views, and navigation paths to understand
                        how our platform is used.
                    </ul>
                </div>
            </div>
            <div style={style}>
                <div className="headings"> 4. Data we do not store</div>

                <div>
                    {" "}
                    BOtag does not store any payment method information on our systems. All payment processing is conducted through our
                    trusted payment processors, who are responsible for securely storing and handling your payment details.
                </div>
            </div>
            <div style={style}>
                <div className="headings"> 5. How we use your information</div>

                <div>In order to provide and improve our services and products, your personal data may be processed on: </div>
                <div>
                    <li className="unOrderList">
                        Orders handling: Name, email address, phone number, address and other information you provide when creating an
                        account.
                    </li>
                    <li className="unOrderList">
                        Customer relationship management: User account management; marketing communications such as sms, mms, newsletters
                        and advertising in general; customer service; marketing events such us contests and public events; surveys;
                        fulfilment of agreements entered between you and BOtag Technology BV and handling of requests from you.
                    </li>
                    <li className="unOrderList">
                        Legal compliance and security measures: compliance with applicable laws and regulations; establishment, defense, and
                        exercise of legal claims; prevention, detection, investigation, and combat of security breaches, fraud, and other
                        potentially illegal activities.
                    </li>
                    <li className="unOrderList">Research & Development: Development of our products and services.</li>
                </div>
            </div>
            <div style={style}>
                <div className="headings">6. Legal framework</div>

                <div>
                    BOtag handles and processes your personal data according to the GDPR legal framework
                    <div>
                        <li className="unOrderList">Article 6.1.a: Only if you have given prior consent in our platforms..</li>
                        <li className="unOrderList">
                            Article 6.1.b: compliance with applicable laws and regulations; establishment, defense, and exercise of legal
                            claims; prevention, detection, investigation, and combat of security breaches, fraud, and other potentially
                            illegal activities.
                        </li>
                        <li className="unOrderList">Article 6.1.c: To comply with accounting obligations and others.</li>
                        <li className="unOrderList">Article 6.1.f: To conduct marketing activities and/or analytics.. </li>
                        <li className="unOrderList">
                            Article 6.1.f and 9.2.f: To prevent fraud or to establish, prosecute or defend legal claims..
                        </li>
                    </div>
                </div>
            </div>
            <div style={style}>
                <div className="headings">7. Sharing your information</div>

                <div> To achieve the aforementioned objectives, we may grant access to your personal data:</div>
                <div>
                    <li className="unOrderList">
                        To data processors who, under an agreement with BOtag, assist in our operations, offering pertinent services,
                        including: providers of IT services such as POS systems installed in physical stores; providers of delivery
                        services;
                    </li>
                    <li className="unOrderList">
                        To data processors providers of stock such as physical stores or warehouses, or to providers of product information
                        such as brands, with the exclusive purpose of: providing you with accurate information about real-time stock and
                        prices; providing you with authentic product information; and handling and fulfilling orders placed by you through
                        BOtag. Both brands and stores operate as independent data controllers of personal data, processing the information
                        for their own purposes.
                    </li>
                    <li className="unOrderList">
                        To other data processors, under an agreement with BOtag, offer other pertinent services including but not limited to
                        CRM systems providers, billing and payment systems providers, marketing services providers, IT services & support
                        providers, website analytics and hosting services.
                    </li>
                    <li className="unOrderList">When required by law or to protect the rights and safety of our users and the public.</li>
                    <li className="unOrderList">
                        With your consent, or as part of a business transfer, merger, or sale of assets.revent fraud or to establish,
                        prosecute or defend legal claims..{" "}
                    </li>
                </div>
            </div>
            <div style={style}>
                <div className="headings">8. Data security</div>

                <div>
                    We implement robust security measures to protect your information from unauthorized access, alteration, disclosure, or
                    destruction. Our platform is hosted on Microsoft Azure infrastructure, which adheres to high standards of security and
                    data protection compliance. This ensures that your personal data is managed in a secure environment with
                    state-of-the-art technological safeguards.{" "}
                </div>
            </div>
            <div style={style}>
                <div className="headings">9. Storage period</div>

                <div>
                    <li className="unOrderList">
                        Personal data related to your purchases is initially stored for three years following your most recent transaction.
                    </li>
                    <li className="unOrderList">
                        Data incorporated into financial records is retained for five years beyond the conclusion of the corresponding
                        fiscal year before it is removed.
                    </li>
                    <li className="unOrderList">
                        {" "}
                        Records of your consent to marketing are maintained for one years after you withdraw your agreement to receive
                        direct marketing communications.
                    </li>
                    <div>
                        {" "}
                        Circumstances may necessitate extending the retention period of personal data, such as to comply with legal
                        obligations or to establish, pursue, or defend against legal claims.
                    </div>
                    <div>Additionally, data may be processed and preserved in an anonymized format for an extended period.</div>
                </div>
            </div>
            <div style={style}>
                <div className="headings">10. Your Rights</div>

                <div>
                    You have the right to access, correct, delete, or limit the use of your personal information. Please contact us if you
                    wish to exercise these rights:<div>info@botagtechnology.com </div>{" "}
                </div>
            </div>
            <div style={style}>
                <div className="headings">11. Children’s Privacy</div>

                <div>
                    BOtag is not intended for individuals under the age of 13. We do not knowingly collect personal information from
                    children without parental consent.
                </div>
            </div>
            <div style={style}>
                <div className="headings">12. Changes to This Policy</div>

                <div>
                    {" "}
                    We may update this Privacy Policy from time to time. We will notify you of any significant changes and update the
                    effective date at the top of this policy.{" "}
                </div>
            </div>
            <div>
                <div className="headings">13. Contact Us</div>

                <div>
                    If you have any questions about this Privacy Policy, please contact us at:
                    <br /> BOtag Technology BV <br /> Kingsfordweg 151, 1043 GR Amsterdam The Netherlands
                    <br />
                    VAT: NL855693009B01
                    <br /> REG: 64500187<div> info@botagtechnology.com</div>
                </div>
            </div>
        </div>
    );
};

/* #endregion */

import React, { useEffect, useRef } from "react";
import { DefaultPopupCard } from "./DefaultPopupCard";
import { ReactComponent as Frame290570 } from "../../../assets/images/close-btn.svg";
import { ReactComponent as MarkerPin020 } from "../../../assets/images/marker-pin-009.svg";
import styled from "styled-components";
import { StandaloneSearchBox } from "@react-google-maps/api";
import logGA4Event from "../../../utils/firebase/analytics";
import { getUserLocationByIP } from "../../../utils/LocationLib";
import UserContext from "../../../contexts/UserContext";
import StandardsContext from "../../../contexts/StandardsContext";
import * as Library from "../../../utils/Library";
import LocationInfoImg from "../../../assets/images/LocationInfo.png";
import Spinner from "../../../assets/images/spinner.svg";
import { getCoordinates, getLocation } from "../../../utils/helperFunctions";
import LayoutContext from "../../../contexts/LayoutContext";

const StyledPage = styled.div`
    &.share-location,
    &.share-location * {
        box-sizing: border-box;
        width: 100%;
    }
    &.share-location {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        padding: 8px;
    }
    .frame-29643 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313553 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313474 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .share-your-location-to-start {
        color: #ffffff;
        text-align: left;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 22px;
        line-height: 28px;
        font-weight: 600;
        position: relative;
        flex: 1;
    }
    .frame-29057 {
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 30px;
        height: 30px;
        position: relative;
        overflow: visible;
    }
    .frame-313700 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .search-location {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 48px;
        position: relative;
        width: 100%;
    }
    .frame-29592 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        width: 100%;
    }
    .buttons-fixed-width-12 {
        border-radius: 5px;
        border-style: solid;
        border-width: 1px;
        padding: 8px 8px 8px 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: 48px;
        position: relative;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    }
    .frame-29480pp {
        display: flex;
        flex-direction: row;
        gap: 8px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .marker-pin-02 {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: relative;
        overflow: visible;
    }
    .insert-location-dd {
        color: #8c8c8c;
        text-align: left;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        border: none;
        background: none;
        outline: none;
        width: 100%;
        padding-left: 0px;
        padding-right: 5px;
    }
    .or {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .btn-lg-solid {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 180px;
        height: 48px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
    }
    .frame-29590 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-medium-regular-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-medium-regular-font-size, 14px);
        line-height: var(--button-text-medium-regular-line-height, 24px);
        font-weight: var(--button-text-medium-regular-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-29642 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .disclaimer {
        color: #8c8c8c;
        text-align: center;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .spinner-text {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .spinner-img {
        animation: spin 1s linear infinite;
        height: 24px;
        width: 24px;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
    @media only screen and (max-width: 992px) {
        .share-your-location-to-start {
            font-size: 20px;
        }
    }
    .frame-29057.cursor-pointer.disable {
        opacity: 0.2;
    }
    .search-location.disable {
        opacity: 0.2;
    }
`;

const ConfirmationCardStyles = styled.div`
    .confirmation-card,
    .confirmation-card * {
        box-sizing: border-box;
    }
    .confirmation-card {
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-end;
        justify-content: center;
        position: relative;
    }
    .frame-29643 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-end;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313411 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-end;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313402 {
        display: flex;
        flex-direction: row;
        gap: 32px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313406 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-3134022 {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313401 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3590 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .group-3413 {
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        position: static;
    }
    .ellipse-80 {
        background: var(--wrap-wrap-orange, rgba(255, 153, 0, 0.1));
        border-radius: 50%;
        width: 50px;
        height: 50px;
        position: absolute;
        left: 177px;
        top: 0px;
    }
    .info-circle {
        border-radius: 88px;
        width: 5.94%;
        height: 48%;
        position: absolute;
        right: 47.03%;
        left: 47.03%;
        bottom: 26%;
        top: 26%;
        overflow: visible;
    }
    .sorry {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 22px;
        line-height: 24px;
        font-weight: 600;
        position: relative;
        align-self: stretch;
    }
    .no-other-stores-have-this-item-in-stock-still-want-to-add-the-5-items-from-h-yer-paleet-to-your-checkout {
        text-align: center;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .no-other-stores-have-this-item-in-stock-still-want-to-add-the-5-items-from-h-yer-paleet-to-your-checkout-span {
        color: var(--text-text-secondary, #8c8c8c);
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
    }
    .no-other-stores-have-this-item-in-stock-still-want-to-add-the-5-items-from-h-yer-paleet-to-your-checkout-span2 {
        color: var(--text-text-primary, #ffffff);
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
    }
    .frame-29729 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313414 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313399 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313412 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .btn-md-solid {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 44px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
        cursor: pointer;
    }
    .frame-313497 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313349 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-medium-regular-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-medium-regular-font-size, 14px);
        line-height: var(--button-text-medium-regular-line-height, 24px);
        font-weight: var(--button-text-medium-regular-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btn-md-solid2 {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 44px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
        cursor: pointer;
    }
    .location-info-image {
        height: 50px;
        width: 50px;
    }
    .marker-icon {
        width: 30px !important;
    }
`;

export default function ShareLocationPopup({ visible, setVisible, onLocationSelected, openBotag }) {
    /* #region VARIABLES */
    const { userGPSCoordinates, userAddress } = React.useContext(UserContext);
    const { isMobile, shareLocationSuccess, setShareLocationSuccess, locationLoader, setLocationLoader } = React.useContext(LayoutContext);
    const inputRef = useRef();
    const [locationInput, setLocationInput] = React.useState("");

    const [showLocationPopup, setShowLocationPopup] = React.useState(true);
    const newUserAddress = getLocation();
    const newUserCoords = getCoordinates();
    const { setDefaultNotificationCard } = React.useContext(StandardsContext);
    const { street, streetNumber, postCode, city, country } = newUserAddress || userAddress;
    const address = `${street} ${streetNumber} ${postCode} ${city},${" "}
    ${country}`;
    const validation = street || streetNumber || postCode || city || country;
    /* #endregion */

    /* #region METHODS */
    const handleGMapApiPlaceChanged = () => {
        logGA4Event("Location_Inserted");
        const places = inputRef?.current?.getPlaces();
        if (places && typeof places[Symbol.iterator] === "function") {
            const [place] = places;
            if (place) {
                const latitude = place.geometry.location.lat();
                const longitude = place.geometry.location.lng();

                if (onLocationSelected) {
                    onLocationSelected({ latitude, longitude }, true);
                    setVisible(false);
                    Library.showNotification({
                        message: "Location successfully shared!",
                        type: "success",
                        setDefaultNotificationCard
                    });
                    if (isMobile) {
                        const element = document.getElementById("mainContent");
                        element.scrollIntoView({ behavior: "smooth", block: "center" });
                    }
                }
            }
        } else {
            console.error("GetPlaces did not return an iterable value.");
        }
    };

    const handleShareExactLocation = () => {
        setLocationLoader(true);
        navigator.geolocation.getCurrentPosition(success, error, options);
    };
    const success = position => {
        const { latitude, longitude } = position.coords;
        if (onLocationSelected) {
            onLocationSelected({ latitude, longitude }, true);
            setVisible(false);
            setShareLocationSuccess(true);
        }
        setLocationLoader(false);
    };
    const error = e => {
        console.log(e);
        setShareLocationSuccess(false);
        setLocationLoader(false);
    };
    const options = {
        enableHighAccuracy: true
    };

    const handleCloseButtonClick = () => {
        if (newUserCoords?.latitude || newUserCoords?.longitude || newUserAddress) {
            setVisible(false);
            return;
        }
        setShowLocationPopup(false);
        //TODO: Get the user's location by ?IP? and continue the code below
        logGA4Event("NotSharing_PopUp");
        const callback = data => {
            const { latitude, longitude, country, city, postalCode } = data;
            if (data) {
                onLocationSelected({ latitude, longitude, country, city, postalCode }, false);
                // setVisible(false);
            }
        };
        getUserLocationByIP(callback);
    };
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (visible) {
            // Reset the input when the popup is opened
            setLocationInput("");
        }
    }, [visible]);
    /* #endregion */

    return (
        <DefaultPopupCard visible={visible} backgroundColor="#121212" mobileUsesFullScreen={false}>
            <StyledPage className="share-location">
                {showLocationPopup ? (
                    <>
                        <div className="frame-29643">
                            <div className="frame-313553">
                                <div className="frame-313474">
                                    <div className="share-your-location-to-start">Share your location to start</div>
                                    <Frame290570
                                        className={`frame-29057 cursor-pointer ${locationLoader ? "disable" : ""}`}
                                        onClick={e => (locationLoader ? e.preventDefault() : handleCloseButtonClick())}
                                    />
                                </div>
                                <div className="frame-313700">
                                    <div className={`search-location ${locationLoader ? "disable" : ""}`}>
                                        <div
                                            className="buttons-fixed-width-12"
                                            style={{
                                                borderColor: locationInput.length > 0 ? "#f7665f" : "#a6a6a633"
                                            }}>
                                            <div className="frame-29480pp">
                                                <MarkerPin020 className="marker-pin-02" />
                                                <StandaloneSearchBox // css class: .pac-container
                                                    onLoad={ref => (inputRef.current = ref)}
                                                    onPlacesChanged={handleGMapApiPlaceChanged}
                                                    containerProps={{
                                                        className: "my-custom-class pac-container"
                                                    }}>
                                                    <input
                                                        type="text"
                                                        className="insert-location-dd"
                                                        placeholder="Insert location"
                                                        value={locationInput}
                                                        onChange={e => {
                                                            setLocationInput(e.target.value);
                                                        }}
                                                        disabled={locationLoader}
                                                    />
                                                </StandaloneSearchBox>
                                            </div>
                                        </div>
                                    </div>

                                    {shareLocationSuccess ? (
                                        <>
                                            <div className="or">Or</div>
                                            <div
                                                className="btn-lg-solid cursor-pointer"
                                                onClick={e => (locationLoader ? e.preventDefault() : handleShareExactLocation())}>
                                                <div className="frame-29590" onClick={() => logGA4Event("Share_PopUp")}>
                                                    <div className="button">
                                                        {locationLoader ? (
                                                            <span className="spinner-text">
                                                                <img src={Spinner} alt="" className="spinner-img" />
                                                                &nbsp;
                                                                {"  "}Detecting Location
                                                            </span>
                                                        ) : (
                                                            "Share my exact location"
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="frame-29642">
                            <div>
                                {shareLocationSuccess ? (
                                    <div className="disclaimer">
                                        BOtag needs a location to show you the best stores and prices for you. Share your location to
                                        experience BOtag!
                                    </div>
                                ) : (
                                    <div className="disclaimer">
                                        <span style={{ color: "var( --icons-icon-red)" }}>
                                            Your location could not be detected.
                                            <br />
                                        </span>
                                        Please insert your location manually.
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    <ConfirmationCard
                        address={address}
                        setVisible={setVisible}
                        handleShareExactLocation={handleShareExactLocation}
                        openBotag={openBotag}
                        validation={validation}
                    />
                )}
            </StyledPage>
        </DefaultPopupCard>
    );
}

export const ConfirmationCard = ({ address, setVisible, handleShareExactLocation, openBotag, validation }) => {
    return (
        <ConfirmationCardStyles>
            <div className="confirmation-card">
                <div className="frame-29643">
                    <div className="frame-313411">
                        <div className="frame-313402">
                            <div className="frame-313406">
                                <div className="frame-3134022">
                                    <div className="frame-313401">
                                        <div className="frame-3590">
                                            <img src={LocationInfoImg} className="location-info-image" />
                                        </div>
                                        <div className="sorry">BOtag is location based</div>
                                        <div className="no-other-stores-have-this-item-in-stock-still-want-to-add-the-5-items-from-h-yer-paleet-to-your-checkout">
                                            <span>
                                                <span className="no-other-stores-have-this-item-in-stock-still-want-to-add-the-5-items-from-h-yer-paleet-to-your-checkout-span">
                                                    We will use this as your generic location for now:
                                                    <br />
                                                </span>
                                                <span className="no-other-stores-have-this-item-in-stock-still-want-to-add-the-5-items-from-h-yer-paleet-to-your-checkout-span2">
                                                    {(validation && address) || ""}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="frame-29729">
                                    <div className="frame-313414">
                                        <div className="frame-313399">
                                            <div className="frame-313412">
                                                <div
                                                    className="btn-md-solid"
                                                    onClick={() => {
                                                        setVisible(false);
                                                        openBotag();
                                                    }}>
                                                    <div className="frame-313497">
                                                        <div className="frame-313349">
                                                            <div className="button">Ok</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="btn-md-solid2" onClick={handleShareExactLocation}>
                                                    <div className="frame-313497">
                                                        <div className="frame-313349">
                                                            <div className="button">Find my location instead</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ConfirmationCardStyles>
    );
};

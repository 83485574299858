import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import LabelIcon from "../../../assets/images/label-icon.svg";
import botagCloseBtn from "../../../assets/images/botagCloseBtn.png";
import Pen from "../../../assets/images/suggest-pen.svg";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import LayoutContext from "../../../contexts/LayoutContext";
import Skeleton from "../../common/skeletons/Skeleton";
import * as Library from "../../../utils/Library";
import { useNavigate } from "react-router-dom";
import AppContext from "../../../contexts/AppContext";

/* #region CSS */
const StyledPage = styled.div`
    &.frame-295922554,
    &.frame-295922554 * {
        box-sizing: border-box;
    }
    &.frame-295922554 {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: flex-start;
        -webkit-box-pack: start;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
        padding: 0px 25px;
    }
    .frame-313706 {
        display: flex;
        flex-direction: row;
        gap: 13px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 24px;
        position: relative;
    }
    .newest {
        color: #ffffff;
        text-align: center;
        font-family: var(--heading-1-font-family, "OpenSans-Bold", sans-serif);
        font-size: var(--heading-1-font-size, 24px);
        line-height: var(--heading-1-line-height, 24px);
        font-weight: var(--heading-1-font-weight, 700);
        position: relative;
    }
    .group-3705 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }

    .frame-295922400 {
        background: linear-gradient(166.11deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 50%);
        border-radius: 24px;
        width: 613.33px;
        height: 670px;
    }
    .frame-295922390 {
        padding: 8px 16px 8px 16px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-end;
        justify-content: center;
        width: 175px;
        height: 71px;
        position: absolute;
        right: 0.33px;
        bottom: 0.29px;
    }
    .samsoe-samsoe-logo-white-3 {
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .info-note {
        background: rgba(0, 0, 0, 0.6);
        border-radius: 30px;
        padding: 5px 15px;
        display: flex;
        flex-direction: row;
        gap: 19.2px;
        align-items: flex-start;
        justify-content: flex-start;
        position: absolute;
        left: 16.33px;
        bottom: 16px;
        backdrop-filter: blur(5px);
    }
    .frame-29614 {
        display: flex;
        flex-direction: row;
        gap: 4.8px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .tag-01 {
        flex-shrink: 0;
        width: 14.4px;
        height: 14.4px;
        position: relative;
        overflow: visible;
    }
    ._2-products {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 14.40000057220459px;
        line-height: 24px;
        font-weight: 400;
        position: relative;
    }

    .tag-012 {
        flex-shrink: 0;
        width: 14.4px;
        height: 14.4px;
        position: relative;
        overflow: visible;
    }
    .frame-295922398 {
        background: linear-gradient(166.11deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 50%);
        border-radius: 24px;
        width: 613.33px;
        height: 941px;
        position: absolute;
        left: 653.33px;
        top: 64px;
    }
    .tag-013 {
        flex-shrink: 0;
        width: 14.4px;
        height: 14.4px;
        position: relative;
        overflow: visible;
    }
    .frame-295922399 {
        background: linear-gradient(166.11deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 50%);
        border-radius: 24px;
        width: 613.33px;
        height: 770px;
        position: absolute;
        left: 1282.67px;
        top: 64px;
    }
    .tag-014 {
        flex-shrink: 0;
        width: 14.4px;
        height: 14.4px;
        position: relative;
        overflow: visible;
    }
    .frame-295922405 {
        background: linear-gradient(166.11deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 50%);
        border-radius: 24px;
        width: 613.33px;
        height: 770px;
        position: absolute;
        left: 24px;
        top: 2422px;
    }
    .frame-295922388 {
        padding: 8px 16px 8px 16px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-end;
        justify-content: center;
        width: 175px;
        height: 71px;
        position: absolute;
        right: 0.33px;
        bottom: 0.29px;
    }
    .tag-015 {
        flex-shrink: 0;
        width: 14.4px;
        height: 14.4px;
        position: relative;
        overflow: visible;
    }
    .frame-295922406 {
        background: linear-gradient(166.11deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 50%);
        border-radius: 24px;
        width: 613.33px;
        height: 770px;
        position: absolute;
        left: 653.33px;
        top: 2593px;
    }
    .tag-016 {
        flex-shrink: 0;
        width: 14.4px;
        height: 14.4px;
        position: relative;
        overflow: visible;
    }
    .frame-295922407 {
        background: linear-gradient(166.11deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 50%);
        border-radius: 24px;
        width: 613.33px;
        height: 770px;
        position: absolute;
        left: 1282.67px;
        top: 2614px;
    }
    .tag-017 {
        flex-shrink: 0;
        width: 14.4px;
        height: 14.4px;
        position: relative;
        overflow: visible;
    }
    .frame-295922562 {
        background: var(--color-emphasis, #9cf9cf);
        border-radius: 16px;
        padding: 32px 24px 32px 24px;
        display: flex;
        flex-direction: column;
        height: 770px;
        align-items: center;
        justify-content: center;
    }
    .frame-295922559 {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .more-brands-coming-soon {
        color: var(--color-gray-700, #000000);
        text-align: center;
        font-family: "ItcAvantGardeGothicProDemi-Regular", sans-serif;
        font-size: 64px;
        line-height: 54px;
        font-weight: 600;
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .want-to-see-your-favorite-brand-here-let-us-know {
        color: var(--color-gray-700, #000000);
        text-align: center;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btn-lg-solid {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 30px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 258.13px;
        height: 57.6px;
        position: relative;
        box-shadow: 0px 36px 50px 0px rgba(83, 188, 141, 1);
    }
    .frame-29590 {
        display: flex;
        flex-direction: row;
        gap: 9.6px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .pencil-line {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        position: relative;
        overflow: visible;
    }
    .suggest-a-brand {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 16.80000114440918px;
        line-height: 28.8px;
        font-weight: 600;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-295922403 {
        background: linear-gradient(166.11deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 50%);
        border-radius: 24px;
        width: 613px;
        height: 770px;
        position: absolute;
        left: 653px;
        top: 1807px;
    }
    .frame-2959223882 {
        padding: 8px 16px 8px 16px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-end;
        justify-content: center;
        width: 175px;
        height: 71px;
        position: absolute;
        right: 0px;
        bottom: 0.29px;
    }
    .tag-018 {
        flex-shrink: 0;
        width: 14.4px;
        height: 14.4px;
        position: relative;
        overflow: visible;
    }
    .frame-295922404 {
        background: linear-gradient(166.11deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 50%);
        border-radius: 24px;
        width: 616px;
        height: 770px;
        position: absolute;
        left: 1280px;
        top: 1828px;
    }
    .tag-019 {
        flex-shrink: 0;
        width: 14.4px;
        height: 14.4px;
        position: relative;
        overflow: visible;
    }
    .frame-295922401 {
        background: linear-gradient(166.11deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 50%);
        border-radius: 24px;
        width: 613.33px;
        height: 770px;
        position: absolute;
        left: 24px;
        top: 850px;
    }
    .tag-0110 {
        flex-shrink: 0;
        width: 14.4px;
        height: 14.4px;
        position: relative;
        overflow: visible;
    }
    .frame-295922397 {
        background: linear-gradient(166.11deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 50%);
        border-radius: 24px;
        width: 613.33px;
        height: 770px;
        position: absolute;
        left: 653.33px;
        top: 1021px;
    }
    .tag-0111 {
        flex-shrink: 0;
        width: 14.4px;
        height: 14.4px;
        position: relative;
        overflow: visible;
    }
    .frame-313382 {
        background: linear-gradient(166.11deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 50%);
        border-radius: 24px;
        width: 613.33px;
        height: 962px;
        position: absolute;
        left: 1282.67px;
        top: 850px;
    }
    .frame-295922389 {
        padding: 8px 16px 8px 16px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-end;
        justify-content: center;
        width: 175px;
        height: 71px;
        position: absolute;
        right: 0.33px;
        bottom: 0.29px;
    }
    .tag-0112 {
        flex-shrink: 0;
        width: 14.4px;
        height: 14.4px;
        position: relative;
        overflow: visible;
    }
    .frame-295922556 {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        width: 1920px;
        height: 373px;
        position: absolute;
        left: 0px;
        bottom: 170px;
    }
    .btn-lg-solid2 {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 30px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 178px;
        height: 48px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
        margin-bottom: 15px;
    }
    .frame-295902 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-medium-regular-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-medium-regular-font-size, 14px);
        line-height: var(--button-text-medium-regular-line-height, 24px);
        font-weight: var(--button-text-medium-regular-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .frame-121212 {
        position: relative !important;
        cursor: pointer;
    }
    @media only screen and (max-width: 992px) {
        .info-note {
            top: 16px;
            bottom: unset;
        }
    }
    @media only screen and (max-width: 1200px) {
        .frame-295922562 {
            height: 555px;
        }
        .more-brands-coming-soon {
            font-size: 40px;
        }
        .want-to-see-your-favorite-brand-here-let-us-know {
            font-size: 18px;
        }
    }
    @keyframes pulse-white {
        0% {
            transform: scale(1.2);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
        }

        100% {
            transform: scale(0.9);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
    }
`;
/* #endregion CSS */
export default function Newest() {
    /* #region VARS */
    const { isMobile, setShowSuggestPopup } = React.useContext(LayoutContext);
    const { setSelectedHomePageBotagId, setSelectedBotag } = React.useContext(AppContext);

    /* #region STATES */
    const [data, setData] = useState([]);
    const [onHover, setOnHover] = useState({
        state: false,
        id: ""
    });
    const [isShowCloseBtn, setIsShowCloseBtn] = useState({ state: false, id: "" });
    const [paginationData, setPaginationData] = useState({
        currentPage: "",
        totalPages: ""
    });

    const [isLoading, setIsLoading] = useState(false);
    const [pageCount, setPageCount] = useState(1);
    const [beacon, setBeacon] = useState({ state: false, id: "" });
    /* #endregion STATES */

    const skeletonsArr = [
        { height: isMobile ? "280px" : "700px" },
        { height: isMobile ? "250px" : "700px" },
        { height: isMobile ? "280px" : "700px" },
        { height: isMobile ? "230px" : "600px" },
        { height: isMobile ? "280px" : "650px" },
        { height: isMobile ? "200px" : "500px" },
        { height: isMobile ? "280px" : "700px" },
        { height: isMobile ? "200px" : "400px" },
        { height: isMobile ? "230px" : "550px" }
    ];
    const formData = {
        page: pageCount,
        limit: 12
    };
    const navigate = useNavigate();
    /* #endregion VARS */

    /* #region Methods */

    const getImages = () => {
        Library.makePostRequest("getHomeNewestBOimages", formData, false, setIsLoading)
            .then(res => {
                const { data } = res;

                if (res?.status === 200) {
                    setData(prev => [...data?.data, ...prev]);
                    setPaginationData({
                        currentPage: data?.currentPage,
                        totalPages: data?.totalPages
                    });
                }
            })
            .catch(e => {
                console.log(e, "");
            });
    };
    /* #endregion Methods */

    /* #region EFFECTS */

    useEffect(() => {
        setSelectedBotag(null);
        getImages();
    }, [pageCount]);
    const targetImageRef = useRef([]);
    /* #endregion EFFECTS*/

    return (
        <StyledPage className="frame-295922554" style={{ padding: "0px 15px" }}>
            <div className="frame-313706">
                <div className="newest">Newest</div>
            </div>
            <div className="group-3705">
                <div style={{ width: "100%" }}>
                    <>
                        <ImageList variant="masonry" cols={isMobile ? 1 : 3} gap={14} style={{ marginTop: "0" }}>
                            {data.map((item, index) => {
                                if (!targetImageRef.current[index]) {
                                    targetImageRef.current[index] = React.createRef();
                                }
                                return (
                                    <>
                                        {index === 1 && (
                                            <ImageListItem key={index + "test"}>
                                                <div className="frame-295922562">
                                                    <div className="frame-295922559">
                                                        <div className="more-brands-coming-soon">More brands coming soon!</div>
                                                        <div className="want-to-see-your-favorite-brand-here-let-us-know">
                                                            Want to see your favorite brand here? Let us know!
                                                        </div>
                                                        <div
                                                            className="btn-lg-solid"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => setShowSuggestPopup(true)}>
                                                            <div className="frame-29590">
                                                                <img className="pencil-line" src={Pen} alt="" />
                                                                <div className="suggest-a-brand">Suggest a brand</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ImageListItem>
                                        )}

                                        <ImageListItem
                                            key={item?._id}
                                            // style={{
                                            //     opacity: onHover?.state && onHover?.id === item?._id ? 1 : onHover?.state && 0.3
                                            // }}
                                            className="frame-121212"
                                            onClick={() => {
                                                if (isMobile) {
                                                    setBeacon({
                                                        state: true,
                                                        id: item?._id
                                                    });

                                                    setOnHover({
                                                        state: true,
                                                        id: item?._id
                                                    });
                                                    setIsShowCloseBtn({ state: true, id: item?._id });
                                                } else {
                                                    navigate(`/${item?.ourID}`);
                                                }
                                            }}
                                            onMouseEnter={() => {
                                                !isMobile &&
                                                    setBeacon({
                                                        state: true,
                                                        id: item?._id
                                                    });
                                                setOnHover({
                                                    state: true,
                                                    id: item?._id
                                                });
                                            }}
                                            onMouseLeave={() => {
                                                setBeacon({
                                                    state: false,
                                                    id: item?._id
                                                });
                                                setOnHover({
                                                    state: false,
                                                    id: item?._id
                                                });
                                            }}>
                                            {beacon?.state &&
                                                beacon?.id === item?._id &&
                                                item?.BOtags?.map(i => {
                                                    return (
                                                        <Beacon
                                                            i={i}
                                                            item={item}
                                                            targetImageRef={targetImageRef.current[index]}
                                                            setSelectedHomePageBotagId={setSelectedHomePageBotagId}
                                                        />
                                                    );
                                                })}

                                            {isMobile && isShowCloseBtn?.state && isShowCloseBtn?.id === item?._id && (
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        zIndex: 5,
                                                        top: "25px",
                                                        left: "25px"
                                                    }}
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        setBeacon({
                                                            state: false,
                                                            id: ""
                                                        });
                                                        setOnHover({
                                                            state: false,
                                                            id: ""
                                                        });

                                                        setIsShowCloseBtn(false);
                                                    }}>
                                                    <img src={botagCloseBtn} alt="" height={"50px"} width={"50px"} />
                                                </div>
                                            )}

                                            <img
                                                src={item?.imageURL}
                                                srcSet={item?.imageURL}
                                                alt={item?.src}
                                                loading="lazy"
                                                style={{ minHeight: "200px", borderRadius: "30px" }}
                                                ref={targetImageRef.current[index]}
                                            />

                                            {!isShowCloseBtn?.state && (
                                                <>
                                                    <div className="info-note">
                                                        <div className="frame-29614">
                                                            <img className="tag-013" src={LabelIcon} alt="" />
                                                            <div className="_2-products">{item?.BOtags?.length} products</div>
                                                        </div>
                                                    </div>
                                                    <div className="frame-295922390">
                                                        <img className="samsoe-samsoe-logo-white-3" src={item?.logo} alt="" />
                                                    </div>
                                                </>
                                            )}
                                        </ImageListItem>
                                    </>
                                );
                            })}
                        </ImageList>
                    </>

                    {isLoading && (
                        <ImageList variant="masonry" cols={isMobile ? 1 : 3} gap={14} style={{ marginTop: "0" }}>
                            {skeletonsArr.map(i => {
                                return (
                                    <ImageListItem>
                                        {isLoading && <Skeleton width={"100%"} height={i?.height} style={{ borderRadius: "20px" }} />}
                                    </ImageListItem>
                                );
                            })}
                        </ImageList>
                    )}
                </div>
                {paginationData?.currentPage >= paginationData?.totalPages ? null : (
                    <div className="">
                        <div
                            className="btn-lg-solid2"
                            onClick={() => {
                                setPageCount(prev => prev + 1);
                            }}>
                            <div className="frame-295902">
                                <div className="button">Load more...</div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </StyledPage>
    );
}
/* #region SMALL COMPONENTS */

const Beacon = ({ i, item, targetImageRef, setSelectedHomePageBotagId }) => {
    const navigate = useNavigate();
    const handleBOImagePin = botag => {
        setSelectedHomePageBotagId(botag?.GTINbarcode);
        navigate(`/${item?.ourID}`);
    };
    return (
        <div
            className="beacons"
            style={{
                position: "absolute",
                left: i?.beaconXposition * (targetImageRef?.current?.clientWidth / targetImageRef?.current?.naturalWidth) + "px",
                top: i?.beaconYposition * (targetImageRef?.current?.clientHeight / targetImageRef?.current?.naturalHeight) + "px",
                zIndex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "5px",
                cursor: "pointer"
            }}
            onClick={() => {
                handleBOImagePin(i);
                //navigate(`/${item?.ourID}`);
            }}>
            <div
                style={{
                    backgroundColor: "#0000004D ",
                    height: "40px",
                    width: "40px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    animation: "pulse-white 1.5s infinite"
                }}>
                <div
                    style={{
                        backgroundColor: "#FFFFFF",
                        height: "20px",
                        width: "20px",
                        borderRadius: "50%"
                    }}></div>
            </div>
            <div style={{ backgroundColor: "#0000004D", padding: "8.4px 15.2px 8.4px 15.2px", borderRadius: "20px" }}>{i?.productName}</div>
        </div>
    );
};
/* #endregion SMALL COMPONENTS */

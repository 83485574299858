import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Vector from "../../../assets/images/empty-checkout.svg";
import LayoutContext from "../../../contexts/LayoutContext";
import UserContext from "../../../contexts/UserContext";
import ItemCheckoutCard from "../../sections/ItemCheckoutCard";
import ConfirmPurchaseLayout from "../../sections/ConfirmPurchaseLayout";
import * as Formatter from "../../../utils/Formatter";
import PaymentPopup from "../dialogs/PaymentPopup";
import axios from "axios";
import { HANDLE_CARD_PAYMENT, HANDLE_PAYMENT_TRANSFERS, CREATE_ORDER } from "../../../api/Endpoints";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import logGA4Event from "../../../utils/firebase/analytics";
import { useNavigate } from "react-router-dom";

import AppContext from "../../../contexts/AppContext";

/* #region SCOPED CSS */
const StyledCheckoutPanel = styled.div`
    &.multi-bag-desktop-panel,
    &.multi-bag-desktop-panel * {
        box-sizing: border-box;
    }
    &.multi-bag-desktop-panel {
        height: 100%;
        position: relative;
        overflow: hidden;
        width: 100%;

        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
    }
    .panel-headers {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        flex-shrink: 0;
    }
    .frame-313366 {
        background: var(--wrap-wrap-brand, rgba(247, 102, 95, 0.1));
        border-radius: 500px;
        padding: 4px 14px 4px 14px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .quick-checkout {
        color: var(--text-text-brand, #f7665f);
        text-align: center;
        font-size: var(--heading-3-font-size, 16px);
        line-height: var(--heading-3-line-height, 24px);
        font-weight: var(--heading-3-font-weight, 600);
        position: relative;
    }
    .frame-313365 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        width: 159px;
        position: relative;
    }
    .btn-xs-secondary {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: 30px;
        position: relative;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-small-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-small-font-size, 12px);
        line-height: var(--button-text-small-line-height, 16px);
        font-weight: var(--button-text-small-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .separator {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: auto;
        margin-top: 16px;
        margin-bottom: 24px;

        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0 0 0;
        flex-shrink: 0;
    }
    .empty-checkout {
        padding: 18px 32px 18px 32px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 100vh;
        position: relative;
        left: 0px;
        top: 48px;
        overflow: hidden;
    }
    .frame-313697 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-313691 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
        top: -72px;
    }
    .vector {
        flex-shrink: 0;
        width: 63.75px;
        height: 60px;
        position: relative;
        overflow: visible;
    }
    .your-checkout-is-empty {
        color: #8c8c8c;
        text-align: center;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .loader-bg-mobile {
        position: absolute;
        z-index: 999;
        height: 100%;
        width: 100%;
        backdrop-filter: blur(7px);
        border-left: 1px solid rgba(166, 166, 166, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 16px;
        background-color: rgba(0, 0, 0, 0.4);
    }
`;

const CheckoutItemsStyles = styled.div`
    &.frame-29739,
    &.frame-29739 * {
        box-sizing: border-box;
    }
    &.frame-29739 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
        flex-shrink: 1;
        overflow: hidden;
    }
`;
/* #endregion */

export default function CheckoutPanel() {
    /* #region VARS */
    const {
        setShowRightCheckoutPanel,
        showPaymentPopup,
        paymentPopupData,
        shippingAddressPopupData,
        setPlaceOrderLoading,
        placeOrderLoading,
        setShowPaymentPopup,
        setShowThankyouPopup,
        setStripeCardData,
        setOrderID,
        courierInstructionsData,
        setShowBOImagePageID,
        setShowRightUserLocationPanel,
        isMobile,
        setCardPlaceOrderLoading,
        setQuickBuyPopup
    } = React.useContext(LayoutContext);
    const {
        checkoutCartData,
        checkoutPaymentData,
        BOTagAccountID,
        shippingRecipient,
        setCheckoutPaymentData,
        userData,
        setUserData,
        setCheckoutCartData,
        setThankYouAmount
    } = React.useContext(UserContext);
    const { selectedBOImageData } = React.useContext(AppContext);
    const navigate = useNavigate();
    const elements = useElements();
    const stripe = useStripe();
    const [singleItemId, setSingleItemId] = useState("");
    /* #endregion */

    /* #region METHODS */

    const callback = data => {
        const { firstName, lastName, email, mobileNumber } = data;
        setUserData({
            firstName: firstName,
            lastName: lastName,
            phoneCountryPrefix: "+47",
            email: email,
            phone: mobileNumber
        });
    };

    const closeClicked = () => {
        setShowRightCheckoutPanel(false);
    };
    const resetValidation = () => {
        setStripeCardData({
            isCardNumber: false,
            isCardExpData: false,
            isCardCvv: false,
            isComplete: false
        });
    };

    //order creation function
    // TODO: payload is a bit new for saved card
    const createNewOrder = async (updateResponse, cardCredentials, cardDetail) => {
        const _accountID = BOTagAccountID;
        let item;
        if (checkoutCartData) {
            const arr = [];
            checkoutCartData?.items.map(checkOutData => {
                const data = checkOutData?.BOTagData;
                const algorithmData = checkOutData?.BOTagAlgorithmData;
                const coordinates = checkOutData?.UserGPSCoordinates;
                const address = `${checkOutData?.UserAddress?.street} ${checkOutData?.UserAddress?.streetNumber}, ${checkOutData?.UserAddress?.postCode} ${checkOutData?.UserAddress?.city}, ${checkOutData?.UserAddress?.country}`;
                const payload = {
                    GTINbarcode: data?.GTINbarcode, //done
                    storeID: algorithmData?.storeDetail?._id, //done
                    productname: data?.productName, //done
                    brandName: data?.labelBrandName, //done
                    itemPrice: algorithmData?.discountPrice ? algorithmData?.discountPrice : algorithmData?.price, //done
                    isDiscount: algorithmData?.discountPrice ? true : false, //done
                    originalItemPrice: algorithmData?.price, //done
                    deliveryCost: algorithmData?.deliveryCharges || 0,
                    deliveryOption: algorithmData?.type, //done
                    deliveryPreferences: data?.deliveryPreference, //done
                    quantity: data?.selectedQuantity,
                    BOimageID: selectedBOImageData?._id,
                    deliveryID: algorithmData?.cf?._id || undefined, //done
                    brandID: data?.labelBrandID, //done
                    userLocation: {
                        address: address, //done
                        country: checkOutData?.UserAddress?.country, //done
                        latitude: coordinates?.latitude, //done
                        longitude: coordinates?.longitude, //done
                        courierInstructions:
                            checkOutData?.BOTagData?.deliveryMethod === "courier"
                                ? courierInstructionsData[checkOutData?.itemID]
                                : "undefined" //TODO: not done
                    }
                };
                if (algorithmData?.type === "shipping") {
                    if (shippingAddressPopupData?.shippingAddressChangedInPopup) {
                        payload.userLocation = {
                            address: shippingAddressPopupData?.addressName, //done
                            country: shippingAddressPopupData?.country, //done
                            latitude: coordinates?.latitude, //done
                            longitude: coordinates?.longitude, //done
                            doorNumber: shippingAddressPopupData?.addressNumber, //done
                            postCode: shippingAddressPopupData?.postCode, //done
                            city: shippingAddressPopupData?.city, //done

                            recipientFirstName: shippingRecipient?.firstName, //done
                            recipientLastName: shippingRecipient?.lastName, //done
                            recipientCompanyName: shippingAddressPopupData?.company, //done
                            floor: shippingAddressPopupData?.floor //done
                        };
                    } else {
                        payload.userLocation = {
                            address: address,
                            country: checkOutData?.UserAddress?.country,
                            latitude: coordinates?.latitude,
                            longitude: coordinates?.longitude,
                            // doorNumber: shippingUserAddress?.addressNumber, //TODO: not done
                            postCode: checkOutData?.UserAddress?.postCode,
                            city: checkOutData?.UserAddress?.city,
                            recipientFirstName: shippingRecipient?.firstName || userData?.firstName,
                            recipientLastName: shippingRecipient?.lastName || userData?.lastName,
                            recipientCompanyName: "",
                            floor: ""
                        };
                    }
                }
                arr.push(payload);
            });
            item = arr;
        }
        const newOrder = {
            firstName: paymentPopupData?.firstName || userData?.firstName,
            lastName: paymentPopupData?.lastName || userData?.lastName,
            email: paymentPopupData?.email || userData?.email,
            cardName: checkoutPaymentData?.cardHolderName || "",
            cardNumber: checkoutPaymentData?.cardNumber || "",
            cardExpireDate: checkoutPaymentData?.cardExpDate || "",
            cardCVV: checkoutPaymentData?.cardCVV || "",
            mobileNumber: paymentPopupData?.phoneNumber || userData?.phone,
            items: item,
            accountID: _accountID || undefined,
            stripeUpdateResponse: updateResponse || {},
            cardCredentials: cardCredentials || undefined,
            // shippingUserAddress: shippingUserAddress || undefined, //TODO: not done
            cardDetail: cardDetail
        };

        const response = await axios.post(`${process.env.REACT_APP_API_URL}${CREATE_ORDER}`, newOrder);

        if (response?.status === 200) {
            const orderID = response.data?.orderID;
            setThankYouAmount(Formatter.formatPrice(checkoutCartData?.total));
            setCheckoutCartData({ items: [], total: 0, currency: "NOK", currencySymbol: "kr" });
            setStripeCardData({
                isCardNumber: false,
                isCardExpData: false,
                isCardCvv: false,
                isComplete: false
            });
            setQuickBuyPopup(false);
            callback(newOrder);
            setOrderID(orderID);
            setPlaceOrderLoading(false);
            setShowPaymentPopup(false);
            setShowThankyouPopup(true);
            logGA4Event("ThankYou");
            setShowRightCheckoutPanel(false);
            setShowBOImagePageID(false);
            setShowRightUserLocationPanel(false);
            setCardPlaceOrderLoading(false);
            navigate("/feed");
        }
    };

    //stripe function
    const handleSubmit = async (PAYMENT_METHOD = null) => {
        if (PAYMENT_METHOD === "SAVED_PAYMENT") {
            setPlaceOrderLoading(true);
        } else {
            setCardPlaceOrderLoading(true);
        }

        logGA4Event("Payment_Loading");
        const data = [];
        try {
            if (checkoutCartData) {
                checkoutCartData?.items.map(item => {
                    const payload = {
                        storeID: item?.BOTagAlgorithmData?.storeDetail?._id,
                        companyID: item?.BOTagAlgorithmData?.storeDetail?.companyId,
                        totalPrice: item?.BOTagAlgorithmData?.price,
                        type: item?.BOTagAlgorithmData?.type,
                        name: item?.BOTagData?.productName,
                        quantity: item?.BOTagData?.selectedQuantity
                    };
                    data?.push(payload);
                });
            }

            const body = {
                data: data,
                email: paymentPopupData?.email || userData?.email,
                name: paymentPopupData?.firstName || userData?.firstName,
                customerId: checkoutPaymentData?.customerID || undefined
            };
            const response = await axios.post(`${process.env.REACT_APP_API_URL}${HANDLE_CARD_PAYMENT}`, body);

            if (response?.data?.success) {
                try {
                    const cardElement = elements.getElement(CardElement);
                    const result = await stripe.confirmCardPayment(response.data.paymentIntent.client_secret, {
                        payment_method:
                            PAYMENT_METHOD === "SAVED_PAYMENT"
                                ? checkoutPaymentData?.paymentMethodID
                                : {
                                      card: cardElement,
                                      billing_details: {
                                          name: paymentPopupData?.nameOnCard
                                      }
                                  }
                    });

                    if (result.error) {
                        console.log(result.error);
                    } else {
                        if (result?.paymentIntent?.status?.toLowerCase() === "succeeded") {
                            const payload = {
                                connectedAccounts: response?.data?.connectedAccounts,
                                uuid: response?.data?.uuid,
                                paymentIntentId: response.data.paymentIntent?.id,
                                paymentMethod: result?.paymentIntent?.payment_method,
                                bothebotAccountId: BOTagAccountID,
                                customerId: response.data?.customerID
                            };

                            const handleTransferResponse = await axios.post(
                                `${process.env.REACT_APP_API_URL}${HANDLE_PAYMENT_TRANSFERS}`,
                                payload
                            );
                            if (PAYMENT_METHOD !== "SAVED_PAYMENT") {
                                const cardInformation = handleTransferResponse?.data?.cardDetail;
                                cardInformation.paymentMethodID = result?.paymentIntent?.payment_method;
                                cardInformation.customerID = response.data?.customerID;
                                if (localStorage.getItem("pid")) {
                                    cardInformation.cardHolder = cardInformation.cardHolderName;
                                    cardInformation.cardExpiration = cardInformation.cardExpDate;
                                    delete cardInformation.cardHolderName;
                                    delete cardInformation.cardExpDate;
                                    setCheckoutPaymentData(cardInformation);
                                }
                            }

                            createNewOrder(
                                handleTransferResponse?.data?.updateResponse,
                                handleTransferResponse?.data?.cardCredentials,
                                handleTransferResponse?.data?.cardDetail
                            );
                        }
                    }
                } catch (error) {
                    createNewOrder(error?.response?.data?.updateResponse);
                }
            }
        } catch (err) {
            logGA4Event("PaymentFailed_PopUp");
            console.log("payment failed");
            setPlaceOrderLoading(false);
            setShowPaymentPopup(false);
            resetValidation();
            setCardPlaceOrderLoading(false);
        }
    };
    /* #endregion */

    return (
        <StyledCheckoutPanel className="multi-bag-desktop-panel">
            <PanelHeader closeClicked={closeClicked} isMobile={isMobile} />
            {showPaymentPopup && <PaymentPopup handleSubmit={handleSubmit} />}

            {checkoutCartData?.items?.length === 0 ? (
                <EmptyCheckoutLayout />
            ) : (
                <CheckoutItemsLayout
                    checkoutCartData={checkoutCartData}
                    singleItemId={singleItemId}
                    setSingleItemId={setSingleItemId}
                    isMobile={isMobile}
                />
            )}
            {checkoutCartData?.items?.length > 0 && (
                <ConfirmPurchaseLayout
                    userName={`${userData?.firstName} ${userData?.lastName}`}
                    email={`${userData?.email}`}
                    phone={Formatter.formatPhoneNumber(userData?.phone, true, userData?.phoneCountryPrefix, "--")}
                    totalAmount={Formatter.formatPrice(checkoutCartData?.total)}
                    currency={checkoutCartData?.currency}
                    cardType={checkoutPaymentData?.cardName}
                    cardNumber={checkoutPaymentData?.cardNumber}
                    cardExpDate={checkoutPaymentData?.cardExpiration}
                    setShowPaymentPopup={setShowPaymentPopup}
                    handleSubmit={handleSubmit}
                    isMobile={isMobile}
                />
            )}
        </StyledCheckoutPanel>
    );
}

/* #region SMALL COMPONENTS */

const PanelHeader = ({ closeClicked, isMobile }) => {
    return (
        <div style={{ flexShrink: 0, marginRight: isMobile && "16px" }}>
            <div className="panel-headers">
                <div className="frame-313366">
                    <div className="quick-checkout">Quick checkout</div>
                </div>
                <div className="frame-313365">
                    <div className="btn-xs-secondary cursor-pointer" onClick={closeClicked}>
                        <div className="button">Close</div>
                    </div>
                </div>
            </div>
            <hr className="separator" />
        </div>
    );
};

const EmptyCheckoutLayout = () => {
    return (
        <div className="empty-checkout">
            <div className="frame-313697">
                <div className="frame-313691">
                    <img className="vector" src={Vector} alt="" />
                    <div className="your-checkout-is-empty">Your checkout is empty...</div>
                </div>
            </div>
        </div>
    );
};

const CheckoutItemsLayout = ({ checkoutCartData, singleItemId, setSingleItemId, isMobile }) => {
    return (
        <div style={{ overflow: "auto", paddingRight: isMobile && "16px", height: "100%" }}>
            <CheckoutItemsStyles className="frame-29739">
                {checkoutCartData?.items?.map((item, index) => {
                    return (
                        <div className="w-100" key={index}>
                            {item?.BOTagData?.deliveryMethod === "pickup" ||
                            item?.BOTagData?.deliveryMethod === "courier" ||
                            item?.BOTagData?.deliveryMethod === "shipping" ? (
                                <ItemCheckoutCard
                                    item={item}
                                    onEditClick={() => {
                                        setSingleItemId(item?.itemID);
                                    }}
                                    singleItemId={singleItemId}
                                />
                            ) : (
                                <div>Delivery method not implemented: {item?.BOTagData?.deliveryMethod}</div>
                            )}
                        </div>
                    );
                })}
            </CheckoutItemsStyles>
        </div>
    );
};
/* #endregion */

import React, { useContext, useState } from "react";
import styled from "styled-components";
import IconVisa from "./../../../../assets/images/icon-visa.png";
import EditPen from "../../../../assets/images/edit-new-pen.svg";
import LayoutContext from "../../../../contexts/LayoutContext";
/* #region STYLED CSS */
const StyledPage = styled.div`
    &.frame-295922521 {
        background: #151515;
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 122px;
        position: relative;
    }
    .frame-313548 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-313657 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313398 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313396 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-313392 {
        display: flex;
        flex-direction: column;
        gap: 2px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        height: 86px;
        position: relative;
    }
    .frame-29716 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .product-name {
        color: var(--dark-text-icons-and-buttons-white-600, #ffffff);
        text-align: left;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 12px;
        line-height: 20px;
        font-weight: 600;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-29719 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-297162 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .product-name2 {
        color: var(--dark-text-icons-and-buttons-gray-500, #8c8c8c);
        text-align: left;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-29720 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .product-name3 {
        color: var(--dark-text-icons-and-buttons-gray-500, #8c8c8c);
        text-align: left;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-29721 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313397 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-3133922 {
        display: flex;
        flex-direction: column;
        gap: 2px;
        align-items: flex-end;
        justify-content: center;
        flex: 1;
        height: 86px;
        position: relative;
    }
    .image-144 {
        flex-shrink: 0;
        width: 32.87px;
        height: 20px;
        position: relative;
        object-fit: cover;
    }
    .frame-29722 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-end;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-297163 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        position: relative;
    }
    .frame-29723 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313385 {
        display: flex;
        flex-direction: column;
        gap: 36px;
        align-items: flex-end;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
        cursor: pointer;
    }
    .frame-313378 {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .icon {
        border-radius: 0px;
        flex-shrink: 0;
        position: relative;
        overflow: visible;
    }
    .edit {
        color: var(--text-text-brand, #f7665f);
        text-align: left;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
    }
`;
export default function UserInformation({ checkoutPaymentData, userData, setShowPaymentPopupQuick, placeOrderLoading }) {
    const { setStripeCardData } = useContext(LayoutContext);
    const handleEditButton = () => {
        setShowPaymentPopupQuick(true);
        setStripeCardData({
            isCardNumber: false,
            isCardExpData: false,
            isCardCvv: false,
            isComplete: false
        });
    };
    return (
        <StyledPage className="frame-295922521">
            <div className="frame-313548">
                <div className="frame-313657">
                    <div className="frame-313398">
                        <div className="frame-313396">
                            <div className="frame-313392">
                                <div className="frame-29716">
                                    <div className="product-name">Customer info</div>
                                </div>
                                <div className="frame-29719">
                                    <div className="frame-297162">
                                        <div className="product-name2">{`${userData?.firstName} ${userData?.lastName}`}</div>
                                    </div>
                                </div>
                                <div className="frame-29720">
                                    <div className="frame-297162">
                                        <div className="product-name3">{userData?.email}</div>
                                    </div>
                                </div>
                                <div className="frame-29721">
                                    <div className="frame-297162">
                                        <div className="product-name3">{userData?.phone}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="frame-313397">
                            <div className="frame-3133922">
                                <img className="image-144" src={IconVisa} alt="IconVisa" />
                                <div className="frame-29722">
                                    <div className="frame-297163">
                                        <div className="product-name3">{`${(checkoutPaymentData?.cardName).replace(/^./, match =>
                                            match.toUpperCase()
                                        )} ending *${checkoutPaymentData?.cardNumber}`}</div>
                                    </div>
                                </div>
                                <div className="frame-29723">
                                    <div className="frame-297163">
                                        <div className="product-name3">{`Expires ${checkoutPaymentData?.cardExpiration}`}</div>
                                    </div>
                                </div>
                                <div
                                    className="frame-313385"
                                    onClick={e => (placeOrderLoading ? e.preventDefault() : handleEditButton())}
                                    style={{ opacity: placeOrderLoading ? "0.3" : "1", cursor: placeOrderLoading ? "no-drop" : "pointer" }}>
                                    <div className="frame-313378">
                                        <img className="icon" src={EditPen} alt="EditPen" />
                                        <div className="edit">Edit</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledPage>
    );
}

import styled from "styled-components";
import { DefaultPopupCard } from "./DefaultPopupCard";
import React, { useEffect, useState } from "react";
import LayoutContext from "../../../contexts/LayoutContext";
import FloatingInput from "../forms/FloatingInput";
import PhoneNumberInput from "../forms/PhoneNumberInput";
import UserContext from "../../../contexts/UserContext";
import * as Formatter from "../../../utils/Formatter";
import closeBtn from "../../../assets/images/close-btn.svg";
import { CardElement } from "@stripe/react-stripe-js";
import { phoneNumberPattern } from "../../../utils/helperFunctions";
import ButtonLoader from "../loaders/ButtonLoader";
import logGA4Event from "../../../utils/firebase/analytics";
import * as Library from "../../../utils/Library";

/* #region SCOPED CSS */
const StyledPage = styled.div`
    .frame-29643,
    .frame-29643 * {
        box-sizing: border-box;
    }
    .frame-29643 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313569 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313568 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313474 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .almost-there {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 22px;
        line-height: 28px;
        font-weight: 600;
        position: relative;
        flex: 1;
    }
    .frame-29057 {
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 30px;
        height: 30px;
        position: relative;
        overflow: visible;
    }
    .frame-313567 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313655 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .tab-stepper {
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313501 {
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 0px 0px 1px 0px;
        padding: 8px 16px 8px 16px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-29786 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313500 {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .add-your-info {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-small-semibold-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--button-text-small-semibold-font-size, 12px);
        line-height: var(--button-text-small-semibold-line-height, 16px);
        font-weight: var(--button-text-small-semibold-font-weight, 600);
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .frame-313503 {
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 0px 0px 1px 0px;
        padding: 8px 16px 8px 16px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .payment-method {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-small-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-small-font-size, 12px);
        line-height: var(--button-text-small-line-height, 16px);
        font-weight: var(--button-text-small-font-weight, 400);
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .frame-313372 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29733 {
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .input-field {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-secondary, #8c8f93);
        border-width: 1px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
        box-shadow: var(--dropdown-input-field-box-shadow, 0px 0px 15px 0px rgba(0, 0, 0, 0.25));
    }
    .frame-313475 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        height: 14px;
        position: relative;
    }
    .label {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--label-xsmall-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--label-xsmall-font-size, 8px);
        line-height: var(--label-xsmall-line-height, 8px);
        font-weight: var(--label-xsmall-font-weight, 600);
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .label2 {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--label-medium-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--label-medium-font-size, 14px);
        line-height: var(--label-medium-line-height, 14px);
        font-weight: var(--label-medium-font-weight, 400);
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .input-field2 {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-secondary, #8c8f93);
        border-width: 1px;
        opacity: 0.2;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        box-shadow: var(--dropdown-input-field-box-shadow, 0px 0px 15px 0px rgba(0, 0, 0, 0.25));
    }
    .input-field-number {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-secondary, #8c8f93);
        border-width: 1px;
        padding: 8px 16px 8px 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        box-shadow: var(--dropdown-input-field-box-shadow, 0px 0px 15px 0px rgba(0, 0, 0, 0.25));
    }
    .frame-29021 {
        display: flex;
        flex-direction: row;
        gap: 7px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: 30px;
        position: relative;
    }
    .emoji-norway {
        flex-shrink: 0;
        width: 18px;
        height: 18px;
        position: relative;
        overflow: visible;
    }
    .chevron-down {
        flex-shrink: 0;
        width: 12px;
        height: 12px;
        position: relative;
        overflow: visible;
    }
    .frame-29524 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    ._47 {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--label-medium-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--label-medium-font-size, 14px);
        line-height: var(--label-medium-line-height, 14px);
        font-weight: var(--label-medium-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    ._938-456-250 {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--label-medium-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--label-medium-font-size, 14px);
        line-height: var(--label-medium-line-height, 14px);
        font-weight: var(--label-medium-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .btn-md-solid {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 44px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
        cursor: pointer;
    }
    .frame-313497 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313349 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-medium-regular-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-medium-regular-font-size, 14px);
        line-height: var(--button-text-medium-regular-line-height, 24px);
        font-weight: var(--button-text-medium-regular-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .by-placing-order-you-agree-to-the-terms-privacy-policy-of-b-otag-norge-as,
    .by-placing-order-you-agree-to-the-terms-privacy-policy-of-b-otag-norge-as * {
        box-sizing: border-box;
    }
    .by-placing-order-you-agree-to-the-terms-privacy-policy-of-b-otag-norge-as {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 10px);
        line-height: var(--caption-line-height, 16px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .by-placing-order-you-agree-to-the-terms-privacy-policy-of-b-otag-norge-as-span {
        color: var(--text-text-secondary, #8c8c8c);
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 10px);
        line-height: var(--caption-line-height, 16px);
        font-weight: var(--caption-font-weight, 400);
    }
    .by-placing-order-you-agree-to-the-terms-privacy-policy-of-b-otag-norge-as-span2 {
        color: var(--text-text-secondary, #8c8c8c);
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 10px);
        line-height: var(--caption-line-height, 16px);
        font-weight: var(--caption-font-weight, 400);
        text-decoration: underline;
        cursor: pointer;
    }
    .by-placing-order-you-agree-to-the-terms-privacy-policy-of-b-otag-norge-as-span3 {
        color: var(--text-text-secondary, #8c8c8c);
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 10px);
        line-height: var(--caption-line-height, 16px);
        font-weight: var(--caption-font-weight, 400);
    }
    .by-placing-order-you-agree-to-the-terms-privacy-policy-of-b-otag-norge-as-span4 {
        color: var(--text-text-secondary, #8c8c8c);
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 10px);
        line-height: var(--caption-line-height, 16px);
        font-weight: var(--caption-font-weight, 400);
        text-decoration: underline;
        cursor: pointer;
    }
    .by-placing-order-you-agree-to-the-terms-privacy-policy-of-b-otag-norge-as-span5 {
        color: var(--text-text-secondary, #8c8c8c);
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 10px);
        line-height: var(--caption-line-height, 16px);
        font-weight: var(--caption-font-weight, 400);
    }
`;

const CardInfoStyles = styled.div`
    .frame-313568,
    .frame-313568 * {
        box-sizing: border-box;
    }
    .frame-313568 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        width: 100%;
    }
    .frame-313474 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .almost-there {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 22px;
        line-height: 28px;
        font-weight: 600;
        position: relative;
        flex: 1;
    }
    .frame-29057 {
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 30px;
        height: 30px;
        position: relative;
        overflow: visible;
    }
    .frame-313567 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313657 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .tab-stepper {
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }

    .frame-29786 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313500 {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313503 {
        border-style: solid;
        border-color: var(--border-border-brand, #f7665f);
        border-width: 0px 0px 1px 0px;
        padding: 8px 16px 8px 16px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .payment-method {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-small-semibold-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--button-text-small-semibold-font-size, 12px);
        line-height: var(--button-text-small-semibold-line-height, 16px);
        font-weight: var(--button-text-small-semibold-font-weight, 600);
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-313656 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313374 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .input-field {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-secondary, #8c8f93);
        border-width: 1px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        box-shadow: var(--dropdown-input-field-box-shadow, 0px 0px 15px 0px rgba(0, 0, 0, 0.25));
    }
    .frame-313475 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        height: 14px;
        position: relative;
    }
    .label {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--label-xsmall-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--label-xsmall-font-size, 8px);
        line-height: var(--label-xsmall-line-height, 8px);
        font-weight: var(--label-xsmall-font-weight, 600);
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .label2 {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--label-medium-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--label-medium-font-size, 14px);
        line-height: var(--label-medium-line-height, 14px);
        font-weight: var(--label-medium-font-weight, 400);
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-29438 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: 30px;
        position: relative;
    }
    .g-4158 {
        flex-shrink: 0;
        width: 24px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .frame-313476 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: relative;
    }

    .frame-313480 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    ._12-24 {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--label-medium-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--label-medium-font-size, 14px);
        line-height: var(--label-medium-line-height, 14px);
        font-weight: var(--label-medium-font-weight, 400);
        position: relative;
    }
    .frame-313481 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313387 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .separator {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        border: none !important;
        margin: 0 !important;
        padding: none !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        border-style: none !important;
        border-color: none !important;
        border-width: 0 !important;
    }
    .line-27 {
        margin-top: -0.5px;
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0.001deg) scale(1, 1);
    }
    .text-label-here {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
    }
    .line-26 {
        margin-top: -0.5px;
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0.001deg) scale(1, 1);
    }
    .frame-313385 {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29432 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        width: 372px;
        height: 41px;
        position: relative;
    }
    .price {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        position: relative;
        flex: 1;
        height: 24px;
    }
    .price-span {
        color: var(--text-text-primary, #ffffff);
        font-family: "OpenSans-Bold", sans-serif;
        font-size: 24px;
        line-height: 24px;
        font-weight: 700;
    }
    .price-span2 {
        color: var(--text-text-primary, #ffffff);
        font-family: "OpenSans-Bold", sans-serif;
        font-size: 30px;
        line-height: 24px;
        font-weight: 700;
    }
    .price-span3 {
        color: var(--text-text-primary, #ffffff);
        font-family: "OpenSans-Bold", sans-serif;
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
    }
    .element {
        border: 1px solid #373737 !important;
        padding: 16px;
        border-radius: 5px;
        font-size: 14px !important;
        font-weight: 400 !important;
        color: red !important;
    }
`;
/* #endregion */

const PaymentPopup = ({ handleSubmit }) => {
    /* #region VARS */
    const {
        setShowPaymentPopup,
        setPaymentPopupData,
        paymentPopupData,
        setShowPolicyPopup,
        setStripeCardData,
        stripeCardData,
        cardPlaceOrderLoading
    } = React.useContext(LayoutContext);
    const { checkoutCartData, userData, checkoutPaymentData } = React.useContext(UserContext);

    const [selectedItem, setSelectedItem] = useState("CUSTOMER_INFO");
    //validation for customer info
    const { firstName, lastName, email, phoneNumber } = paymentPopupData;
    const isUserInfoValid =
        firstName?.length > 0 && lastName?.length > 0 && email?.length > 0 && phoneNumber?.trimEnd().length > 5 ? true : false;

    //validation for card info
    const { isCardCvv, isCardExpData, isCardNumber, isComplete } = stripeCardData;
    const isCardInfoValidate = paymentPopupData?.nameOnCard?.length > 0 && isCardCvv && isCardExpData && isCardNumber && isComplete;

    useEffect(() => {
        setPaymentPopupData({
            ...paymentPopupData,
            firstName: userData?.firstName || "",
            lastName: userData?.lastName || "",
            email: userData?.email || "",
            phoneNumber: userData?.phone || "+47   ",
            nameOnCard: checkoutPaymentData?.cardHolder || ""
        });
    }, []);
    /* #endregion */

    /* #region METHODS */

    const handleChangeSelectedItem = state => {
        setSelectedItem(state);
    };
    const handleNextBtnClick = () => {
        if (selectedItem === "CUSTOMER_INFO") {
            handleChangeSelectedItem("CARD_INFO");
        } else if (selectedItem === "CARD_INFO") {
            handleSubmit();
            // setShowThankyouPopup(true);
        }
    };

    //reseting validation for card info
    const resetValidation = () => {
        setStripeCardData({
            isCardNumber: false,
            isCardExpData: false,
            isCardCvv: false,
            isComplete: false
        });
    };
    /* #endregion */

    return (
        <DefaultPopupCard visible={true} mobileUsesFullScreen={false}>
            <StyledPage style={{ width: "100%" }}>
                <div className="frame-29643" style={{ pointerEvents: cardPlaceOrderLoading && "none" }}>
                    <div className="frame-313569">
                        <div className="frame-313568">
                            <div className="frame-313474">
                                <div className="almost-there">Almost there</div>
                                <img
                                    className="frame-29057 cursor-pointer"
                                    src={closeBtn}
                                    alt=""
                                    onClick={e => {
                                        cardPlaceOrderLoading ? e.preventDefault() : setShowPaymentPopup(false);
                                        resetValidation();
                                    }}
                                    style={{
                                        opacity: cardPlaceOrderLoading ? "0.1" : "1"
                                    }}
                                />
                            </div>
                            <div className="frame-313567">
                                <div className="frame-313655">
                                    <div
                                        className="tab-stepper"
                                        style={{
                                            opacity: cardPlaceOrderLoading ? "0.1" : "1"
                                        }}>
                                        <div
                                            className="frame-313501"
                                            style={{
                                                borderBottom:
                                                    selectedItem === "CUSTOMER_INFO" && "1px solid var(--border-border-brand, #f7665f)"
                                            }}>
                                            <div className="frame-29786">
                                                <div className="frame-313500">
                                                    <div
                                                        className="add-your-info"
                                                        onClick={() => {
                                                            handleChangeSelectedItem("CUSTOMER_INFO");
                                                            resetValidation();
                                                            logGA4Event("Confirm_PopUp_UserInfo");
                                                        }}>
                                                        Add your info
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="frame-313503"
                                            style={{
                                                borderBottom:
                                                    selectedItem === "CARD_INFO" && "1px solid var(--border-border-brand, #f7665f)"
                                            }}>
                                            <div
                                                className="frame-29786"
                                                onClick={() => {
                                                    handleChangeSelectedItem("CARD_INFO");
                                                    logGA4Event("Confirm_PopUp_Payment");
                                                }}
                                                style={{
                                                    opacity: isUserInfoValid ? 1 : 0.2,
                                                    pointerEvents: !isUserInfoValid && "none"
                                                }}>
                                                <div className="frame-313500">
                                                    <div className="payment-method">Payment method</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {selectedItem === "CUSTOMER_INFO" && (
                                        <CustomerInfoComponent
                                            setPaymentPopupData={setPaymentPopupData}
                                            paymentPopupData={paymentPopupData}
                                            isUserInfoValid={isUserInfoValid}
                                            handleNextBtnClick={handleNextBtnClick}
                                        />
                                    )}
                                    {selectedItem === "CARD_INFO" && (
                                        <CardInfoComponent
                                            setPaymentPopupData={setPaymentPopupData}
                                            paymentPopupData={paymentPopupData}
                                            checkoutCartData={checkoutCartData}
                                            setStripeCardData={setStripeCardData}
                                            handleNextBtnClick={handleNextBtnClick}
                                            isCardInfoValidate={isCardInfoValidate}
                                            cardPlaceOrderLoading={cardPlaceOrderLoading}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {selectedItem === "CARD_INFO" && (
                        <div
                            className="by-placing-order-you-agree-to-the-terms-privacy-policy-of-b-otag-norge-as"
                            style={{ opacity: cardPlaceOrderLoading ? "0.3" : "1" }}>
                            <span>
                                <span className="by-placing-order-you-agree-to-the-terms-privacy-policy-of-b-otag-norge-as-span">
                                    By placing order you agree to the&nbsp;
                                </span>
                                <span
                                    className="by-placing-order-you-agree-to-the-terms-privacy-policy-of-b-otag-norge-as-span2"
                                    onClick={() => setShowPolicyPopup("terms")}>
                                    Terms&nbsp;
                                </span>
                                <span className="by-placing-order-you-agree-to-the-terms-privacy-policy-of-b-otag-norge-as-span3">
                                    &amp;&nbsp;
                                </span>
                                <span
                                    className="by-placing-order-you-agree-to-the-terms-privacy-policy-of-b-otag-norge-as-span4"
                                    onClick={() => setShowPolicyPopup("privacy")}>
                                    Privacy policy&nbsp;
                                </span>
                                <span className="by-placing-order-you-agree-to-the-terms-privacy-policy-of-b-otag-norge-as-span5">
                                    of BOtag Norge AS
                                </span>
                            </span>
                        </div>
                    )}
                </div>
            </StyledPage>
        </DefaultPopupCard>
    );
};

export default PaymentPopup;

/* #region SMALL COMPONENTS */
const CustomerInfoComponent = ({ setPaymentPopupData, paymentPopupData, isUserInfoValid, handleNextBtnClick }) => {
    const callback = (e, c) => {
        setPaymentPopupData({
            ...paymentPopupData,
            phoneNumber: phoneNumberPattern(e, c?.format)
        });
    };
    return (
        <div className="frame-313372">
            <div className="frame-29733">
                <FloatingInput
                    label={"First name"}
                    onChange={e => {
                        setPaymentPopupData({
                            ...paymentPopupData,
                            firstName: e.target.value
                        });
                    }}
                    value={paymentPopupData?.firstName}
                />
                <FloatingInput
                    label={"Last name"}
                    onChange={e => {
                        setPaymentPopupData({
                            ...paymentPopupData,
                            lastName: e.target.value
                        });
                    }}
                    value={paymentPopupData?.lastName}
                />
            </div>
            <FloatingInput
                label={"Email"}
                onChange={e => {
                    setPaymentPopupData({
                        ...paymentPopupData,
                        email: e.target.value
                    });
                }}
                value={paymentPopupData?.email}
            />

            <PhoneNumberInput callback={callback} phoneNumber={paymentPopupData?.phoneNumber} />

            <div
                className="btn-md-solid"
                style={{ opacity: isUserInfoValid ? 1 : 0.2, pointerEvents: isUserInfoValid ? "auto" : "none" }}
                onClick={handleNextBtnClick}>
                <div className="frame-313497">
                    <div className="frame-313349">
                        <div className="button">
                            <div>Next</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const CardInfoComponent = ({
    setPaymentPopupData,
    paymentPopupData,
    checkoutCartData,
    setStripeCardData,
    handleNextBtnClick,
    isCardInfoValidate,
    cardPlaceOrderLoading
}) => {
    const customStyles = {
        base: {
            "padding": "16px",
            "color": "#fff",
            "fontWeight": "400",
            "fontSize": "14px",
            "fontSmoothing": "antialiased",
            ":-webkit-autofill": {
                color: "#fce883"
            },
            "::placeholder": {
                color: "#8C8C8C"
            }
        },
        invalid: {
            iconColor: "red",
            color: "red"
        }
    };
    return (
        <CardInfoStyles className="w-100">
            <div className="frame-313568">
                <div className="frame-313567">
                    <div className="frame-313657">
                        <div className="frame-313656">
                            <div className="frame-313374" style={{ opacity: cardPlaceOrderLoading ? "0.1" : "1" }}>
                                <FloatingInput
                                    label={"Name on card"}
                                    onChange={e => {
                                        setPaymentPopupData({
                                            ...paymentPopupData,
                                            nameOnCard: e.target.value
                                        });
                                    }}
                                    value={paymentPopupData?.nameOnCard}
                                />

                                <div className="w-100">
                                    <CardElement
                                        id="card-element"
                                        options={{
                                            style: customStyles,
                                            hidePostalCode: true,
                                            disableLink: true
                                        }}
                                        onChange={e =>
                                            setStripeCardData({
                                                isCardNumber: true,
                                                isCardExpData: true,
                                                isCardCvv: true,
                                                isComplete: e.complete
                                            })
                                        }
                                        className="element"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="frame-313387">
                    <div className="separator">
                        <div className="line-27"></div>
                        <div className="text-label-here">Total to pay</div>
                        <div className="line-26"></div>
                    </div>
                    <div className="frame-313385">
                        <div className="frame-29432">
                            <div className="price">
                                <span>
                                    <span className="price-span">{Formatter.formatPrice(checkoutCartData?.total)}</span>
                                    <span className="price-span2"> </span>
                                    <span className="price-span3">NOK</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="btn-md-solid"
                    style={{
                        opacity: isCardInfoValidate ? 1 : 0.2,
                        pointerEvents: (!isCardInfoValidate || cardPlaceOrderLoading) && "none"
                    }}
                    onClick={handleNextBtnClick}>
                    <div className="frame-313497">
                        <div className="frame-313349">
                            {cardPlaceOrderLoading && <ButtonLoader />}
                            <div className="button">
                                <div>{cardPlaceOrderLoading ? "Placing order" : "Place order"}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CardInfoStyles>
    );
};
/* #endregion */

import React, { useState } from "react";
import { ReactComponent as Frame290570 } from "../../../../assets/images/close-btn.svg";
import { ReactComponent as PasswordEyeHide } from "../../../../assets/images/password-eye-hide.svg";
import { ReactComponent as PasswordEyeHideActive } from "../../../../assets/images/password-eye-hide-active.svg";
import { ReactComponent as Checkbox } from "../../../../assets/images/terms-check.svg";
import Spinner from "../../../../assets/images/spinner.svg";
import styled from "styled-components";
import LayoutContext from "../../../../contexts/LayoutContext";
import * as Library from "../../../../utils/Library";
import { isValidEmail } from "../../../../utils/Formatter";
import { UserContext, userLogin } from "../../../../contexts/UserContext";
import packageJson from "../../../../../package.json";
import StandardsContext from "../../../../contexts/StandardsContext";

/* #region STYLED CSS */
const StyledPage = styled.div`
    &.user-login-registration-main,
    &.user-login-registration-main * {
        box-sizing: border-box;
    }
    &.user-login-registration-main {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: start;
        justify-content: flex-start;
        position: relative;
    }
    .frame-29643 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313560 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313474 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .log-in {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 22px;
        line-height: 28px;
        font-weight: 600;
        position: relative;
        flex: 1;
    }
    .frame-29057 {
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 30px;
        height: 30px;
        position: relative;
        overflow: visible;
    }
    .welcome-back {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .tabs-level-1 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .buttons-fixed-width {
        background: var(--buttons-button-secondary-active, #373737);
        border-radius: 5px 0px 0px 5px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 36px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
        cursor: pointer;
    }
    .buttons-fixed-width .label {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-small-semibold-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--button-text-small-semibold-font-size, 12px);
        line-height: var(--button-text-small-semibold-line-height, 16px);
        font-weight: var(--button-text-small-semibold-font-weight, 600);
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .buttons-fixed-width2 {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 0px 5px 5px 0px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 36px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
        cursor: pointer;
    }
    .label-active {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-small-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-small-font-size, 12px);
        line-height: var(--button-text-small-line-height, 16px);
        font-weight: var(--button-text-small-font-weight, 400);
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-313586 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .input-field {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        box-shadow: var(--dropdown-input-field-box-shadow, 0px 0px 15px 0px rgba(0, 0, 0, 0.25));
    }
    .label {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--label-medium-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--label-medium-font-size, 14px);
        line-height: var(--label-medium-line-height, 14px);
        font-weight: var(--label-medium-font-weight, 400);
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .eye {
        flex-shrink: 0;
        width: 14px;
        height: 14px;
        position: relative;
        overflow: visible;
    }
    .frame-313478 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .checkbox {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .ellipse-1 {
        border-radius: 50%;
        border-style: solid;
        border-color: rgba(166, 166, 166, 0.2);
        border-width: 1px;
        flex-shrink: 0;
        width: 18px;
        height: 18px;
        position: relative;
        cursor: pointer;
    }
    .text-label {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        cursor: pointer;
    }
    .text-label2 {
        color: var(--text-text-brand, #f7665f);
        text-align: right;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        flex: 1;
        cursor: pointer;
    }
    .btn-md-solid {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 5px;
        opacity: 0.2;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 44px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
        cursor: no-drop;
    }
    .frame-313497 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313349 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-medium-regular-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-medium-regular-font-size, 14px);
        line-height: var(--button-text-medium-regular-line-height, 24px);
        font-weight: var(--button-text-medium-regular-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .version-1-2 {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .floating-input {
        position: relative;
        width: 100%;
    }
    .customer-info-inputs {
        background-color: initial;
        border: 1px solid #373737;
        border-radius: 5px;
        color: #fff;
        display: inline-block;
        height: 0px;
        outline: none;
        padding: 28px 16px 16px;
        width: 100%;
        font-size: 14px;
    }
    .floating-input input:focus + label,
    .floating-input input:not(:placeholder-shown) + label {
        color: #8c8c8c;
        font-size: 8px;
        font-weight: 600;
        left: 18px;
        top: 5px;
    }
    .floating-input label {
        color: #8c8c8c;
        font-size: 14px;
        font-weight: 400;
        left: 22px;
        pointer-events: none;
        position: absolute;
        top: 13px;
        transition: all 0.2s ease;
    }
    .customer-info-inputs.has-value {
        opacity: 1;
        border: 1px solid #8c8f93;
        font-size: 14px;
    }
    .btn-md-solid.active {
        opacity: 1;
        cursor: pointer;
    }
    .toggle-password {
        position: absolute;
        right: 14px;
        top: 16px;
    }
    .spinner-text {
        display: flex;
        flex-direction: row;
    }
    .spinner-img {
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
    .floating-input .error {
        border-color: #f7665f !important;
        font-size: 14px;
    }
    .error-text {
        font-size: 12px;
        color: #f7665f;
    }
`;
/* #endregion */
export default function Login({ visible }) {
    /* #region VARS */
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrorText, setPasswordErrorText] = useState("");
    const [isButtonDisable, setIsButtonDisable] = useState(true);
    const [togglePassword, setTogglePassword] = useState(false);
    const [emailHasValue, setEmailHasValue] = useState(false);
    const [passwordHasValue, setPasswordHasValue] = useState(false);
    const [isFormSubmit, setIsFormSubmit] = useState(false);
    const [enableCheckbox, setEnableCheckbox] = useState(false);
    const { setShowUserLoginRegisterPopup, setShowForgotPasswordPopup, setShowLoginForm, setShowRegistration } =
        React.useContext(LayoutContext);
    const {
        setIsUserLoggedIn,
        setUserData,
        setUserAddress,
        setUserGPSCoordinates,
        setBOTagAccountID,
        setCheckoutPaymentData,
        setShippingRecipient,
        setCheckoutCartData,
        checkoutCartData
    } = React.useContext(UserContext);
    const { setDefaultNotificationCard } = React.useContext(StandardsContext);
    /* #endregion */

    /* #region METHODS */
    const handleCloseLogin = () => {
        setShowUserLoginRegisterPopup(false);
    };
    const handleUserEmail = e => {
        setEmailError(false);
        setEmailErrorText("");
        setEmail(e.target.value);
    };
    const handleUserPassword = e => {
        setPassword(e.target.value);
        setPasswordError(false);
        setPasswordErrorText("");
    };
    const handleTogglePassword = () => {
        setTogglePassword(!togglePassword);
    };
    const handleCheckbox = () => {
        setEnableCheckbox(!enableCheckbox);
    };
    const handleLogin = e => {
        if (isButtonDisable) {
            e.preventDefault();
            return;
        }
        if (handleValidation()) {
            setEmailHasValue(false);
            setPasswordHasValue(false);
            setIsFormSubmit(true);
            Library.makePostRequest(
                "login",
                {
                    email: email,
                    password: password
                },
                false,
                setIsButtonDisable
            )
                .then(res => {
                    setEmailHasValue(true);
                    setPasswordHasValue(true);
                    setIsFormSubmit(false);
                    if (res?.data?.status === 200) {
                        localStorage.setItem("pid", res?.data?.data?._id);
                        localStorage.setItem("isLoggedIn", true);
                        if (enableCheckbox) {
                            localStorage.setItem("rememberEmail", email);
                            localStorage.setItem("rememberPassword", password);
                        } else {
                            localStorage.removeItem("rememberEmail");
                            localStorage.removeItem("rememberPassword");
                        }
                        const { _id, firstName, lastName, phoneNumber, cards } = res?.data?.data;
                        //TODO: THERE IS NO ADDRESS DATA IN THE RESPONSE; GPS COORDINATES ARE ALSO MISSING; MAYBE OTHER DATA IS MISSING TOO
                        const allUserInfo = {
                            _id,
                            firstName,
                            lastName,
                            email,
                            phone: phoneNumber,
                            cards: typeof cards !== "undefined" && cards[0]
                        };

                        checkoutCartData.items.forEach(item => {
                            item.BOTagData.recipient = `${firstName} ${lastName}`;
                        });
                        userLogin(
                            allUserInfo,
                            setIsUserLoggedIn,
                            setUserData,
                            setUserAddress,
                            setUserGPSCoordinates,
                            setBOTagAccountID,
                            setCheckoutPaymentData,
                            setShippingRecipient,
                            setCheckoutCartData,
                            checkoutCartData
                        );
                        setShowUserLoginRegisterPopup(false);

                        //Library.showSuccessMessage("Logged in successfully");
                        Library.showNotification({
                            message: "Logged in successfully",
                            type: "success",
                            setDefaultNotificationCard
                        });
                    } else {
                        if (res?.data?.error_type === "username") {
                            setEmailError(true);
                            setEmailErrorText(res?.data?.message);
                        }
                        if (res?.data?.error_type === "password") {
                            setPasswordError(true);
                            setPasswordErrorText(res?.data?.message);
                        }
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        }
    };
    const handleValidation = () => {
        let isFromValid = true;
        if (!emailHasValue) {
            isFromValid = false;
            setEmailError(true);
            setEmailErrorText("");
        } else if (emailHasValue && !isValidEmail(email)) {
            isFromValid = false;
            setEmailError(true);
            setEmailErrorText("Invalid email");
        }
        if (!passwordHasValue) {
            isFromValid = false;
            setPasswordError(true);
            setPasswordErrorText("");
        }
        return isFromValid;
    };
    const handleForgotPassword = () => {
        setShowForgotPasswordPopup(true);
    };
    const handleShowRegistration = () => {
        setShowLoginForm(false);
        setShowRegistration(true);
    };
    /* #endregion */

    /* #region EFFECTS */
    React.useEffect(() => {
        if (email) {
            setEmailHasValue(true);
        } else {
            setEmailHasValue(false);
        }
        if (password) {
            setPasswordHasValue(true);
        } else {
            setPasswordHasValue(false);
        }
        if (email && password) {
            setIsButtonDisable(false);
        } else {
            setIsButtonDisable(true);
        }
    }, [email, password]);

    React.useEffect(() => {
        setEmail(localStorage.getItem("rememberEmail") ? localStorage.getItem("rememberEmail") : "");
        setPassword(localStorage.getItem("rememberPassword") ? localStorage.getItem("rememberPassword") : "");
    }, []);
    /* #endregion */

    return (
        <StyledPage className="user-login-registration-main">
            <div className="frame-29643">
                <div className="frame-313560">
                    <div className="frame-313474">
                        <div className="log-in">Log in</div>
                        <Frame290570 className="frame-29057 cursor-pointer" onClick={handleCloseLogin} />
                    </div>
                    <div className="welcome-back">Welcome back!</div>
                </div>
            </div>
            <div className="tabs-level-1">
                <div className="buttons-fixed-width">
                    <div className="label">Log in</div>
                </div>
                <div className="buttons-fixed-width2" onClick={handleShowRegistration}>
                    <div className="label-active">Create account</div>
                </div>
            </div>
            <div className="frame-313586">
                <div className="floating-input">
                    <input
                        type="text"
                        className={`customer-info-inputs ${emailHasValue ? "has-value" : ""} ${emailError ? "error" : ""}`}
                        placeholder=" "
                        autoComplete="off"
                        value={email}
                        onChange={handleUserEmail}
                    />
                    <label>Email</label>
                    <div className="error-text">{emailErrorText}</div>
                </div>
                <div className="floating-input">
                    <input
                        type={!togglePassword ? "password" : "text"}
                        className={`customer-info-inputs ${passwordHasValue ? "has-value" : ""} ${passwordError ? "error" : ""}`}
                        placeholder=" "
                        autoComplete="off"
                        value={password}
                        onChange={handleUserPassword}
                    />
                    <label>Password</label>
                    <div className="error-text">{passwordErrorText}</div>
                    <span className="toggle-password">
                        {passwordHasValue ? (
                            <PasswordEyeHideActive
                                className="frame-29057 cursor-pointer"
                                style={{
                                    height: "15px",
                                    width: "15px"
                                }}
                                onClick={handleTogglePassword}
                            />
                        ) : (
                            <PasswordEyeHide
                                className="frame-29057 cursor-pointer"
                                style={{
                                    height: "15px",
                                    width: "15px"
                                }}
                                onClick={handleTogglePassword}
                            />
                        )}
                    </span>
                </div>
            </div>
            <div className="frame-313478">
                <div className="checkbox" onClick={handleCheckbox}>
                    {enableCheckbox ? (
                        <Checkbox
                            className="frame-29057 cursor-pointer"
                            style={{
                                height: "18px",
                                width: "18px",
                                opacity: isFormSubmit ? 0.2 : 1
                            }}
                        />
                    ) : (
                        <div className="ellipse-1"></div>
                    )}

                    <div className="text-label">Keep me logged in</div>
                </div>
                <div className="checkbox">
                    <div className="text-label2" onClick={handleForgotPassword}>
                        Forgot password?
                    </div>
                </div>
            </div>
            <div className={`btn-md-solid  ${!isButtonDisable ? "active" : ""}`} onClick={handleLogin}>
                <div className="frame-313497">
                    <div className="frame-313349">
                        <div className="button">
                            {isFormSubmit ? (
                                <span className="spinner-text">
                                    <img src={Spinner} alt="" className="spinner-img" />
                                    &nbsp;
                                    {"  "}Logging in...
                                </span>
                            ) : (
                                "Login"
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="version-1-2">Version {packageJson.version}</div>
        </StyledPage>
    );
}

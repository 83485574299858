import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styled from "styled-components";
import { extractPhoneNumber } from "../../../utils/helperFunctions";
// import { extractPhoneNumber } from "../../assets/helpers/utilHelpers";
// import { useResize } from "../../hooks/useResize";
const PhoneInputStyle = styled.div`
    .form-control {
        color: #fff !important;
        background: #121212 !important;
        border: 1px solid #8c8f93 !important;
        border-radius: 5px !important;
        width: 100% !important;
        padding-block: 21px;
        border: none !important;
    }

    .form-control:focus {
        box-shadow: none;
    }

    .selected-flag {
        background: #121212 !important;
    }

    .country-list {
        color: white !important;
        background: #212121 !important;
    }

    .flag-dropdown {
        border-right: none !important;
    }

    .arrow {
        border-top: 4px solid #ffffff !important;
    }

    .arrow.up {
        border-top: none !important;
        border-bottom: 4px solid #ffffff !important;
    }

    .country.highlight {
        background: #282828 !important;
    }

    .country:hover {
        background: #282828 !important;
    }

    .flag-dropdown {
        background: none !important;
        border: none !important;
    }
`;

const PhoneNumberInput = ({ callback, phoneNumber }) => {
    const [countryCode, setCountryCode] = useState("");
    const handleChange = (e, c, f, i) => {
        callback(e, c, countryCode);
        setCountryCode(c?.dialCode);
    };
    const borderChangeCondition = extractPhoneNumber(countryCode, phoneNumber)?.length > 0;

    return (
        <PhoneInputStyle style={{ width: "100%" }}>
            <PhoneInput
                country={"no"}
                countryCodeEditable={false}
                onChange={(e, c, d, a) => {
                    handleChange(e, c, d, a);
                }}
                value={phoneNumber}
                style={{
                    border: borderChangeCondition ? "1px solid var(--text-text-primary)" : "1px solid rgba(101, 101, 101, 0.36)",
                    borderRadius: "5px"
                }}
                dropdownStyle={{
                    position: "absolute",
                    // bottom: isMobile && isCheckout ? "35px" : null,
                    borderRadius: "5px"
                }}
            />
        </PhoneInputStyle>
    );
};

export default PhoneNumberInput;

import * as React from "react";
import Drawer from "@mui/material/Drawer";
import LayoutContext from "./contexts/LayoutContext";
import useViewportHeight from "./utils/hooks/useViewportHeight";

export default function AnchorDrawer({
    anchor = "bottom",
    isOpen = false,
    setIsOpen,
    children,
    mobileUsesFullScreen = true,
    onDrawerClick = () => {},
    customClass
}) {
    /* #region STATES */
    const [anchorStates, setAnchorStates] = React.useState({ top: false, left: false, bottom: false, right: false });
    const [overrideAlwaysUseFullscreen, setOverrideAlwaysFullscreen] = React.useState(false);
    /* #endregion */

    /* #region METHODS */
    const closeDrawer = () => {
        if (setIsOpen) setIsOpen(false);
    };
    /* #endregion */

    /* #region EFFECTS */
    React.useEffect(() => {
        setAnchorStates({ ...anchorStates, [anchor]: isOpen });
    }, [isOpen]);

    React.useEffect(() => {
        // EdgA is the user agent for Edge on Android
        if (window?.navigator?.userAgent?.indexOf("EdgA") > -1) {
            setOverrideAlwaysFullscreen(true);
        }
    }, []);

    /* #endregion */

    return (
        <div onClick={onDrawerClick}>
            <React.Fragment key={anchor}>
                <Drawer
                    anchor={anchor}
                    open={anchorStates[anchor]}
                    onClose={() => closeDrawer()}
                    className={`AnchorDrawer ${mobileUsesFullScreen || overrideAlwaysUseFullscreen ? "fullscreen" : "partial"}`}>
                    {DrawerContents(children, mobileUsesFullScreen, customClass, overrideAlwaysUseFullscreen)}
                </Drawer>
            </React.Fragment>
        </div>
    );
}

/* #region SMALL COMPONENTS */
const DrawerContents = (children, mobileUsesFullScreen, customClass, overrideAlwaysUseFullscreen) => {
    const { placeOrderLoading, isMobile } = React.useContext(LayoutContext);
    const viewportHeight = useViewportHeight();

    const calculateHeight = () => {
        if (overrideAlwaysUseFullscreen) {
            return "100dvh";
        }

        if (mobileUsesFullScreen) {
            return placeOrderLoading ? "100dvh" : `${viewportHeight - 32}px`;
        } else {
            return "auto";
        }
    };

    return (
        <div
            className={`anchor-drawer-contents ${customClass}`}
            style={{
                height: calculateHeight(),
                overflow: "auto",
                margin: placeOrderLoading && isMobile ? "0" : "16px"
            }}>
            {children}
        </div>
    );
};
/* #endregion */
